import AddUserProps from "../interfaces/AddUserProps";

export const DEFAULT_USER: AddUserProps = {
  cpi_id: "",
  cpi_name: "",
  email: "",
  full_name: "",
  region: "",
  technology_type: "",
  role: "",
  username: "",
};
