import React from "react";
import Dropzone from "react-dropzone";

import "./AccordionInput.css";

type inputTypes = "input" | "dropdown" | string;
interface AccordionInputProps {
  type: inputTypes;
  title: string;
  disabled?: boolean;
  options?: any[];
  value?: string;
  onChange?: any;
  icon?: string;
  onUpload?: any;
}

interface DropdownProps {
  type: inputTypes;
  title: string;
  value?: string;
  onChange: any;
  disabled: boolean;
  options: any[];
}

const VendorDropdown: React.FC<DropdownProps> = ({ type, title, value, onChange, disabled = false, options = [] }) =>
  type === "vendor-dropdown" ? (
    <div className="form-floating w-100">
      <select
        disabled={disabled}
        className="form-control accordion-input"
        value={value}
        id="accordionDropdown"
        onChange={(e: any) => {
          onChange(e);
        }}
      >
        <option hidden>{title}</option>
        {options.map((option: any) => {
          return <option key={option.id} value={option.id}>{`${option.vendor_name}-${option.vendor_model}`}</option>;
        })}
      </select>
      <label htmlFor="accordionDropdown" className="accordion-input-label">
        {title}
      </label>
    </div>
  ) : (
    <Dropdown type={type} disabled={disabled} onChange={onChange} options={options} title={title} value={value} />
  );

const Dropdown: React.FC<DropdownProps> = ({ type, title, value, onChange, disabled = false, options = [] }) =>
  type === "dropdown" ? (
    <div className="form-floating w-100">
      <select
        disabled={disabled}
        className="form-control accordion-input"
        value={value}
        id="accordionDropdown"
        onChange={(e: any) => {
          onChange(e);
        }}
      >
        <option hidden>{title}</option>
        {options.map((option: any) => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })}
      </select>
      <label htmlFor="accordionDropdown" className="accordion-input-label">
        {title}
      </label>
    </div>
  ) : (
    <VendorDropdown type={type} disabled={disabled} onChange={onChange} options={options} title={title} value={value} />
  );

const AccordionInput: React.FC<AccordionInputProps> = ({
  type,
  title,
  disabled = false,
  options = [],
  value,
  onChange,
  icon,
  onUpload,
}) => {
  return (
    <div>
      {type === "input" ? (
        <div className="row-wise">
          <div className="form-floating w-100">
            <input
              disabled={disabled}
              className="form-control accordion-input"
              id="accordionInput"
              placeholder={title}
              value={value}
              onChange={(e: any) => {
                onChange(e);
              }}
            ></input>
            <label htmlFor="accordionInput" className="accordion-input-label">
              {title}
            </label>
          </div>
          {icon ? (
            <Dropzone
              multiple={false}
              onDrop={(acceptedFiles: any) => {
                onUpload(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="center">
                  <div {...getRootProps({})}>
                    <input {...getInputProps()} accept="image/*,image/png,image/jpeg,image/jpg" />

                    <span
                      style={icon === "done" ? { color: "#00d56e" } : {}}
                      className="material-icons-round accordion-icon"
                    >
                      {icon}
                    </span>
                  </div>
                  <div></div>
                </section>
              )}
            </Dropzone>
          ) : (
            ""
          )}
        </div>
      ) : (
        <Dropdown type={type} disabled={disabled} onChange={onChange} options={options} title={title} value={value} />
      )}
    </div>
  );
};

export default AccordionInput;
