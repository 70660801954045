import React from "react";

const UsersTableHeader: React.FC = () => {
  return (
    <div className="row table-header-card vertical-center">
      <div className="col-2">
        <span>Full Name</span>
      </div>
      <div className="col-2">
        <span>Username</span>
      </div>
      <div className="col-2">
        <span>Email</span>
      </div>
      <div className="col-2">
        <span>Technology Type</span>
      </div>
      <div className="col-2">
        <span>Role</span>
      </div>
      <div className="col-1">
        <span>CPI ID</span>
      </div>
      <div className="col-1"></div>
    </div>
  );
};

export default UsersTableHeader;
