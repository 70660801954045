import React from "react";

interface LocateMeProps {
  located: any;
  error: any;
}
const LocateMe: React.FC<LocateMeProps> = ({ located, error }) => {
  const getLocation = () => {
    try {
      console.log("geolocator", navigator.geolocation);
      if (navigator.geolocation) {
        console.log("entered");
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            console.log("position", position);
            located(pos, true);
          },
          (e: any) => {
            error({message: e.message, alert: true})
          },
          { timeout: 3000 }
        );
      } else {
        console.log("position", null);
        located(null, false);
      }
    } catch (e: any) {
      error({message: e, alert: true})
    }
  };

  return (
    <div title="My Location" className="map-icon-background mb-3" onClick={getLocation}>
      <span className="material-icons-round map-icon">my_location</span>
    </div>
  );
};

export default LocateMe;
