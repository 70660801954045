import React from "react";
import "./PrimaryButton.css";

interface PrimaryButtonProps {
  label: string;
  clicked: any;
  disable?: boolean;
  className?: string;
}
const PrimaryButton: React.FC<PrimaryButtonProps> = ({ label, clicked, disable = false, className = "" }) => {
  const clickAction = (e: any) => {
    if(!disable) {
      clicked(e);
    }
  };


  return (
    <div
      className={className + (disable ? " primary-button-disabled" : " primary-button")}
      onClick={(e: any) => {
        clickAction(e);
      }}
    >
      <span>{label}</span>
    </div>
  );
};

export default PrimaryButton;
