import React from "react";

interface MapSpinnerProps {
  show: boolean;
}
const MapSpinner: React.FC<MapSpinnerProps> = ({ show }) => {
  return (
    <div style={show ? { visibility: "visible" } : { visibility: "hidden" }} className="spinner map-center">
      <span></span>
    </div>
  );
};

export default MapSpinner;
