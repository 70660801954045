import React, { useState } from "react";
import { IWifiVendorCardsProps } from "./IWifiVendorCardsProps";
import Vendor from "../Vendor";

const WifiVendorCards: React.FC<IWifiVendorCardsProps> = (props) => {
  const [activeTab, setActiveTab] = useState<"wifi" | "cpe">("wifi");
  return (
    <div>
      {/* Vendor Type selection tabs */}
      <ul className="nav mt-3">
        <li
          id="wifi-vendors"
          onClick={() => {
            setActiveTab("wifi");
          }}
          className={`${activeTab === "wifi" ? "active" : ""}`}
        >
          {"WIFI"}
        </li>
        <li
          id="cpe-vendors"
          onClick={() => {
            setActiveTab("cpe");
          }}
          className={`${activeTab === "cpe" ? "active" : ""}`}
        >
          {"CPE"}
        </li>
      </ul>

      {activeTab === "wifi" ? (
        <div className="row gx-0">
          <div className="col-4 pe-1">
            <Vendor
              title="WIFI"
              type="wifi_6e"
              processing={props.processing}
              selectedVendorId={props.selectedWifiVendorId}
              options={props.wifiOptions}
              refresh={props.refresh}
              onChange={props.onWifiChange}
            />
          </div>
        </div>
      ) : activeTab === "cpe" ? (
        <div className="row gx-0">
          <div className="col-4 pe-1">
            <Vendor
              title="CPE"
              type="cpe_wifi"
              processing={props.processing}
              selectedVendorId={props.selectedCpeVendorId}
              options={props.cpeOptions}
              refresh={props.refresh}
              onChange={props.onCpeChange}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default WifiVendorCards;
