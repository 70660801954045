import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../components/SecondaryButton";
import PrimaryButton from "../components/PrimaryButton";
import ReactDatePicker from "react-datepicker";
import { ApiHandler } from "../services/apiHandler";
import { CBSDMountingTypes, UserRoles } from "../constants/GlobalConstants";
import ErrorAlert from "../components/ErrorAlert";

import Dropdown from 'rsuite/Dropdown';
import 'rsuite/dist/rsuite.min.css';
import { exists } from "../services/helper";

interface DateSelection {
  startDate: Date | null;
  endDate: Date | null;
}

interface AssetFilterProps {
  chartFilters: any;
  assetFilters: any;
  searchFilters: any;
}

const CBSDAssetFilter: React.FC<AssetFilterProps> = ({ chartFilters, assetFilters, searchFilters }) => {
  let navigate = useNavigate();
  const [installerUsers, setInstallerUsers] = useState<any>([]);
  const [cpiAdmins, setCpiAdmins] = useState<any>([]);
  const [mountingTypeFilter, setMountingTypeFilter] = useState("All");
  const [dateFilter, setDateFilter] = useState<DateSelection>({ startDate: null, endDate: null });
  const [userFilter, setUserFilter] = useState("All");
  const [dateFilterFormatted, setFormattedDate] = useState({});
  const [installerSearchString, setInstallerSearchString] = useState("");
  const [cpiSearchString, setCpiSearchString] = useState("");
  const [error, setError] = useState({
    message: "",
    alert: false,
  });

  useEffect(() => {
    getUsers(UserRoles.INSTALLER);
    getUsers(UserRoles.CPI);
  }, []);

  useEffect(() => {
    let chartFilteredMountingType = exists(chartFilters.mountingType) 
                                    ? chartFilters.mountingType 
                                    : 'All';
    setMountingTypeFilter(chartFilteredMountingType);
  }, [chartFilters]);
 
  useEffect(() => {
    setFormattedDate({
      startDate : dateFilter.startDate?.toLocaleString("en-CA").split(",")[0],
      endDate : dateFilter.endDate?.toLocaleString("en-CA").split(",")[0]
    });
  }, [dateFilter]);

  {/** Called on dropdown Installer search */}
  useEffect(() => {
    if (exists(installerSearchString)) {
      searchUser(installerSearchString, UserRoles.INSTALLER);
    } else {
      getUsers(UserRoles.INSTALLER);
    }
  }, [installerSearchString]);

  {/** Called on dropdown CPI search */}
  useEffect(() => {
    if (exists(cpiSearchString)) {
      searchUser(cpiSearchString, UserRoles.CPI);
    } else {
      getUsers(UserRoles.CPI);
    }
  },[cpiSearchString]);

  {/**Method used to get all the users */}
  const searchUser = (user:any, role:any) => {
    const queryParams: any ={
      role: role
    };
    ApiHandler({ apiName: "searchUsers", body: {}, params: {search_string: user}, queryParams: queryParams })
      .then((response: any) => {
        if (role === UserRoles.INSTALLER) {
          setInstallerUsers(response.data.users);
        } else if(role === UserRoles.CPI) {
          setCpiAdmins(response.data.users);
        }
      })
      .catch((error: any) => {
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
            setTimeout(() => navigate("/login", { replace: true }), 2000)
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  {/** Method used  to get list of users for user filter */}
  const getUsers = (role: any) => {
    const queryParams: any = {
      page: 1,
      limit: 25,
      role: role
    };
    ApiHandler({ apiName: "getUsers", body: {}, params: {}, queryParams: queryParams })
      .then((response: any) => {
        if(role === UserRoles.INSTALLER) {
          setInstallerUsers(response.data.users);
        } else if(role === UserRoles.CPI) {
          setCpiAdmins(response.data.users);
        }
      })
      .catch((error: any) => {
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
            setTimeout(() => navigate("/login", { replace: true }), 2000)
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  {/** Clear All action set*/}
  const clearAllFilters = () => {
    setMountingTypeFilter("All");
    setUserFilter("All");
    setDateFilter({ startDate: null, endDate: null });
    assetFilters({});
    setInstallerSearchString("");
    setCpiSearchString("");
  };

  {/** Apply filter when any changes in the filters*/}
  const applyFilters = () => {
    assetFilters({
      mountingType: mountingTypeFilter !== "All" ? mountingTypeFilter : "",
      installer: userFilter !== "All" ? userFilter : "",
      date: dateFilterFormatted
    });
  };

  return (
    <div className="col-wise">
      <div className="row-wise">
        {/* Filter for mounting type */}
        <div className="col-wise">
          <span className="sub-heading-network ms-2">Mounting Type</span>
          <div className="form-group me-2">
            <Dropdown 
              disabled={searchFilters.hasSearchString}
              style={{backgroundColor:searchFilters.hasSearchString?'#e9ecef':''}}
              size = 'lg'
              className="menuStyle"
              title={mountingTypeFilter}>
              <Dropdown.Item value='All' onClick={ (e:any) => setMountingTypeFilter('All')}>All</Dropdown.Item>
              <Dropdown.Item value={CBSDMountingTypes.AERIAL} onClick={ (e:any) => setMountingTypeFilter(CBSDMountingTypes.AERIAL)}>{CBSDMountingTypes.AERIAL}</Dropdown.Item>
              <Dropdown.Item value={CBSDMountingTypes.ROE} onClick={ (e:any) => setMountingTypeFilter(CBSDMountingTypes.ROE)}>{CBSDMountingTypes.ROE}</Dropdown.Item>
              <Dropdown.Item value={CBSDMountingTypes.SMB} onClick={ (e:any) => setMountingTypeFilter(CBSDMountingTypes.SMB)}>{CBSDMountingTypes.SMB}</Dropdown.Item>
            </Dropdown>
          </div>
        </div>

        {/* Filter for User */}
        <div className="col-wise">
          <span className="sub-heading-network ms-2">User</span>
          <div className="form-group me-2">
            <Dropdown 
              disabled={searchFilters.hasSearchString}
              style={{backgroundColor:searchFilters.hasSearchString?'#e9ecef':''}}
              size = 'lg'
              className="menuStyle" 
              title={userFilter}>
                <Dropdown.Item onClick={ (e:any) => setUserFilter('All')}>All
                </Dropdown.Item>
                <Dropdown.Menu title={UserRoles.INSTALLER}>
                <div className="userdropdown">
                  <Dropdown.Item>
                  <div className="dropdown-search">
                    <span className="network-icon material-icons-round ms-2">search</span>
                    <input className="p-0 search-input-box" value={installerSearchString} 
                      placeholder='search'
                      onChange={(e: any) => {
                        setInstallerSearchString(e.target.value);
                    }}/>
                  </div>
                  </Dropdown.Item>
                  {installerUsers.map((user: any) => (
                    <Dropdown.Item
                        eventKey={`1-(${user.email})`}
                        key={user.email}
                        value={user.username}
                        onClick={ (e:any) => setUserFilter(user.username)}
                    >
                        {user.username}
                    </Dropdown.Item>
                  ))}
                </div>
                </Dropdown.Menu>
                <Dropdown.Menu title={UserRoles.CPI}>
                <div className="userdropdown">
                  <Dropdown.Item>
                  <div className="dropdown-search">
                    <span className="network-icon material-icons-round ms-2">search</span>
                    <input className="p-0 search-input-box" value={cpiSearchString} 
                      placeholder='search'
                      onChange={(e: any) => {
                        setCpiSearchString(e.target.value);
                    }}/>
                  </div>
                  </Dropdown.Item>
                  {cpiAdmins.map((user: any) => (
                    <Dropdown.Item
                        eventKey={`1-(${user.email})`}
                        key={user.email}
                        value={user.username}
                        onClick={ (e:any) => setUserFilter(user.username)}
                    >
                        {user.username}
                    </Dropdown.Item>
                  ))}
                </div>
                </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {/* Filter for Date */}
        <div className="col-wise">
          <span className="sub-heading-network ms-2">Date Range</span>

          <ReactDatePicker
            disabled={searchFilters.hasSearchString}
            selected={dateFilter.startDate}
            onChange={(e: any) => {
              setDateFilter({ startDate: e[0], endDate: e[1] });
            }}
            startDate={dateFilter.startDate}
            endDate={dateFilter.endDate}
            selectsRange
            isClearable
            dateFormat={"yyyy/MM/dd"}
            monthsShown={2}
            closeOnScroll
            placeholderText="Start Date - End Date"
            className="filter-input-box filter-text ps-3"
          />
        </div>
        <div className="row-wise ms-2 align-items-end">
          <SecondaryButton
            disable={searchFilters.hasSearchString}
            label="Clear All"
            type="delete"
            clicked={clearAllFilters}
            className=" filter-buttons"
          />
          <PrimaryButton label="Filter" disable={searchFilters.hasSearchString} clicked={applyFilters} className="ms-2 filter-buttons" />
        </div>
      </div>
      <ErrorAlert
          show={error.alert}
          onDismiss={() => {
              setError({ message: "", alert: false });
          } }
          message={error.message} />
    </div>
  );
};

export default CBSDAssetFilter;