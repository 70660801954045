import { LEAF_ICONS, SMB_ICONS } from "../constants/IconConstants";
import ClusterCircle from "../assets/clusterCircle.svg";
import { CBSDMountingTypes } from "./../constants/GlobalConstants";

export const isFilled = (inputVar: any) => {
  return inputVar !== undefined && inputVar !== null && inputVar.toString().trim();
};

export const exists = (inputVar: any) => {
  return !(inputVar === undefined || inputVar === null || (typeof inputVar === "string" && !inputVar.trim()));
};

export const isNumeric = (inputVar: any) => {
  return typeof inputVar === "number";
};

export const getRegion = () => {
  return localStorage.getItem("region") ? localStorage.getItem("region") : "";
};

export const getClusterIcon = (): Promise<google.maps.Icon> => {
  return new Promise((resolve: any, reject: any) => {
    let icon: google.maps.Icon = {
      url: ClusterCircle,
      scaledSize: new window.google.maps.Size(30, 30),
      anchor: new window.google.maps.Point(15, 15),
      labelOrigin: new window.google.maps.Point(15, 15),
    };
    resolve(icon);
  });
};

{
  /**
   * returns the correct marker icon based on the zoom, status, and mounting type of the asset.
   * dots for zoom levels 0-14, and rotating leaves for zoom levels 15-22
   */
}
export const getMarkerIcon = (asset: any): Promise<google.maps.Icon> => {
  return new Promise((resolve: any, reject: any) => {
    rotateLeaves(asset).then((icon: any) => {
      resolve(icon);
    });
  });
};

const getMarker = (canvas: any, times: any) => {
  let url: string = canvas.toDataURL();
  return {
    url: url || "",
    scaledSize: new google.maps.Size(30 * times, 30 * times),
    anchor: new google.maps.Point(15 * times, 15 * times),
    labelOrigin: new google.maps.Point(15 * times, 50 - 20 * (1 - times)),
  };
};

const imageOnLoad = (context: any, canvas: any, marker: any, resolve: any, mountingType: any, asset: any) => {
  if (mountingType) {
    for (let index = 0; index < asset.azimuth.length; index++) {
      let angle = asset.azimuth[index];
      let sectorStatus: string = asset.status_sector[index];
      let leaf: any = LEAF_ICONS[sectorStatus];
      let image: HTMLImageElement = new Image();
      image.onload = () => {
        if (context) {
          context.save();
          context.translate(canvas.width / 2, canvas.height / 2);
          context.rotate((angle * Math.PI) / 180);
          context.drawImage(image, -image.width / 2, -image.height / 2);
          context.restore();
        }
        if (index + 1 === asset.azimuth.length) {
          marker = getMarker(canvas, 2);
          resolve(marker);
        }
      };
      image.src = leaf;
    }
  } else {
    const smbIcon = SMB_ICONS[asset.status];
    let image: HTMLImageElement = new Image();
    image.onload = () => {
      if (context) {
        context.save();
        context.translate(canvas.width / 2, canvas.height / 2);
        context.drawImage(image, -image.width / 2, -image.height / 2);
        context.restore();
      }
      marker = getMarker(canvas, 1);
      resolve(marker);
    };
    image.src = smbIcon;
  }
};

{
  /**
   * rotate the leaf based on the azimuth of the asset.
   * creates a canvas and draws the leaf on it, then returns icon for the marker.
   */
}
const rotateLeaves = (asset: any): Promise<google.maps.Icon> => {
  return new Promise((resolve: any, reject: any) => {
    let marker: google.maps.Icon = {
      url: "",
    };
    if ([CBSDMountingTypes.AERIAL, CBSDMountingTypes.ROE].includes(asset.mounting_type)) {
      const canvas = document.createElement("canvas");
      canvas.width = 200;
      canvas.height = 200;
      canvas.style.backgroundColor = "transparent";
      let context = canvas.getContext("2d");
      imageOnLoad(context, canvas, marker, resolve, "AERIAL-ROE", asset);
      if (!canvas) {
        reject(marker);
      }
    } else {
      const canvas = document.createElement("canvas");
      canvas.width = 75;
      canvas.height = 75;
      canvas.style.backgroundColor = "transparent";
      let context = canvas.getContext("2d");
      imageOnLoad(context, canvas, marker, resolve, "", asset);
      if (!canvas) {
        reject(marker);
      }
    }
  });
};
