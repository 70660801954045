import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AssetProps, { SectorObj } from "../interfaces/AssetProps";
import { ApiHandler } from "../services/apiHandler";
import ConfirmAlert from "./confirmAlert";
import ErrorAlert from "./ErrorAlert";
import LabelLessInput from "./LabelLessInput";
import Modal from "./Modal";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import Spinner from "./Spinner";
import SuccessAlert from "./SuccessAlert";
import WarningAlert from "./WarningAlert";
import PolePrimary from "../assets/pole_primary.svg";
import LedPrimary from "../assets/led_primary.svg";
import MiscPrimary from "../assets/misc_primary.svg";
import LabelPrimary from "../assets/label_primary.svg";
import "./AssetDetailsPanel.css";

interface AssetDetailsPanelProps {
  assetDetails: AssetProps | undefined;
  goBack: any;
  setAction: any;
  rmaSerialNumber: any;
}

const AssetDetailsPanel: React.FC<AssetDetailsPanelProps> = ({ rmaSerialNumber, assetDetails, goBack, setAction }) => {
  let navigate = useNavigate();
  const [replaceModal, showReplaceModal] = useState(false);
  const [newSerialNumber, setNewSerialNumber] = useState("");
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [siteImages, setSiteImages] = useState<any[]>();
  const [error, setError] = useState({
    message: "",
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: "",
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: "",
    alert: false,
  });
  const [viewImage, setViewImage] = useState({
    image: "",
    fileName: "",
    popup: false,
  });

  useEffect(() => {
    type imageType = string | null | undefined;
    let images: imageType[] = [
      assetDetails?.asset.site_image_1,
      assetDetails?.asset.site_image_2,
      assetDetails?.asset.site_image_3,
      assetDetails?.asset.site_image_4,
    ];
    let availableImages: imageType[] = [];
    images.forEach((image: imageType) => {
      if (!["", null, undefined].includes(image)) {
        availableImages.push(image);
      }
    });
    setSiteImages(availableImages);
  }, [assetDetails]);

  const getImage = (image: string) => {
    setLoading(true);
    ApiHandler({ apiName: "download", body: { path: image }, params: {} })
      .then((response: any) => {
        setViewImage({ image: response.data.file, fileName: image.slice(image.lastIndexOf("/") + 1), popup: true });
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const downloadImage = () => {
    try {
      saveAs(viewImage.image, `${assetDetails?.asset.tracking_id}_${viewImage.fileName}`);
    } catch (error: any) {
      console.info(error);
    }
  };

  const replaceClicked = (e: any) => {
    if (newSerialNumber.trim() === "") {
      setWarning({ message: "Serial number cannot be empty", alert: true });
      return;
    }
    rmaSerialNumber(newSerialNumber);
    setAction("rma");
    goBack("editAsset");
  };

  const deleteAsset = () => {
    setDeleteConfirmVisible(false);
    setLoading(true);
    ApiHandler({ apiName: "deleteAsset", body: {}, params: { tracking_id: assetDetails?.asset.tracking_id } })
      .then((response: any) => {
        setLoading(false);
        goBack("home");
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const editClicked = () => {
    setAction("edit");
    goBack("editAsset");
  };

  const initiateRma = () => {
    let count: number = 0;
    assetDetails?.sectors.map((sector: SectorObj) => {
      if (sector.status === "SAS") {
        count++;
      }
    });
    if (assetDetails?.sectors.length === count) {
      showReplaceModal(true);
    } else {
      setWarning({ message: "CBSD can only be replaced if all the sectors are in SAS.", alert: true });
    }
  };

  const imageTypes: any = {
    led: LedPrimary,
    pole: PolePrimary,
    label: LabelPrimary,
    misc: MiscPrimary,
  };

  const getDeviceTitle: any = {
    cbrs_5g: "CBSD",
    wifi_6e: "WIFI",
    cpe_cbsd: "CPE",
    cpe_wifi: "CPE",
    "": "Asset",
  };

  return (
    <div className="app-card position-relative app-card-size overflow-auto">
      <div className="row-wise vertical-center">
        <div
          className="icon-background-active"
          onClick={() => {
            goBack("home");
          }}
        >
          <span className="material-icons-round icon-active">arrow_back</span>
        </div>
        <span className="app-card-action-heading ms-3">{`${
          getDeviceTitle[assetDetails?.asset?.technology_type ?? ""]
        } Details`}</span>
      </div>

      <span className="asset-name mt-3">
        {assetDetails?.asset?.cbrs_node_name ? assetDetails?.asset?.cbrs_node_name : "-"}
      </span>
      <span className="asset-description mt-2">
        {assetDetails?.asset?.description ? assetDetails?.asset?.description : ""}
      </span>
      <hr className="breaker"></hr>

      {assetDetails?.asset?.deployment_location === "indoor" ? (
        <div className="col-wise w-100 indoor-details mb-3">
          <span className="indoor-heading mb-2">Venue Details</span>
          {assetDetails?.asset?.venue_name && (
            <span className="indoor-sub-heading mb-1">
              Venue: <span className="indoor-value">{assetDetails?.asset?.venue_name}</span>
            </span>
          )}
          {assetDetails?.asset?.building_name && (
            <span className="indoor-sub-heading mb-1">
              Building: <span className="indoor-value">{assetDetails?.asset?.building_name}</span>
            </span>
          )}
          {assetDetails?.asset?.floor_number && (
            <span className="indoor-sub-heading">
              Floor: <span className="indoor-value">{assetDetails?.asset?.floor_number}</span>
            </span>
          )}
        </div>
      ) : (
        <></>
      )}

      <div className="overflow-auto h-100">
        <div className="row g-0">
          <div className="col col-wise">
            <span className="value-title">Tracking ID:</span>
            <span className="value-label mt-1">
              {assetDetails?.asset?.tracking_id ? assetDetails?.asset?.tracking_id : "-"}
            </span>
          </div>
          <div className="col col-wise">
            <span className="value-title">Site ID:</span>
            <span className="value-label mt-1">{assetDetails?.asset?.site_id ? assetDetails?.asset?.site_id : ""}</span>
          </div>
        </div>

        <div className="row g-0">
          <div className="col col-wise">
            <span className="value-title mt-3">Latitude:</span>
            <span className="value-label mt-1">
              {assetDetails?.asset?.latitude ? assetDetails?.asset?.latitude : "-"}
            </span>
          </div>
          <div className="col col-wise">
            <span className="value-title mt-3">Longitude:</span>
            <span className="value-label mt-1">
              {assetDetails?.asset?.longitude ? assetDetails?.asset?.longitude : "-"}
            </span>
          </div>
        </div>

        <div className="row g-0">
          <div className="col col-wise">
            <span className="value-title mt-3">Height:</span>
            <span className="value-label mt-1">
              {assetDetails?.asset?.height ? assetDetails?.asset?.height : "-"} ft
            </span>
          </div>
          <div className="col col-wise">
            <span className="value-title mt-3">Serial #:</span>
            <span className="value-label mt-1">
              {assetDetails?.asset?.serial_number ? assetDetails?.asset?.serial_number : "-"}
            </span>
          </div>
        </div>

        {siteImages?.length ? (
          <div className="mt-3">
            <span className="value-title">Site Images:</span>
            <div className="col-wise">
              {siteImages &&
                siteImages.map((image: any) => {
                  let imageName: string = image.slice(image.lastIndexOf("/") + 1);
                  let imageType: string = imageName.slice(0, imageName.indexOf("_"));
                  return (
                    <div
                      key={imageName}
                      className="vertical-center row-wise mt-2 ps-2 pt-2 pb-2 image-boxes"
                      onClick={() => {
                        getImage(image);
                      }}
                    >
                      <img src={imageTypes[imageType]} width={15} />
                      <span className="value-label ms-2">{imageName}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="h-auto mt-2 col-wise justify-content-end">
        <div className="row g-0 mb-2">
          <div className="col">
            <SecondaryButton
              icon="delete"
              type="delete"
              clicked={() => {
                setDeleteConfirmVisible(true);
              }}
              label="Delete"
            />
          </div>
          {!["wifi_6e", "cpe_cbsd", "cpe_wifi", undefined, null].includes(assetDetails?.asset?.technology_type) ? (
            <div className="col ms-2">
              <SecondaryButton icon="sync_alt" clicked={initiateRma} label="RMA" />
            </div>
          ) : (
            <></>
          )}
        </div>
        <SecondaryButton icon="edit" clicked={editClicked} label="Edit" />
      </div>

      <Modal show={replaceModal}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content app-card border-0 p-0">
            <div className="modal-header">
              <h5 className="modal-title">{"Replace CBSD"}</h5>
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  showReplaceModal(false);
                }}
              ></button>
            </div>
            <div className="modal-body overflow-hidden">
              <span className="app-card-action-heading ms-2" style={{ textTransform: "none" }}>
                Old Serial Number
              </span>
              <LabelLessInput
                className="mt-2 mb-4"
                placeholder="Old Serial Number"
                value={assetDetails?.asset?.serial_number ? assetDetails?.asset?.serial_number : ""}
                disabled
                w100
              />
              <span className="app-card-action-heading ms-2" style={{ textTransform: "none" }}>
                New Serial Number
              </span>
              <LabelLessInput
                className="mt-2"
                placeholder="New Serial Number"
                value={newSerialNumber}
                newValue={(value: any) => setNewSerialNumber(value)}
                w100
              />
            </div>
            <div className="modal-footer">
              <PrimaryButton
                label={"Replace"}
                clicked={(e: any) => {
                  replaceClicked(e);
                }}
                className="w-100"
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={viewImage.popup}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content app-card border-0 p-0">
            <div className="modal-header">
              <h5 className="modal-title">{"Site Image"}</h5>
              <div className="row-wise download-box ms-2" onClick={downloadImage}>
                <span className="material-icons-round me-1 download-icon">file_download</span>
                <span className="download-label">DOWNLOAD</span>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  setViewImage({ image: "", fileName: "", popup: false });
                }}
              ></button>
            </div>
            <div className="modal-body p-0 overflow-hidden">
              <img
                loading="eager"
                onLoad={() => {
                  setLoading(false);
                }}
                className="w-100"
                src={viewImage.image}
              />
            </div>
          </div>
        </div>
      </Modal>

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: "", alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: "", alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: "", alert: false });
        }}
        message={warning.message}
      />
      <Spinner show={loading} />
      <ConfirmAlert
        title={`Delete ${getDeviceTitle[assetDetails?.asset?.technology_type ?? ""]}`}
        message={`Are you sure you want to delete ${getDeviceTitle[assetDetails?.asset?.technology_type ?? ""]}?`}
        note={`Tracking ID: ${assetDetails?.asset.tracking_id}`}
        primaryText="Confirm"
        secondaryText="Cancel"
        secondaryAction={() => {
          setDeleteConfirmVisible(false);
        }}
        primaryAction={() => {
          deleteAsset();
        }}
        visible={deleteConfirmVisible}
      />
    </div>
  );
};

export default AssetDetailsPanel;
