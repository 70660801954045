import React, { useEffect, useState } from "react";
import "./_buildingdetails.css";
import { IBuildingDetailsProps } from "./IBuildingDetailsProps";
import { useNavigate } from "react-router-dom";
import WarningAlert from "../WarningAlert";
import SuccessAlert from "../SuccessAlert";
import ErrorAlert from "../ErrorAlert";
import ConfirmAlert from "../confirmAlert";
import Spinner from "../Spinner";
import { ApiHandler } from "../../services/apiHandler";
import PrimaryButton from "../PrimaryButton";

export const BuildingDetails: React.FC<IBuildingDetailsProps> = (props) => {
  let navigate = useNavigate();
  const [building, setBuilding] = useState<any>();
  const [floors, setFloors] = useState<any[]>();
  const [loading, setLoading] = useState(false);
  const [totalAssets, setTotalAssets]: any = useState();
  const [outdoorAssets, setOutdoorAssets]: any = useState();
  const [totalAssetStatus, setTotalAssetStatus]: any = useState({ fieldTech: 0, cloud: 0, sas: 0 });
  const [outdoorAssetStatus, setOutdoorAssetStatus]: any = useState({ fieldTech: 0, cloud: 0, sas: 0 });
  const [error, setError] = useState({
    message: "",
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: "",
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: "",
    alert: false,
  });
  const [deleteBuildingConfirmModal, setDeleteBuildingConfirmModal] = useState({ alert: false, message: "" });
  const [deleteFloorConfirmModal, setDeleteFloorConfirmModal] = useState(false);
  const [assets, setAssets]: any = useState();
  const [activeFloorId, setActiveFloorId] = useState(null);

  useEffect(() => {
    props.setFloorId(null);
  }, []);

  useEffect(() => {
    getBuildingDetails();
    listFloors();
  }, [props.buildingId]);

  const getBuildingDetails = () => {
    setLoading(true);
    ApiHandler({ apiName: "getBuilding", body: {}, params: { building_id: props.buildingId } })
      .then((res) => {
        setLoading(false);
        setBuilding(res.data);
        setAssets(res.data.assets);
        setTotalAssets(res.data.total_assets);
        setOutdoorAssets(res.data.asset_on_building);
        setTotalAssetStatus({
          fieldTech: res.data.total_field_tech,
          cloud: res.data.total_cloud,
          sas: res.data.total_sas,
        });
        setOutdoorAssetStatus({
          fieldTech: res.data.outdoor_field_tech,
          cloud: res.data.outdoor_cloud,
          sas: res.data.outdoor_sas,
        });
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const listFloors = () => {
    setLoading(true);
    ApiHandler({ apiName: "listFloors", body: {}, params: { building_id: props.buildingId } })
      .then((res) => {
        setLoading(false);
        setFloors(res.data);
        // props.setFloorsForBuilding(res.data);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const deleteBuilding = () => {
    setLoading(true);
    ApiHandler({ apiName: "deleteBuilding", body: {}, params: { building_id: props.buildingId } })
      .then((res) => {
        setLoading(false);
        setSuccess({ message: "Building Deleted Successfully", alert: true });
        setTimeout(() => {
          props.navigateTo("venueDetails");
        }, 1000);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const goToFloor = (floor: any) => {
    props.setFloorId(floor?.id);
    props.navigateTo("floorDetails");
  };

  const checkFloorsInBuilding = () => {
    if (floors?.length != 0) {
      setWarning({ message: "Please delete floors before deleting buildings", alert: true });
    } else {
      let deleteMessage: string = "<span>Following assets are present.</span><br/><br/><ul>";
      assets.forEach((asset: any) => {
        deleteMessage += "<li>" + asset.tracking_id + "</li>";
      });
      deleteMessage +=
        "</ul><i>On confirming the deletion, the assets will <b>automatically be dissociated from the building</b> but will still remain associated to the venue <br/><br/> Do you want to proceed?</i>";
      setDeleteBuildingConfirmModal(
        assets?.length
          ? { alert: true, message: deleteMessage }
          : { alert: true, message: "Are you sure you want to delete the building" }
      );
    }
  };

  const removeAssetsBuilding = () => {
    setLoading(true);
    const requestBody: any = { asset_ids: [] };
    assets.forEach((asset: any) => {
      requestBody.asset_ids.push(asset.id);
    });
    ApiHandler({ apiName: "removeAssetFromBuilding", body: requestBody, params: {} })
      .then((response: any) => {
        setLoading(false);
        deleteBuilding();
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  return (
    <div className="overflow-auto h-100">
      <div className="app-card position-relative app-card-size">
        <div className="row-wise vertical-center justify-content-between">
          <div className="row-wise vertical-center">
            <div
              className="icon-background-active"
              onClick={() => {
                props.navigateTo("venueDetails");
              }}
            >
              <span className="material-icons-round icon-active">arrow_back</span>
            </div>
            <label className="building-name ms-3">{building?.name}</label>
          </div>
          {/* Edit and delete options */}
          <div className="btn-group dropstart">
            <span
              title="Options"
              className="material-icons-round user-select-none cursor-pointer"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              more_vert
            </span>
            <ul className="dropdown-menu">
              <li>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => {
                    props.navigateTo("editBuilding");
                  }}
                >
                  Edit
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item color-red"
                  type="button"
                  onClick={() => {
                    checkFloorsInBuilding();
                  }}
                >
                  Delete
                </button>
              </li>
            </ul>
          </div>
        </div>

        <hr style={{ borderTop: "5px solid #e5e5e5" }}></hr>

        {/* Building Details */}
        <div className="col-wise overflow-auto h-100">
          <span className="entity-title mb-2">Description</span>
          <span className="entity-label mb-3">
            {building?.description ? building?.description : <em style={{ color: "gray" }}>No description added</em>}
          </span>
          <span className="entity-title mb-2">Address</span>
          <span className="entity-label mb-3">{building?.address}</span>
          <span className="entity-title mb-2">
            {`${localStorage.getItem("technology_type_name") === "wifi_6e" ? "WIFI" : "5G"} Devices`}{" "}
          </span>

          <div className="row-wise">
            <div className="w-50 me-1 result-box col-wise">
              <span className="result-title">Total in/on Building</span>
              <span className="result-label">{totalAssets}</span>
              <div className="row-wise align-items-center">
                <div title="Field Tech" className="field-tech-circle me-1"></div>
                <span title="Field Tech" className="me-2 result-title">
                  {totalAssetStatus.fieldTech}
                </span>

                <div title="Cloud" className="cloud-circle me-1"></div>
                <span title="Cloud" className="me-2 result-title">
                  {totalAssetStatus.cloud}
                </span>

                {localStorage.getItem("technology_type_name") !== "wifi_6e" ? (
                  <div className="row-wise align-items-center">
                    <div title="SAS" className="sas-circle me-1"></div>
                    <span title="SAS" className="me-2 result-title">
                      {totalAssetStatus.sas}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="w-50 ms-1 result-box col-wise">
              <span className="result-title">Placed on Building</span>
              <span className="result-label">{outdoorAssets}</span>
              <div className="row-wise align-items-center">
                <div title="Field Tech" className="field-tech-circle me-1"></div>
                <span title="Field Tech" className="me-2 result-title">
                  {outdoorAssetStatus.fieldTech}
                </span>

                <div title="Cloud" className="cloud-circle me-1"></div>
                <span title="Cloud" className="me-2 result-title">
                  {outdoorAssetStatus.cloud}
                </span>

                {localStorage.getItem("technology_type_name") !== "wifi_6e" ? (
                  <div className="row-wise align-items-center">
                    <div title="SAS" className="sas-circle me-1"></div>
                    <span title="SAS" className="me-2 result-title">
                      {outdoorAssetStatus.sas}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="row-wise justify-content-between mt-3 mb-3 vertical-center">
            <label className="floor-title">FLOORS</label>
            <span
              title="Add New Floor"
              className="material-icons-round add-floor-icon"
              onClick={() => {
                props.setFloorActionType("add");
                props.navigateTo("floorActions");
              }}
            >
              add_circle_outline
            </span>
          </div>

          <div className="floors-box">
            {floors && floors?.length > 0 ? (
              floors.map((floor: any) => {
                return (
                  <div
                    className={props.floorId === floor?.id ? "floor-selected" : "floor-default"}
                    onClick={() => {
                      if (floor?.id !== props.floorId) {
                        props.setFloorId(floor?.id);
                      }
                    }}
                  >
                    <span>{"F" + floor.floor_number}</span>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>

          {/* Floor Details */}
          <div className="overflow-auto h-100 col-wise mt-3">
            {floors && floors?.length > 0 ? (
              floors?.map((floor: any) => {
                if (floor?.id === props.floorId) {
                  return (
                    <div
                      className="floor-card"
                      onClick={() => {
                        goToFloor(floor);
                      }}
                      key={floor.id}
                    >
                      {/* Image */}
                      {floor.image && floor.image !== "" ? (
                        <img src={floor.image} className=" floor-image-box image" />
                      ) : (
                        <div className="floor-image-box">
                          <span className="material-icons-outlined icon">dashboard</span>
                        </div>
                      )}
                      {/* Details */}
                      <div className="floor-details ms-3 w-100">
                        <div className="row-wise vertical-center justify-content-between w-100 mb-2">
                          <label className="name">
                            {floor?.floor_type === "Parking" ? "P" : floor?.floor_type === "Basement" ? "B" : "F"}
                            {floor?.floor_number}
                          </label>
                          <span className="material-icons-outlined icon">navigate_next</span>
                        </div>
                        <label className="description">{`${floor?.total_assets} ${
                          localStorage.getItem("technology_type_name") === "wifi_6e" ? "WIFI" : "5G"
                        } devices`}</label>
                        <label className="description mb-2 h-100">{floor.description}</label>
                        {/* <label className="total-floors">{floor.total_floors} Floors</label> */}
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div className="text-center">No Floors Added</div>
            )}
          </div>
          {/* <div className="h-100 mb-3 align-content-start">
              <div className="overflow-auto floor-list vertical-center">
          
              </div>
            </div> */}
        </div>

        <PrimaryButton
          clicked={() => {
            props.navigateTo("venueDetails");
          }}
          label="Back To Venue"
        />
      </div>

      <Spinner show={loading} />

      {/* Delete Building confirmation modal */}
      <ConfirmAlert
        message={deleteBuildingConfirmModal.message}
        primaryText={"Yes"}
        secondaryText={"No"}
        secondaryAction={() => {
          setDeleteBuildingConfirmModal({ alert: false, message: "" });
        }}
        visible={deleteBuildingConfirmModal.alert}
        title={"Delete Building"}
        primaryAction={() => {
          setDeleteBuildingConfirmModal({ alert: false, message: "" });
          assets?.length !== 0 ? removeAssetsBuilding() : deleteBuilding();
        }}
      />

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: "", alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: "", alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: "", alert: false });
        }}
        message={warning.message}
      />
    </div>
  );
};
