import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AssetProps from "../interfaces/AssetProps";
import { ApiHandler } from "../services/apiHandler";
import "./AssetParameters.css";
import CbsdAccordion from "./CbsdAccordion";
import ConfirmAlert from "./confirmAlert";
import ErrorAlert from "./ErrorAlert";
import InstallationAccordion from "./InstallationAccordion";
import LabelledInput from "./LabelledInput";
import Modal from "./Modal";
import OptionalAccordion from "./OptionalAccordion";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import SuccessAlert from "./SuccessAlert";

interface AssetParametersProps {
  assetDetails: AssetProps | undefined;
  setUpdatedAsset: any;
  processing: any;
  refreshAsset: any;
}

const AssetParameters: React.FC<AssetParametersProps> = ({
  assetDetails,
  setUpdatedAsset,
  processing,
  refreshAsset,
}) => {
  let navigate = useNavigate();
  const [userRole, setUserRole] = useState("");
  const [cpiId, setCpiId] = useState("");
  const [cpiName, setCpiName] = useState("");
  const [selectedSectorIndex, setSectorindex] = useState(0);
  const [asset, setAsset] = useState(assetDetails?.asset);
  const [sectors, setSectors] = useState(assetDetails?.sectors || []);
  const [vendor, setVendor] = useState(assetDetails?.vendor);
  const [saved, setSaved] = useState(false);
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState("");
  const [sasConfirmModal, showSasConfirmModal] = useState(false);
  const [submittedSectors, setSubmittedSectors] = useState<string[]>([]);
  const [nextSectorPopup, showNextSectorPopup] = useState(false);
  const [error, setError] = useState({
    message: "",
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: "",
    alert: false,
  });

  const getDeviceTitle: any = {
    wifi_6e: "WIFI",
    cbrs_5g: "CBSD",
    cpe_cbsd: "CPE",
    cpe_wifi: "CPE",
    "": "Asset",
  };

  useEffect(() => {
    setUserRole(localStorage.getItem("role")?.toString() || "");
  }, []);

  useEffect(() => {
    setAsset(assetDetails?.asset);
    setSectors(assetDetails?.sectors || []);
    setVendor(assetDetails?.vendor);
  }, [assetDetails]);

  useEffect(() => {
    if (userRole === "installer") {
      if (
        sectors[selectedSectorIndex].submitted_to_cloud ||
        sectors[selectedSectorIndex].submitted_to_sas ||
        ["Cloud", "RMA Requested", "RMA in progress", null].includes(sectors[selectedSectorIndex].status)
      ) {
        setPrimaryButtonLabel("Update to Cloud");
      } else {
        setPrimaryButtonLabel("Submit to Cloud");
      }
    } else {
      if (!["wifi_6e", "cpe_wifi", null, undefined].includes(asset?.technology_type)) {
        if (asset?.technology_type === "cpe_cbsd" && vendor?.cbsd_category === "EUD") {
          if (sectors[selectedSectorIndex].submitted_to_cloud) setPrimaryButtonLabel("Update to Cloud");
          else setPrimaryButtonLabel("Submit to Cloud");
        } else if (
          sectors[selectedSectorIndex].submitted_to_sas ||
          ["SAS", "RMA Requested", "RMA in progress", null].includes(sectors[selectedSectorIndex].status)
        ) {
          setPrimaryButtonLabel("Update to SAS");
        } else if (
          sectors[selectedSectorIndex].submitted_to_sas ||
          ["AFC", null].includes(sectors[selectedSectorIndex].status)
        ) {
          setPrimaryButtonLabel("Update to AFC");
        } else if (localStorage.getItem("provider_type") === "sas") {
          setPrimaryButtonLabel("Submit to SAS");
        } else if (localStorage.getItem("provider_type") === "afc") {
          setPrimaryButtonLabel("Submit to AFC");
        }
      } else {
        if (asset?.technology_type === "cpe_wifi" && vendor?.cbsd_category === "EUD") {
          if (sectors[selectedSectorIndex].submitted_to_cloud) setPrimaryButtonLabel("Update to Cloud");
          else setPrimaryButtonLabel("Submit to Cloud");
        } else setPrimaryButtonLabel("Submit to OpenWiFi");
      }
    }
  }, [sectors, selectedSectorIndex, userRole]);

  useEffect(() => {
    if (submittedSectors.length !== 0) {
      const remainingSectors = sectors.filter((sector: any) => {
        return ![...submittedSectors, null].includes(sector.sector_id);
      });
      if (remainingSectors.length > 0) {
        showNextSectorPopup(true);
      }
    }
  }, [submittedSectors]);

  const goToNextSector = () => {
    const remainingSectors = sectors.filter((sector: any) => {
      return ![...submittedSectors, null].includes(sector.sector_id);
    });
    console.log(remainingSectors);

    sectors.forEach((sector: any, index: any) => {
      if (sector.sector_id === remainingSectors[0].sector_id) {
        console.log(sector.sector_id, remainingSectors[0], index);
        setSectorindex(index);
      }
    });
  };

  const updateAssetValues = (name: string, value: any, type: string) => {
    switch (type) {
      case "asset":
        setAsset((prevValues: any) => {
          return { ...prevValues, [name]: value };
        });
        break;
      case "sector":
        setSectors((prevValues: any) => {
          return [
            ...prevValues.slice(0, selectedSectorIndex),
            {
              ...prevValues[selectedSectorIndex],
              [name]: value,
            },
            ...prevValues.slice(selectedSectorIndex + 1),
          ];
        });
        break;
      case "vendor":
        setVendor((prevValues: any) => {
          return { ...prevValues, [name]: value };
        });
        break;
    }
  };

  const updateAsset = () => {
    processing(true);
    let requestBody: any = asset;
    requestBody["latitude"] = parseFloat(requestBody["latitude"]);
    requestBody["longitude"] = parseFloat(requestBody["longitude"]);
    requestBody["sectors"] = sectors;
    requestBody["sectors"].map((sector: any) => {
      delete sector.submitted_to_cloud;
      delete sector.submitted_to_sas;
    });
    ApiHandler({ apiName: "updateAsset", body: requestBody, params: { tracking_id: asset?.tracking_id } })
      .then((response: any) => {
        processing(false);
        setSuccess({
          alert: true,
          message: `${getDeviceTitle[asset?.technology_type ?? ""]} updated successfully.`,
        });
        setUpdatedAsset(response.data);
        setSaved(true);
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const submitToSAS = () => {
    processing(true);
    let requestBody: any = {
      tracking_id: asset?.tracking_id?.toString() || "",
      sector_id: sectors[selectedSectorIndex].sector_id?.toString() || "",
      cpi_id: cpiId,
      cpi_name: cpiName,
    };
    ApiHandler({ apiName: "submitSAS", body: requestBody, params: {} })
      .then((response: any) => {
        processing(false);
        setSuccess({ alert: true, message: response.data.message });
        setSaved(false);
        refreshAsset(asset?.tracking_id);
        setSubmittedSectors((prevValues: any) => {
          return [...prevValues, sectors[selectedSectorIndex].sector_id];
        });
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const submitToCloud = () => {
    processing(true);
    let requestBody: any = {
      tracking_id: asset?.tracking_id?.toString() || "",
      sector_id: sectors[selectedSectorIndex].sector_id?.toString() || "",
      status: ["RMA Requested", "RMA in progress", null].includes(sectors[selectedSectorIndex]?.status)
        ? "RMA Requested"
        : "Cloud",
    };
    ApiHandler({ apiName: "submitCloud", body: requestBody, params: {} })
      .then((response: any) => {
        processing(false);
        setSuccess({ alert: true, message: response.data.message });
        setSaved(false);
        refreshAsset(asset?.tracking_id);
        setSubmittedSectors((prevValues: any) => {
          return [...prevValues, sectors[selectedSectorIndex].sector_id];
        });
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const updateToSAS = (apiType: string) => {
    processing(true);
    let requestBody: any = {
      tracking_id: asset?.tracking_id?.toString() || "",
      sector_id: sectors[selectedSectorIndex].sector_id?.toString() || "",
      cpi_id: cpiId,
      cpi_name: cpiName,
    };
    ApiHandler({ apiName: apiType, body: requestBody, params: {} })
      .then((response: any) => {
        processing(false);
        setSuccess({ alert: true, message: response.data.message });
        setSaved(false);
        refreshAsset(asset?.tracking_id);
        setSubmittedSectors((prevValues: any) => {
          return [...prevValues, sectors[selectedSectorIndex].sector_id];
        });
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const updateToCloud = () => {
    processing(true);
    let requestBody: any = {
      tracking_id: asset?.tracking_id?.toString() || "",
      sector_id: sectors[selectedSectorIndex].sector_id?.toString() || "",
      status: ["RMA Requested", "RMA in progress", null].includes(sectors[selectedSectorIndex].status)
        ? "RMA Requested"
        : "Cloud",
    };
    ApiHandler({ apiName: "submitCloud", body: requestBody, params: {} })
      .then((response: any) => {
        processing(false);
        setSuccess({ alert: true, message: response.data.message });
        setSaved(false);
        refreshAsset(asset?.tracking_id);
        setSubmittedSectors((prevValues: any) => {
          return [...prevValues, sectors[selectedSectorIndex].sector_id];
        });
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const primaryButtonClicked = () => {
    // if (userRole === "installer") {
    //   if (primaryButtonLabel === "Submit to Cloud") submitToCloud();
    //   else updateToCloud();
    // } else if (asset?.technology_type !== "wifi_6e") {
    //   if (["Submit to AFC", "Update to AFC"].includes(primaryButtonLabel)) {
    //     return;
    //   } else initiateSasSubmission();
    //   initiateSasSubmission();
    // } else submitToOpenWifi();
    if (["Submit to SAS", "Update to SAS"].includes(primaryButtonLabel)) initiateSasSubmission();
    else if (["Submit to AFC", "Update to AFC"].includes(primaryButtonLabel)) return;
    else if (primaryButtonLabel === "Submit to Cloud") submitToCloud();
    else if (primaryButtonLabel === "Update to Cloud") updateToCloud();
    else if (primaryButtonLabel === "Submit to OpenWiFi") submitToOpenWifi();
  };

  const submitToOpenWifi = () => {
    processing(true);
    let requestBody: any = {
      tracking_id: asset?.tracking_id?.toString() || "",
      sector_id: sectors[selectedSectorIndex].sector_id?.toString() || "",
      serial_number: asset?.serial_number?.toString() || "",
      name: asset?.cbrs_node_name?.toString() || "",
    };
    ApiHandler({ apiName: "submitOpenWifi", body: requestBody, params: {} })
      .then((response: any) => {
        processing(false);
        setSuccess({ alert: true, message: response.data.message });
        setSaved(false);
        refreshAsset(asset?.tracking_id);
        setSubmittedSectors((prevValues: any) => {
          return [...prevValues, sectors[selectedSectorIndex].sector_id];
        });
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const initiateSasSubmission = () => {
    processing(true);
    ApiHandler({ apiName: "getCpiDetails", body: {}, params: { username: localStorage.getItem("username") } })
      .then((response: any) => {
        processing(false);
        setCpiId(response.data.cpi_id);
        setCpiName(response.data.cpi_name);
        showSasConfirmModal(true);
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const sasConfirmed = () => {
    showSasConfirmModal(false);
    if (primaryButtonLabel === "Submit to SAS") submitToSAS();
    else if (["RMA Requested", "RMA in progress", null].includes(sectors[selectedSectorIndex].status))
      updateToSAS("replaceSAS"); // RMA API in case of RMA flow.
    else updateToSAS("updateSAS"); // Update API in case of normal flow.
  };

  const sectorStatusClass: any = {
    "RMA Requested": "status-circle-rma-requested",
    "RMA in progress": "status-circle-rma-in-progress",
    SAS: "status-circle-sas",
    Cloud: "status-circle-cloud",
    "Field Tech": "status-circle-field-tech",
    "": "",
  };

  return (
    <div className="mt-2 h-100 col-wise overflow-auto">
      {/* Sector Tabs */}
      <div className="row-wise w-100">
        {asset?.mounting_type !== "SMB" &&
          sectors.map((sector: any, index: any) => {
            return (
              <div
                key={sector}
                onClick={() => {
                  setSectorindex(index);
                }}
                className={`w-100 row-wise align-items-center justify-content-center ${
                  index === selectedSectorIndex ? "sector-active" : "sector-tab"
                } ${index !== sectors.length - 1 ? "sector-border" : ""}`}
              >
                <div className={`${sectorStatusClass[sectors[index]?.status || ""]}`}></div>
                <span>SEC {sector.sector_id}</span>
              </div>
            );
          })}
      </div>

      <div className="p-3 h-100 col-wise overflow-auto">
        {/* sector details */}
        <div className="row g-0">
          <div className="col col-wise">
            <span className="value-title">Status:</span>
            <span className="value-label mt-2">{sectors[selectedSectorIndex]?.status}</span>
          </div>
          {/* This status part is commented since there are no two separate statuses called operational status and sas status as of now to show */}
          {/* <div className="col col-wise">
            <span className="value-title">SAS Status:</span>
            <span className="value-label mt-2">{assetDetails?.sectors[selectedSectorIndex]?.status}</span>
          </div> */}
        </div>

        {/* Accordions */}

        <div className="accordion mt-4 accordion-customs h-100 overflow-auto" id="sectorParams">
          <div className="accordion-item">
            <div className="accordion-header" id="cbsdAccordionHead">
              <button
                className="accordion-button accordion-headline collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#cbsdAccordion"
                aria-expanded="false"
                aria-controls="cbsdAccordion"
              >
                {`${getDeviceTitle[assetDetails?.asset?.technology_type ?? ""]}`}
              </button>
            </div>
            <div
              id="cbsdAccordion"
              className="accordion-collapse collapse"
              aria-labelledby="cbsdAccordionHead"
              data-bs-parent="#sectorParams"
            >
              <div className="accordion-body">
                <CbsdAccordion
                  updateDetails={updateAssetValues}
                  assetDetails={asset}
                  sectorDetails={sectors[selectedSectorIndex]}
                  vendorDetails={vendor}
                />
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="installationAccordionHead">
              <button
                className="accordion-button accordion-headline collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#installationAccordion"
                aria-expanded="false"
                aria-controls="installationAccordion"
              >
                Installation
              </button>
            </h2>
            <div
              id="installationAccordion"
              className="accordion-collapse collapse"
              aria-labelledby="installationAccordionHead"
              data-bs-parent="#sectorParams"
            >
              <div className="accordion-body">
                <InstallationAccordion
                  processing={processing}
                  assetDetails={asset}
                  sectorDetails={sectors[selectedSectorIndex]}
                  vendorDetails={vendor}
                  updateDetails={updateAssetValues}
                />
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="optionalAccordionHead">
              <button
                className="accordion-button accordion-headline collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#optionalAccordion"
                aria-expanded="false"
                aria-controls="optionalAccordion"
              >
                Optional
              </button>
            </h2>
            <div
              id="optionalAccordion"
              className="accordion-collapse collapse"
              aria-labelledby="optionalAccordionHead"
              data-bs-parent="#sectorParams"
            >
              <div className="accordion-body">
                <OptionalAccordion
                  assetDetails={asset}
                  sectorDetails={sectors[selectedSectorIndex]}
                  vendorDetails={vendor}
                  updateDetails={updateAssetValues}
                />
              </div>
            </div>
          </div>
        </div>

        <SecondaryButton className="mt-3" label="Save" clicked={updateAsset} />
        <PrimaryButton className="mt-2" label={primaryButtonLabel} disable={!saved} clicked={primaryButtonClicked} />
      </div>

      <Modal show={sasConfirmModal}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content app-card border-0 p-0">
            <div className="modal-header">
              <h5 className="modal-title">{"Submitting Parameters"}</h5>
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  showSasConfirmModal(false);
                }}
              ></button>
            </div>
            <div className="modal-body overflow-hidden">
              <span className="submit-parameter-info">Please enter CPI details.</span>

              <LabelledInput
                className="mt-2 mb-2"
                title="CPI ID"
                value={cpiId}
                onChange={(e: any) => {
                  setCpiId(e.target.value);
                }}
              />

              <LabelledInput
                className="mt-2 mb-2"
                title="CPI Name"
                value={cpiName}
                onChange={(e: any) => setCpiName(e.target.value)}
              />
            </div>
            <div className="modal-footer">
              <PrimaryButton
                label={"Submit Parameters"}
                clicked={(e: any) => {
                  sasConfirmed();
                }}
                className="w-100"
              />
            </div>
          </div>
        </div>
      </Modal>

      <ConfirmAlert
        visible={nextSectorPopup}
        title="Go to next sector"
        message="Ready for next sector?"
        primaryText="Take me there"
        secondaryText="No"
        secondaryAction={() => {
          showNextSectorPopup(false);
        }}
        primaryAction={() => {
          showNextSectorPopup(false);
          goToNextSector();
        }}
      />

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: "", alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: "", alert: false });
        }}
        message={success.message}
      />
    </div>
  );
};

export default AssetParameters;
