import React from "react";
import "./Modal.css";

interface ModalProps {
  show: boolean;
  children?: any;
  isNetworkActivity?: boolean;
}

const networkActivityModal = (show:any,isNetworkActivity:any) => {
  if (show) {
    return isNetworkActivity ? "network-activity-modal d-block" : "action-modal d-block"
  }
  return "d-none"
}

const Modal: React.FC<ModalProps> = ({ show = false, children, isNetworkActivity }) => {
  return (
    <div
      className={`modal ${
        networkActivityModal(show,isNetworkActivity)
      }`}
    >
      {children}
    </div>
  );
};

export default Modal;
