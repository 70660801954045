import React from "react";

interface SpinnerProps {
  show: boolean;
}
const Spinner: React.FC<SpinnerProps> = ({ show }) => {
  return (
    <div className={`${show ? "spinner-body" : "d-none"}`}>
      <div className="spinner center">
        <span></span>
      </div>
    </div>
  );
};

export default Spinner;
