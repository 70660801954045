import React from "react";

interface LabelLessinputProps {
  placeholder: string;
  value: string | number | undefined;
  newValue?: any;
  type?: any;
  disabled?: boolean;
  w100?: boolean;
  className?: string;
}

const LabelLessInput: React.FC<LabelLessinputProps> = ({
  placeholder,
  value,
  newValue,
  type = "text",
  disabled = false,
  w100 = false,
  className,
}) => {
  const setValue = (e: any) => {
    newValue(e.target.value);
  };
  return (
    <input
      className={`labelless-input ${className} ${w100 ? "w-100" : ""}`}
      autoComplete="new-password"
      placeholder={placeholder}
      value={value}
      onChange={setValue}
      type={type}
      disabled={disabled}
    />
  );
};

export default LabelLessInput;
