import React, { useEffect, useState } from "react";
import { IAddBuildingProps } from "./IAddBuildingprops";
import LabelledInput from "../LabelledInput";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";
import Dropzone from "react-dropzone";
import "./_addbuilding.css";
import { ApiHandler } from "../../services/apiHandler";
import Spinner from "../Spinner";
import ConfirmAlert from "../confirmAlert";
import ErrorAlert from "../ErrorAlert";
import SuccessAlert from "../SuccessAlert";
import WarningAlert from "../WarningAlert";
import { useNavigate } from "react-router-dom";

export const AddBuilding: React.FC<IAddBuildingProps> = (props) => {
  let navigate = useNavigate();
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: "",
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: "",
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: "",
    alert: false,
  });
  const [building, setBuilding] = useState({
    name: "",
    description: "",
    address: "",
    latitude: 0,
    longitude: 0,
    image: undefined,
    total_floors: 0,
  });
  const [venue, setVenue] = useState<any>();
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  useEffect(() => {
    setBuilding((prevValues: any) => {
      return {
        ...prevValues,
        latitude: parseFloat(props.position.lat.toFixed(6)),
        longitude: parseFloat(props.position.lng.toFixed(6)),
      };
    });
  }, []);

  useEffect(() => {
    if (venue !== undefined) {
      checkLocationInPolygon();
    }
  }, [props.position]);

  useEffect(() => {
    getVenue();
  }, [props.venueId]);

  const checkLocationInPolygon = () => {
    const contains: any = window.google.maps.geometry.poly.containsLocation(
      new window.google.maps.LatLng(props.position.lat, props.position.lng),
      new window.google.maps.Polygon({ paths: venue?.polygon_points })
    );
    if (!contains) {
      setIsSaveDisabled(true);
      setWarning({
        message: "Please select a location within the venue",
        alert: true,
      });
    } else {
      setIsSaveDisabled(false);
      setBuilding((prevValues: any) => {
        return {
          ...prevValues,
          latitude: parseFloat(props.position.lat.toFixed(6)),
          longitude: parseFloat(props.position.lng.toFixed(6)),
        };
      });
    }
  };

  const getVenue = () => {
    setLoading(true);
    ApiHandler({ apiName: "getVenue", body: {}, params: { venue_id: props.venueId } })
      .then((res: any) => {
        setLoading(false);
        setVenue(res.data);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const handleInputChange = (e: any) => {
    setBuilding((prevValues: any) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  const createBuilding = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("venue_id", props.venueId);
    formData.append("name", building.name);
    formData.append("description", building.description);
    formData.append("address", building.address);
    formData.append("latitude", building.latitude.toString());
    formData.append("longitude", building.longitude.toString());
    formData.append("total_floors", building.total_floors.toString());
    if (building.image !== undefined) {
      formData.append("image", building.image);
    }
    ApiHandler({ apiName: "createBuilding", body: formData, params: {} })
      .then((res: any) => {
        setLoading(false);
        setSuccess({
          message: "Building Added Successfully",
          alert: true,
        });
        setTimeout(() => {
          props.navigateTo("venueDetails");
        }, 1000);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  return (
    <div className="h-100 overflow-auto">
      <div className="app-card position-relative app-card-size">
        <div className="row-wise vertical-center">
          <div
            className="icon-background-active"
            onClick={() => {
              props.navigateTo("venueDetails");
            }}
          >
            <span className="material-icons-round icon-active">arrow_back</span>
          </div>

          <span className="app-card-action-heading ms-3">ADDING BUILDING</span>
        </div>
        <form className="overflow-auto col-wise h-100 pe-3 ps-1 pb-1 mb-1 pt-2">
          <LabelledInput
            title="Name*"
            name="name"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={building.name}
          />
          <LabelledInput
            title="Total Number of Floors*"
            name="total_floors"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            type="number"
            value={building.total_floors}
          />
          <div className="form-floating w-100 mb-2">
            <textarea
              rows={5}
              id="description"
              className="form-control textbox-box input-text h-auto"
              placeholder={"Description"}
              name="description"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={building.description}
            ></textarea>
            <label className="input-label" htmlFor="description">
              Description
            </label>
          </div>

          {/* Image Upload */}
          <Dropzone
            multiple={false}
            onDrop={(acceptedFiles: any) => {
              setBuilding((prevValues: any) => {
                return { ...prevValues, image: acceptedFiles[0] };
              });
              console.log(acceptedFiles[0]);
            }}
            accept={{ "image/jpg": [".jpg"], "image/jpeg": [".jpeg"], "image/png": [".png"] }}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="img-dropzone-set mb-2">
                <div
                  {...getRootProps({
                    className: `${building.image !== undefined ? "img-building-uploaded" : "img-building"}`,
                  })}
                >
                  <input {...getInputProps()} />

                  {building.image === undefined ? (
                    <div className="row-wise vertical-center ps-2 w-100 h-100">
                      <span className="material-icons-outlined">add_a_photo</span>
                      <span className="ps-2">Upload Building Image</span>
                    </div>
                  ) : (
                    <div className="row-wise vertical-center ps-2 w-100 h-100">
                      <span className="material-icons-outlined">photo_camera</span>
                      <span className="ps-2">{building.image["name"]}</span>
                    </div>
                  )}
                </div>
                <div></div>
              </section>
            )}
          </Dropzone>

          <div className="form-floating w-100 mb-2">
            <textarea
              rows={5}
              id="address"
              className="form-control textbox-box input-text h-auto"
              placeholder={"Address"}
              name="address"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={building.address}
            ></textarea>
            <label className="input-label" htmlFor="address">
              Address*
            </label>
          </div>

          <div className="row gx-2">
            <div className="col-6">
              <LabelledInput title="Latitude*" name="latitude" disabled value={building.latitude} />
            </div>
            <div className="col-6">
              <LabelledInput title="Longitude*" name="longitude" disabled value={building.longitude} />
            </div>
          </div>
        </form>

        <SecondaryButton
          className="mb-2 me-4 ms-1 mt-2"
          label={"Save and Add Floor"}
          clicked={() => {}}
          disable={isSaveDisabled}
        />

        <PrimaryButton
          className="mb-2 me-4 ms-1 mt-2"
          label="Create Building"
          clicked={() => {
            setConfirmAlert(true);
          }}
          disable={isSaveDisabled}
        />
      </div>

      <Spinner show={loading} />

      <ConfirmAlert
        message={`Do you confirm creation of the building?`}
        primaryText={"Yes"}
        secondaryText={"No"}
        secondaryAction={() => {
          setConfirmAlert(false);
        }}
        visible={confirmAlert}
        title={"Finish Building Creation"}
        primaryAction={() => {
          setConfirmAlert(false);
          createBuilding();
        }}
      />

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: "", alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: "", alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: "", alert: false });
        }}
        message={warning.message}
      />
    </div>
  );
};
