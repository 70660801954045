import AssetDotRMA from "../assets/assetDotRma.png";
import AssetDotSAS from "../assets/assetDotSas.png";
import AssetDotCloud from "../assets/assetDotCloud.png";
import AssetDotFtech from "../assets/assetDotFtech.png";
import AssetDotRMAProgress from "../assets/assetDotRmaProgress.png";
import WifiIcon from "../assets/wifi_icon.png";
import WifiIconCloud from "../assets/wifi_icon_cloud.png";
import OpenWifiIcon from "../assets/wifi_ow_icon.png";

{
  /** Interface definitions */
}
interface AssetStatus {
  key: string;
  value: string;
  displayText: string;
}

{
  /** Constant definitions */
}
export const UserRoles = {
  INSTALLER: "Installer",
  CPI: "CPI",
  ADMIN: "Master Admin",
};

export const CBSDStatuses = {
  CLOUD: { key: "CLOUD", value: "Cloud", displayText: "CLOUD" },
  SAS: { key: "SAS", value: "SAS", displayText: "SAS" },
  RMA_REQUESTED: { key: "RMA_REQUESTED", value: "RMA Requested", displayText: "RMA" },
  RMA_INPROGRESS: { key: "RMA_INPROGRESS", value: "RMA in progress", displayText: "RMA_PROGRESS" },
  FIELD_TECH: { key: "FIELD_TECH", value: "Field Tech", displayText: "FIELD_TECH" },
};

export const CBSDMountingTypes = {
  AERIAL: "Aerial",
  ROE: "ROE",
  SMB: "SMB",
};

export const CBSDAge = {
  ZeroFive: "0-5",
  SixTen: "6-10",
  ElevenTwenty: "11-20",
  GtTwenty: ">20",
};

export const assetStatusDot: any = {
  "Field Tech": AssetDotFtech,
  Cloud: AssetDotCloud,
  SAS: AssetDotSAS,
  OpenWifi: AssetDotSAS,
  "RMA Requested": AssetDotRMA,
  "RMA in progress": AssetDotRMAProgress,
};

export const wifiStatusIcon: any = {
  "Field Tech": WifiIcon,
  Cloud: WifiIconCloud,
  OpenWifi: OpenWifiIcon,
};

export const setLabelInput = (mountingType: any) => {
  if (mountingType === CBSDMountingTypes.SMB) return "Omni";
  else if ([CBSDMountingTypes.AERIAL, CBSDMountingTypes.ROE].includes(mountingType)) return "Directional";
  return undefined;
};
