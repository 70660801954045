import React from "react";
import "./LabelLessDropdown.css";

interface LabelLessDropdownProps {
  title: string;
  value?: string;
  options: any;
  onChange?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  name?: string;
}

const LabelLessDropdown: React.FC<LabelLessDropdownProps> = ({
  title,
  value = "",
  options = [],
  onChange = () => {},
  fullWidth = false,
  disabled = false,
  className,
  name,
}) => {
  const updateValue = (val: any) => {
    let e = { target: { name: "", value: "" } };
    e.target.name = name ?? "";
    e.target.value = val ?? "";
    onChange(e);
  };
  return (
    <div className={`dropdown ${className}`}>
      <button
        disabled={disabled}
        className={`${fullWidth ? "w-100" : ""}`}
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {value && value !== "" ? value : title}
      </button>

      <ul className={`dropdown-menu ${fullWidth ? "w-100" : ""}`}>
        {options.map((option: any) => {
          return (
            <li key={option}>
              <span
                onClick={() => {
                  updateValue(option);
                }}
                className="dropdown-item"
              >
                {option}
              </span>
            </li>
          );
        })}
      </ul>
    </div>

    // <select
    //   name={name}
    //   disabled={disabled}
    //   className={className + (fullWidth ? " w-100" : "")}
    //   value={value}
    //   onChange={updateValue}
    // >
    //   <option value={title} hidden>
    //     {title}
    //   </option>
    //   {options.map((option: any) => (
    //     <option key={option} value={option}>
    //       {option}
    //     </option>
    //   ))}
    // </select>
  );
};

export default LabelLessDropdown;
