import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ROLES from "../constants/roles";
import { ApiHandler } from "../services/apiHandler";
import ConfirmAlert from "./confirmAlert";
import ErrorAlert from "./ErrorAlert";
import LabelLessDropdown from "./LabelLessDropdown";
import LabelLessInput from "./LabelLessInput";
import Modal from "./Modal";
import PrimaryButton from "./PrimaryButton";
import SuccessAlert from "./SuccessAlert";
import "./UserActions.css";
import WarningAlert from "./WarningAlert";
import TECHNOLOGY_TYPE from "../constants/technologyType";

interface UserActionsProps {
  user: any;
  processing: any;
  refreshUsers: any;
}
const UserActions: React.FC<UserActionsProps> = ({ user, processing, refreshUsers }) => {
  let navigate = useNavigate();
  const [isEdit, edit] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [cpiId, setCpiId] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [techType, setTechType] = useState("");
  const [passwordType, setPasswordType] = useState("auto");
  const [passwordModalVisible, togglePasswordModalVisibility] = useState(false);
  const [deleteConfirmVisible, toggleDeleteConfirmVisibility] = useState(false);
  const [error, setError] = useState({
    message: "",
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: "",
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: "",
    alert: false,
  });

  useEffect(() => {
    if (isEdit) {
      setName(user.full_name);
      setEmail(user.email);
      setRole(user.role);
      setCpiId(user.cpi_id);
      setTechType(user.technology_type);
    }
  }, [isEdit]);

  const editConfirmed = () => {
    processing(true);
    const requestBody: any = {
      full_name: name,
      email: email,
      role: role,
      cpi_id: cpiId,
      technology_type: techType,
    };
    ApiHandler({ apiName: "updateUser", body: requestBody, params: { username: user.username } })
      .then((response: any) => {
        processing(false);
        edit(false);
        setSuccess({ message: response.data.message, alert: true });
        refreshUsers();
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const deleteUser = () => {
    toggleDeleteConfirmVisibility(false);
    processing(true);
    ApiHandler({ apiName: "deleteUser", body: {}, params: { username: user.username } })
      .then((response: any) => {
        processing(false);
        setSuccess({ message: response.data.message, alert: true });
        refreshUsers();
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const resetPasswordConfirmed = () => {
    if (passwordType === "auto") autoResetPassword();
    else manualResetPassword();
  };

  const autoResetPassword = () => {
    processing(true);
    ApiHandler({ apiName: "generatePassword", body: { email: user.email }, params: {} })
      .then((response: any) => {
        processing(false);
        togglePasswordModalVisibility(false);
        setSuccess({ message: `Email sent successfully to ${user.username}`, alert: true });
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const manualResetPassword = () => {
    if (newPassword.trim() === "") {
      setWarning({ message: "Password is Invalid", alert: true });
      return;
    }
    processing(true);
    const requestBody: any = {
      password: newPassword,
    };
    ApiHandler({ apiName: "updateUser", body: requestBody, params: { username: user.username } })
      .then((response: any) => {
        processing(false);
        togglePasswordModalVisibility(false);
        setSuccess({ message: response.data.message, alert: true });
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  return (
    <div>
      <div className="row vertical-center user-row">
        <div className="col-2">
          {isEdit ? (
            <LabelLessInput
              w100
              placeholder="Full Name"
              value={name}
              newValue={(value: any) => {
                setName(value);
              }}
            />
          ) : (
            <span className="user-text">{user.full_name}</span>
          )}
        </div>
        <div className="col-2">
          <span className="user-text">{user.username}</span>
        </div>
        <div className="col-2">
          {isEdit ? (
            <LabelLessInput
              w100
              placeholder="Email"
              value={email}
              newValue={(value: any) => {
                setEmail(value);
              }}
            />
          ) : (
            <span className="user-text">{user.email}</span>
          )}
        </div>
        <div className="col-2">
          {isEdit && localStorage.getItem("role") == "master admin" ? (
            <LabelLessDropdown
              title="technology_type"
              onChange={(e: any) => {
                e.target.name = TECHNOLOGY_TYPE.filter((tech) => tech.displayName === e.target.value)[0].dbName;
                setTechType(e.target.name);
              }}
              value={TECHNOLOGY_TYPE.filter((tech: any) => tech.dbName === techType)[0]?.displayName}
              options={TECHNOLOGY_TYPE.map((tech: any) => {
                return tech.displayName;
              })}
              fullWidth
            />
          ) : (
            <span className="user-text">{TECHNOLOGY_TYPE.filter((tech: any) => tech.dbName === user.technology_type)[0]?.displayName}</span>
          )}
        </div>
        <div className="col-2">
          {isEdit ? (
            <LabelLessDropdown
              title="Role"
              onChange={(e: any) => {
                setRole(e.target.value);
              }}
              value={role}
              options={ROLES}
              fullWidth
            />
          ) : (
            <span className="user-text">{user.role}</span>
          )}
        </div>

        <div className="col-1">
          {isEdit ? (
            <LabelLessInput
              w100
              placeholder="CPI ID"
              value={cpiId}
              newValue={(value: any) => {
                setCpiId(value);
              }}
            />
          ) : (
            <span className="user-text">{user.cpi_id}</span>
          )}
        </div>
        <div className="col-1 d-flex justify-content-center">
          {isEdit ? (
            <div className="row-wise w-100 d-flex align-items-center justify-content-around">
              <div
                className="edit-confirm-box mr-1"
                onClick={() => {
                  editConfirmed();
                }}
              >
                <span className="material-icons-round edit-confirm-icon font-20">done</span>
              </div>
              <span
                className="cancel-edit"
                onClick={() => {
                  edit(false);
                }}
              >
                Cancel
              </span>
            </div>
          ) : (
            <div className="dropstart">
              <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <span className="material-icons-round" style={{ color: "#8f9fac" }}>
                  menu_open
                </span>
              </a>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li
                  className="dropdown-item"
                  onClick={() => {
                    edit(true);
                  }}
                >
                  Edit
                </li>
                <li
                  className="dropdown-item"
                  onClick={() => {
                    toggleDeleteConfirmVisibility(true);
                  }}
                >
                  Delete
                </li>
                <li
                  className="dropdown-item"
                  onClick={() => {
                    togglePasswordModalVisibility(true);
                  }}
                >
                  Reset Password
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      <ConfirmAlert
        title="Confirm Delete"
        message="Are you sure you want to delete the user?"
        note={`Username: ${user.username}`}
        primaryText="Confirm"
        secondaryText="Cancel"
        secondaryAction={() => {
          toggleDeleteConfirmVisibility(false);
        }}
        primaryAction={deleteUser}
        visible={deleteConfirmVisible}
      />

      <Modal show={passwordModalVisible}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content app-card border-0 p-0">
            <div className="modal-header">
              <h5 className="modal-title">Reset user password</h5>
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  togglePasswordModalVisibility(!passwordModalVisible);
                }}
              ></button>
            </div>
            <div className="modal-body overflow-hidden col-wise">
              <span className="password-instructions mt-2 mb-2 w-100">
                Select an option and password reset instructions will be emailed to the user
              </span>

              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    className="option-label"
                    control={<Radio color="primary" />}
                    checked={passwordType === "auto"}
                    onClick={() => {
                      setPasswordType("auto");
                    }}
                    label="Auto generate random password"
                  />
                  <FormControlLabel
                    className="option-label"
                    control={<Radio color="primary" />}
                    checked={passwordType === "manual"}
                    onClick={() => {
                      setPasswordType("manual");
                    }}
                    label="Manual password"
                  />
                </RadioGroup>
              </FormControl>

              <LabelLessInput
                w100
                type={"password"}
                placeholder="New Password"
                disabled={passwordType === "auto"}
                value={newPassword}
                newValue={(value: any) => {
                  setNewPassword(value);
                }}
              />
            </div>

            <div className="modal-footer">
              <PrimaryButton label="Submit" disable={false} clicked={resetPasswordConfirmed} />
            </div>
          </div>
        </div>
      </Modal>

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: "", alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: "", alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: "", alert: false });
        }}
        message={warning.message}
      />
    </div>
  );
};

export default UserActions;
