import Federated from "../assets/federated.svg";
import Google from "../assets/google.svg";

const SasProviders = [
  {
    img: Federated,
    alt: "federated",
  },
  {
    img: Google,
    alt: "google",
  },
];

export default SasProviders;
