// AUTHOR: Rahul Choudhary | Whirldata Inc.
// DATE: 23 Aug 2022
// DESCRIPTION:
// -- Allows to show the active page being marked as active by TabNavItem and TabIcons
//    by equating tab marked as active (activeTab) and id of the tab.
// -- See TabNavItem and TabIcons for more details on setting a page as active.

import React from "react";

// Interface defines the properties to be used in the component when used in parent component.
interface TabContentProps {
  id: string;
  activeTab: string;
  children: any;
}

const TabContent: React.FC<TabContentProps> = ({ id, activeTab, children }) => {
  return activeTab === id ? <div key={id}>{children}</div> : null;
};

export default TabContent;
