import { Alert, Snackbar } from "@mui/material";
import React from "react";

interface ErrorAlertProps {
  show: boolean;
  message: string;
  onDismiss: any;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ show, message, onDismiss }) => {
  return (
    <Snackbar open={show} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={onDismiss}>
      <Alert onClose={onDismiss} severity="error" variant="filled" sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ErrorAlert;
