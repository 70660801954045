import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorAlert from "../components/ErrorAlert";
import Spinner from "../components/Spinner";
import { ApiHandler } from "../services/apiHandler";
import Logo from "../assets/logo.png";
import "./Login.css";
import SuccessAlert from "../components/SuccessAlert";

const SetPassword: React.FC = () => {
  let navigate = useNavigate(); // Hook for navigation used in loginClicked()
  const [searchParams] = useSearchParams();

  // State Hooks
  const [isPasswordVisible, togglePasswordType] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [error, setError] = useState({
    message: "",
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: "",
    alert: false,
  });

  useEffect(() => {
    if (!searchParams.get("token")) {
      navigate("/login", { replace: true });
    }
  }, []);

  // Methods

  const validateRequest = () => {
    if (password.trim() === "") {
      setError({ message: "Password cannot be empty.", alert: true });
      return false;
    }
    if (password !== rePassword) {
      setError({ message: "Passwords do not match.", alert: true });
      return false;
    }
    return true;
  };

  const passwordSubmissionConfirmed = (e: any) => {
    e.preventDefault();
    const isValid: boolean = validateRequest();
    if (!isValid) return;

    setLoading(true);
    let api: string = "";
    if (searchParams.get("forgot")) {
      api = "resetPassword";
    } else {
      api = "setPassword";
    }
    let urlToken: string = searchParams.get("token") || "";
    let urlUsername: string = searchParams.get("username") || "";

    ApiHandler({
      apiName: api,
      body: { password: password },
      params: {},
      queryParams: { token: urlToken, username: urlUsername },
    })
      .then((response: any) => {
        setLoading(false);
        setSuccess({ message: "Password set successfully", alert: true });
        setTimeout(() => {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        }, 2000);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };
  // View
  return (
    <div>
      {/* Background overlay image */}
      <div className="background" />

      {/* Login Card */}
      <div className="vertical-center h-100 w-100" style={{ position: "absolute", justifyContent: "end" }}>
        <div className="login-card center" style={{ marginRight: "7.3vw" }}>
          <img className="logo" src={Logo} alt="c3-spectra logo"></img>

          <span className="action-title mb-3" style={{ marginTop: "5.5vh" }}>
            Reset Password
          </span>

          <span className="action-instructions" style={{ marginBottom: "3.9vh" }}>
            Enter your new password below.
          </span>

          <form className="w-100" onSubmit={passwordSubmissionConfirmed}>
            <div className="form-floating w-100" style={{ marginBottom: "1.3vh" }}>
              <input
                className="form-control input-box input-text vertical-center"
                placeholder="New Password"
                id="passwordInput"
                onInput={(e: any) => {
                  setPassword(e.target.value);
                }}
                value={password}
                type={"password"}
              ></input>
              <label className="input-label vertical-center" htmlFor="passwordInput">
                New Password
              </label>
            </div>

            <div className="row gx-0 center input-with-icon w-100">
              <div className="col-10">
                <div className="form-floating">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    className="form-control input-password-box input-text border-0 vertical-center"
                    placeholder="Re-Enter Password"
                    id="rePasswordInput"
                    onInput={(e: any) => {
                      setRePassword(e.target.value);
                    }}
                    value={rePassword}
                  ></input>
                  <label className="input-label vertical-center" htmlFor="rePasswordInput">
                    Re-Enter Password
                  </label>
                </div>
              </div>

              <div
                className="col-2 center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  togglePasswordType(!isPasswordVisible);
                }}
              >
                <span className="material-icons-round">{isPasswordVisible ? "visibility" : "visibility_off"}</span>
              </div>
            </div>

            <button
              type="submit"
              className="w-100 mt-3 center primary-action-button"
              disabled={password === "" || rePassword === ""}
            >
              <span>Reset</span>
            </button>
          </form>

          <span
            className="interactible-text forgot-password-text mt-4"
            onClick={() => {
              navigate("/login", { replace: true });
            }}
          >
            Log In instead?
          </span>
        </div>
      </div>

      <Spinner show={loading} />
      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: "", alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: "", alert: false });
        }}
        message={success.message}
      />
    </div>
  );
};

export default SetPassword;
