import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useNavigate } from "react-router-dom";
import ErrorAlert from "../components/ErrorAlert";
import LabelLessInput from "../components/LabelLessInput";
import PrimaryButton from "../components/PrimaryButton";
import Spinner from "../components/Spinner";
import SuccessAlert from "../components/SuccessAlert";
import SasProviders from "../constants/SasProviders";
import { ApiHandler } from "../services/apiHandler";
import TECHNOLOGY_TYPE from "../constants/technologyType";
import imageCompression from "browser-image-compression";
import ProfileAvatar from "../assets/profile_avatar.svg";
import "./Profile.scss";
import LabelLessDropdown from "../components/LabelLessDropdown";
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";

const CertificateUploaded: React.FC<{ isCertificateUploaded: boolean }> = ({ isCertificateUploaded }) =>
  isCertificateUploaded ? <span>Certificate Uploaded</span> : <span>+ Upload CPI Certificate</span>;

const Profile: React.FC = () => {
  let navigate = useNavigate();
  const [selectedSasProvider, setSasProvider] = useState("");
  const [aerialVendorId, setAerialVendorId] = useState<number>(0);
  const [aerialVendorList, setAerialVendorList] = useState<{ id: number; vendor_name: string; vendor_model: string }[]>(
    []
  );
  const [roeVendorList, setRoeVendorList] = useState<{ id: number; vendor_name: string; vendor_model: string }[]>([]);
  const [smbVendorList, setSmbVendorList] = useState<{ id: number; vendor_name: string; vendor_model: string }[]>([]);
  const [roeVendorId, setRoeVendorId] = useState<number>(0);
  const [smbVendorId, setSmbVendorId] = useState<number>(0);
  const [wifiVendorId, setWifiVendorId] = useState<number>(0);
  const [wifiVendorList, setWifiVendorList] = useState<{ id: number; vendor_name: string; vendor_model: string }[]>([]);
  const [cpeWifiVendorId, setCpeWifiVendorId] = useState<number>(0);
  const [cpeCbsdVendorId, setCpeCbsdVendorId] = useState<number>(0);
  const [cpeWifiVendorList, setCpeWifiVendorList] = useState<
    { id: number; vendor_name: string; vendor_model: string }[]
  >([]);
  const [cpeCbsdVendorList, setCpeCbsdVendorList] = useState<
    { id: number; vendor_name: string; vendor_model: string }[]
  >([]);
  const [cpiName, setCpiName] = useState("");
  const [email, setEmail] = useState("");
  const [certPassword, setCertPassword] = useState("");
  const [cpiId, setCpiId] = useState("");
  const [certificateFile, setCertificateFile] = useState<File>();
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");
  const [isCertificateUploaded, setCertificateUploaded] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isInstaller, setIsInstaller] = useState(false);
  const [isSettingChange, allowSettingsSave] = useState(false);
  const [isProfileChange, allowProfileSave] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [isProfilePicHovering, setIsProfilePicHovering] = useState(false);
  let userRole = localStorage.getItem("role") || "";
  let technologyTypeName = localStorage.getItem("technology_type_name") || "";
  const [technologyTypeTitle, setTechnologyTypeTitle] = useState(technologyTypeName);
  const [selectedProviderType, setSelectedProviderType] = useState<"sas" | "afc">("sas");

  useEffect(() => {
    listVendors();
    if (localStorage.getItem("profilePic")) {
      setProfilePic(localStorage.getItem("profilePic") || ProfileAvatar);
    }
  }, []);

  const listVendors = () => {
    setLoading(true);
    ApiHandler({ apiName: "listVendors", params: {}, body: {} })
      .then((response: any) => {
        let vendor: any = response.data;
        setAerialVendorList(vendor.aerial);
        setRoeVendorList(vendor.roe);
        setSmbVendorList(vendor.smb);
        setWifiVendorList(vendor.wifi);
        setCpeWifiVendorList(vendor.cpe_wifi);
        setCpeCbsdVendorList(vendor.cpe_cbsd);
        getUser();
      })
      .catch((response: any) => {
        setLoading(false);
        if (response.doLogout === true) {
          setErrorMessage("Session Timed Out");
          setErrorAlert(true);
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setErrorMessage(response.data.message);
          setErrorAlert(true);
        }
      });
  };

  const setUsernameValue = () => {
    setUsername(localStorage.getItem("username") ? localStorage.getItem("username")! : "");
  };

  const setProfilePicture = (user: any) => {
    if (user.profile_picture) {
      localStorage.setItem("profilePic", user.profile_picture);
      setProfilePic(user.profile_picture || "");
    }
  };

  const getUser = () => {
    setIsInstaller(localStorage.getItem("role") === "installer");
    ApiHandler({ apiName: "getUser", params: { username: localStorage.getItem("username") }, body: {} })
      .then((response: any) => {
        let user: any = response.data;
        setUserId(user.user_id || "");
        setSasProvider(user.default_provider || "");
        setAerialVendorId(user.default_aerial_vendor_id || 0);
        setRoeVendorId(user.default_roe_vendor_id || 0);
        setSmbVendorId(user.default_smb_vendor_id || 0);
        setWifiVendorId(user.default_wifi_vendor_id || 0);
        setCpeWifiVendorId(user.default_cpe_wifi_vendor_id || 0);
        setCpeCbsdVendorId(user.default_cpe_cbsd_vendor_id || 0);
        setCpiName(user.cpi_name || "");
        setUsernameValue();
        setEmail(user.email || "");
        setCpiId(user.cpi_id || "");
        setCertificateUploaded(user.is_p12_uploaded || false);
        setCertificateFile(undefined);
        setCertPassword("");
        setProfilePicture(user);
        setTechnologyTypeTitle(user.technology_type);
        setSelectedProviderType(user.provider_type);
        setLoading(false);
        allowSettingsSave(false);

        localStorage.setItem("technology_type_name", user.technology_type);
        localStorage.setItem("provider_type", user.provider_type);
      })
      .catch((response: any) => {
        setLoading(false);
        if (response.doLogout === true) {
          setErrorMessage("Session Timed Out");
          setErrorAlert(true);
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setErrorMessage(response.data.message);
          setErrorAlert(true);
        }
      });
  };

  const uploadProfilePicture = async (file: File) => {
    setLoading(true);
    const options = {
      maxSizeMB: 1,
    };
    const compressedFile = await imageCompression(file, options);
    const imageDataUrl = await imageCompression.getDataUrlFromFile(compressedFile);
    let requestBody: any = new FormData();
    requestBody.append("file", imageDataUrl);
    requestBody.append("ext", file["name"].slice(file["name"].lastIndexOf(".") + 1));
    ApiHandler({ apiName: "uploadProfilePic", params: { username: username }, body: requestBody })
      .then((response: any) => {
        setLoading(false);
        setProfilePic(response.data.url);
        localStorage.setItem("profilePic", response.data.url);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setErrorMessage("Session Timed Out");
          setErrorAlert(true);
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setErrorMessage(error.data.message);
          setErrorAlert(true);
        }
      });
  };

  const saveProfileClicked = (e: any) => {
    e.preventDefault();
    setLoading(true);
    let requestBody: any = new FormData();
    if (cpiName !== "") requestBody.append("cpi_name", cpiName);
    if (email !== "") requestBody.append("email", email);
    if (cpiId !== "") requestBody.append("cpi_id", cpiId);
    if (certPassword !== "") requestBody.append("cpi_password", certPassword);
    if (certificateFile !== undefined) requestBody.append("p12_file", certificateFile);
    ApiHandler({
      apiName: "updateUserProfile",
      params: { username: localStorage.getItem("username") },
      body: requestBody,
    })
      .then((response: any) => {
        setLoading(false);
        getUser();
        setSuccessMessage(response.data.message);
        setSuccessAlert(true);
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(error.data.message);
        setErrorAlert(true);
      });
  };
  const saveSettingsClicked = (e: any) => {
    e.preventDefault();
    setLoading(true);
    console.log(technologyTypeTitle);
    let requestBody = {
      user_id: userId,
      technology_type: technologyTypeTitle,
      default_provider: selectedSasProvider,
      provider_type: selectedProviderType,
      default_aerial_vendor_id: parseInt(aerialVendorId.toString()),
      default_roe_vendor_id: parseInt(roeVendorId.toString()),
      default_smb_vendor_id: parseInt(smbVendorId.toString()),
      default_wifi_vendor_id: parseInt(wifiVendorId.toString()),
      default_cpe_wifi_vendor_id: parseInt(cpeWifiVendorId.toString()),
      default_cpe_cbsd_vendor_id: parseInt(cpeCbsdVendorId.toString()),
    };
    ApiHandler({
      apiName: "updateUserDefaults",
      params: { username: localStorage.getItem("username") },
      body: requestBody,
    })
      .then((response: any) => {
        setLoading(false);
        getUser();
        setSuccessMessage(response.data.message);
        setSuccessAlert(true);
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(error.data.message);
        setErrorAlert(true);
      });
  };

  const uploadCertificate = (file: any) => {
    setCertificateFile(file[0]);
  };

  return (
    <div className="backdrop col-wise overflow-auto">
      <span className="page-heading">Settings</span>

      <div className="row gx-0 overflow-auto p-2">
        {/* Settings Card */}
        <div className="col-6" style={{ paddingRight: "8px" }}>
          <div className="app-card h-100">
            <span className="app-card-heading">SYSTEM SETTINGS</span>

            <div className="row-wise w-100 justify-content-between">
              <div className="col-wise w-50">
                <span className="sub-heading">USER ID</span>
                <LabelLessInput
                  placeholder="Enter User ID"
                  value={userId}
                  newValue={(value: any) => {
                    setUserId(value);
                    allowSettingsSave(true);
                  }}
                />
              </div>
              <div className="col-wise align-self-end">
                <PrimaryButton
                  disable={!isSettingChange}
                  className="w-100"
                  label="Save System Settings"
                  clicked={saveSettingsClicked}
                />
              </div>
            </div>

            <span className="sub-heading mb-2">TECHNOLOGY TYPE</span>
            <LabelLessDropdown
              disabled={userRole !== "master admin"}
              name={"technology_type"}
              className="mb-2"
              fullWidth
              title={technologyTypeTitle}
              options={TECHNOLOGY_TYPE.map((tech: any) => {
                return tech.displayName;
              })}
              value={TECHNOLOGY_TYPE.filter((tech: any) => tech.dbName === technologyTypeTitle)[0]?.displayName}
              onChange={(e: any) => {
                e.target.value = TECHNOLOGY_TYPE.filter((tech) => tech.displayName === e.target.value)[0].dbName;
                setTechnologyTypeTitle(e.target.value);
                allowSettingsSave(true);
              }}
            />

            {technologyTypeTitle === "cbrs_5g" && (
              <div className="gap-2 d-flex flex-column">
                <span className="sub-heading mb-2">PROVIDER TYPE</span>
                <div className="d-flex flex-row align-items-center">
                  <input
                    className="provider-selection-input me-2"
                    type="checkbox"
                    id="sas-checkbox"
                    checked={selectedProviderType === "sas"}
                    onChange={() => {
                      setSelectedProviderType("sas");
                      allowSettingsSave(true);
                    }}
                  />
                  <label className="provider-selection-heading me-4" htmlFor="sas-checkbox">
                    SAS
                  </label>

                  <input
                    className="provider-selection-input me-2"
                    type="checkbox"
                    id="afc-checkbox"
                    checked={selectedProviderType === "afc"}
                    onChange={() => {
                      setSelectedProviderType("afc");
                      allowSettingsSave(true);
                    }}
                  />
                  <label className="provider-selection-heading" htmlFor="afc-checkbox">
                    AFC
                  </label>
                </div>
                <div className="row gx-0">
                  {technologyTypeTitle === "cbrs_5g" && selectedProviderType === "sas" ? (
                    SasProviders.map((provider: any) => {
                      return (
                        <div key={provider.alt} className="col">
                          <div
                            key={provider}
                            className={selectedSasProvider === provider.alt ? "sas-provider-active" : "sas-provider"}
                            onClick={() => {
                              setSasProvider(provider.alt);
                              allowSettingsSave(true);
                            }}
                          >
                            <img src={provider.img} alt={provider.alt}></img>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
            {technologyTypeTitle === "wifi_6e" ? (
              <div className="col-wise w-100">
                {/* CPE WIFI Vendor */}
                <span className="sub-heading">SELECT CPE VENDOR</span>

                <div className={`dropdown w-100`}>
                  <button className="w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {cpeWifiVendorList.filter((option: any) => option.id.toString() === cpeWifiVendorId.toString())
                      .length > 0
                      ? `${
                          cpeWifiVendorList.filter(
                            (option: any) => option.id.toString() === cpeWifiVendorId.toString()
                          )[0].vendor_name
                        }-${
                          cpeWifiVendorList.filter(
                            (option: any) => option.id.toString() === cpeWifiVendorId.toString()
                          )[0].vendor_model
                        }`
                      : "Select CPE Vendor"}
                  </button>

                  <ul className={`dropdown-menu w-100`}>
                    {cpeWifiVendorList.map((option: any) => {
                      return (
                        <li key={option.id}>
                          <span
                            onClick={() => {
                              setCpeWifiVendorId(option.id);
                              allowSettingsSave(true);
                            }}
                            className="dropdown-item"
                          >
                            {`${option.vendor_name}-${option.vendor_model}`}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                {/* WIFI Vendor */}
                <span className="sub-heading">SELECT WIFI VENDOR</span>

                <div className={`dropdown w-100`}>
                  <button className="w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {wifiVendorList.filter((option: any) => option.id.toString() === wifiVendorId.toString()).length > 0
                      ? `${
                          wifiVendorList.filter((option: any) => option.id.toString() === wifiVendorId.toString())[0]
                            .vendor_name
                        }-${
                          wifiVendorList.filter((option: any) => option.id.toString() === wifiVendorId.toString())[0]
                            .vendor_model
                        }`
                      : "Select WIFI Vendor"}
                  </button>

                  <ul className={`dropdown-menu w-100`}>
                    {wifiVendorList.map((option: any) => {
                      return (
                        <li key={option.id}>
                          <span
                            onClick={() => {
                              setWifiVendorId(option.id);
                              allowSettingsSave(true);
                            }}
                            className="dropdown-item"
                          >
                            {`${option.vendor_name}-${option.vendor_model}`}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) : (
              <div className="col-wise w-100">
                {/* CPE CBSD Vendor */}
                <span className="sub-heading">SELECT CPE VENDOR</span>

                <div className={`dropdown w-100`}>
                  <button className="w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {cpeCbsdVendorList.filter((option: any) => option.id.toString() === cpeCbsdVendorId.toString())
                      .length > 0
                      ? `${
                          cpeCbsdVendorList.filter(
                            (option: any) => option.id.toString() === cpeCbsdVendorId.toString()
                          )[0].vendor_name
                        }-${
                          cpeCbsdVendorList.filter(
                            (option: any) => option.id.toString() === cpeCbsdVendorId.toString()
                          )[0].vendor_model
                        }`
                      : "Select CPE Vendor"}
                  </button>

                  <ul className={`dropdown-menu w-100`}>
                    {cpeCbsdVendorList.map((option: any) => {
                      return (
                        <li key={option.id}>
                          <span
                            onClick={() => {
                              setCpeCbsdVendorId(option.id);
                              allowSettingsSave(true);
                            }}
                            className="dropdown-item"
                          >
                            {`${option.vendor_name}-${option.vendor_model}`}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                {/* Aerial Vendor */}
                <span className="sub-heading">SELECT AERIAL VENDOR</span>

                <div className={`dropdown w-100`}>
                  <button className="w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {aerialVendorList.filter((option: any) => option.id.toString() === aerialVendorId.toString())
                      .length > 0
                      ? `${
                          aerialVendorList.filter(
                            (option: any) => option.id.toString() === aerialVendorId.toString()
                          )[0].vendor_name
                        }-${
                          aerialVendorList.filter(
                            (option: any) => option.id.toString() === aerialVendorId.toString()
                          )[0].vendor_model
                        }`
                      : "Select Aerial Vendor"}
                  </button>

                  <ul className={`dropdown-menu w-100`}>
                    {aerialVendorList.map((option: any) => {
                      return (
                        <li key={option.id}>
                          <span
                            onClick={() => {
                              setAerialVendorId(option.id);
                              allowSettingsSave(true);
                            }}
                            className="dropdown-item"
                          >
                            {`${option.vendor_name}-${option.vendor_model}`}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <span className="sub-heading">SELECT ROE VENDOR</span>

                <div className={`dropdown w-100`}>
                  <button className="w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {roeVendorList.filter((option: any) => option.id.toString() === roeVendorId.toString()).length > 0
                      ? `${
                          roeVendorList.filter((option: any) => option.id.toString() === roeVendorId.toString())[0]
                            .vendor_name
                        }-${
                          roeVendorList.filter((option: any) => option.id.toString() === roeVendorId.toString())[0]
                            .vendor_model
                        }`
                      : "Select ROE Vendor"}
                  </button>

                  <ul className={`dropdown-menu w-100`}>
                    {roeVendorList.map((option: any) => {
                      return (
                        <li key={option.id}>
                          <span
                            onClick={() => {
                              setRoeVendorId(option.id);
                              allowSettingsSave(true);
                            }}
                            className="dropdown-item"
                          >
                            {`${option.vendor_name}-${option.vendor_model}`}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <span className="sub-heading">SELECT SMB VENDOR</span>

                <div className={`dropdown w-100`}>
                  <button className="w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {smbVendorList.filter((option: any) => option.id.toString() === smbVendorId.toString()).length > 0
                      ? `${
                          smbVendorList.filter((option: any) => option.id.toString() === smbVendorId.toString())[0]
                            .vendor_name
                        }-${
                          smbVendorList.filter((option: any) => option.id.toString() === smbVendorId.toString())[0]
                            .vendor_model
                        }`
                      : "Select SMB Vendor"}
                  </button>

                  <ul className={`dropdown-menu w-100`}>
                    {smbVendorList.map((option: any) => {
                      return (
                        <li key={option.id}>
                          <span
                            onClick={() => {
                              setSmbVendorId(option.id);
                              allowSettingsSave(true);
                            }}
                            className="dropdown-item"
                          >
                            {`${option.vendor_name}-${option.vendor_model}`}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Profile Card */}
        <div className="col-6" style={{ paddingLeft: "8px" }}>
          <div className="app-card h-100">
            <span className="app-card-heading">PROFILE INFO</span>
            {/* <div className="avatar mt-4">
              <span className="material-icons-round icon-active" style={{ fontSize: "80px" }}>
                account_circle
              </span>
            </div> */}

            <div
              className="profile-pic mt-4"
              onMouseOver={() => {
                setIsProfilePicHovering(true);
              }}
              onMouseOut={() => {
                setIsProfilePicHovering(false);
              }}
            >
              <label htmlFor="profile-picture"></label>
              <input
                id="profile-picture"
                type={"file"}
                accept={"image/png,image/jpeg,image/jpg"}
                onChange={(e: any) => {
                  uploadProfilePicture(e.target.files[0]);
                }}
              ></input>
              {isProfilePicHovering ? (
                <div className="upload-overlay">
                  <div className="upload-button">
                    <span className="material-icons-outlined">photo_camera</span>
                    <p>Upload</p>
                  </div>
                </div>
              ) : null}
              {profilePic.length ? (
                <img src={profilePic} style={{ borderRadius: "50%" }} alt="User Profile image" />
              ) : (
                <img src={ProfileAvatar} alt="Profile Avatar image" />
              )}
            </div>

            <div className="row gx-0">
              <div className="col-6 col-wise">
                <span className="sub-heading">CPI NAME</span>
                <LabelLessInput
                  disabled={isInstaller}
                  placeholder="Enter CPI Name"
                  value={cpiName}
                  newValue={(value: any) => {
                    setCpiName(value);
                    allowProfileSave(true);
                  }}
                />
                <span className="sub-heading">EMAIL</span>
                <LabelLessInput
                  placeholder="Enter Email ID"
                  value={email}
                  newValue={(value: any) => {
                    setEmail(value);
                    allowProfileSave(true);
                  }}
                  type={"email"}
                />
                <span className="sub-heading">CPI CERTIFICATE PASSWORD</span>
                <LabelLessInput
                  disabled={isInstaller}
                  placeholder="Enter CPI Certificate Password"
                  value={certPassword}
                  newValue={(value: any) => {
                    setCertPassword(value);
                    allowProfileSave(true);
                  }}
                  type="password"
                />
              </div>

              <div className="col-6 col-wise">
                <span className="sub-heading">USERNAME</span>
                <LabelLessInput placeholder="Enter Username" value={username} disabled={true} />
                <span className="sub-heading">CPI ID</span>
                <LabelLessInput
                  disabled={isInstaller}
                  placeholder="Enter CPI ID"
                  value={cpiId}
                  newValue={(value: any) => {
                    setCpiId(value);
                    allowProfileSave(true);
                  }}
                />
                <span className="sub-heading">CPI CERTIFICATE</span>

                <Dropzone
                  disabled={isInstaller}
                  onDrop={(file: any) => {
                    uploadCertificate(file);
                    allowProfileSave(true);
                  }}
                  accept={{ "application/x-pkcs12": [".p12"] }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone-set">
                      <div
                        {...getRootProps({
                          className: certificateFile ? "dropzone-uploaded" : "dropzone",
                        })}
                      >
                        <input {...getInputProps()} disabled={isInstaller} />

                        {certificateFile ? (
                          <span>{"Ready to upload!"}</span>
                        ) : (
                          <CertificateUploaded isCertificateUploaded={isCertificateUploaded} />
                        )}
                      </div>
                      <div></div>
                    </section>
                  )}
                </Dropzone>

                {/* <input type={"file"} accept=".p12,application/x-pkcs12" /> */}

                <div className="w-100 justify-content-end d-flex">
                  <PrimaryButton
                    disable={!isProfileChange}
                    className="mt-4 me-2 w-50"
                    label="Save Profile Info"
                    clicked={saveProfileClicked}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spinner show={loading} />
      <ErrorAlert
        show={errorAlert}
        onDismiss={() => {
          setErrorAlert(false);
        }}
        message={errorMessage}
      />
      <SuccessAlert
        show={successAlert}
        onDismiss={() => {
          setSuccessAlert(false);
        }}
        message={successMessage}
      />
    </div>
  );
};

export default Profile;
