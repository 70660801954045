// AUTHOR: Rahul Choudhary | Whirldata Inc.
// DATE: 23 Aug 2022
// DESCRIPTION:
// -- Reusable component for navigation icons in the header
// -- Allows to have multiple icon based navigation buttons.
// -- Defines the style of the icon button and sets the corresponding
//    page as active on click.
// -- See TabContent for more details on active page visibility.

import React from "react";

// Interface defines the properties to be used in the component when used in parent component.
interface TabIconsProps {
  id: string;
  icon: string;
  activeTab: string;
  setActiveTab: Function;
  show?: boolean;
}

const TabIcons: React.FC<TabIconsProps> = ({ id, icon, activeTab, setActiveTab, show = true }) => {
  // Sets the clicked tab as active tab.
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <div
      className={`${show ? "" : "d-none"} ${activeTab === id ? "icon-background-active" : "icon-background"}`}
      style={{ marginRight: "16px" }}
      onClick={handleClick}
    >
      <span className={"material-icons-round" + (activeTab === id ? " icon-active" : " icon")}>{icon}</span>
    </div>
  );
};

export default TabIcons;
