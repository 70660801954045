import React from "react";

interface ToggleMapTypeProps {
  change: any;
}
const ToggleMapType: React.FC<ToggleMapTypeProps> = ({ change }) => {
  return (
    <div title="Switch between Satellite and RoadMap view" className="map-icon-background mb-3" onClick={change}>
      <span className="material-icons-round map-icon">layers</span>
    </div>
  );
};

export default ToggleMapType;
