// AUTHOR: Rahul Choudhary | Whirldata Inc.
// DATE: 23 Aug 2022
// DESCRIPTION:
// -- Header of the whole web-app.
// -- Contains tabs and icons to navigate amongst pages.
// -- logout method (called on icon click) replaces /login page with this page
//    thus removing it from navigation stack and clears the <cookies/localstorage>.

// -- Components:
//     -- TabNavItem: defines the text based navigation tabs and decides
//         which page to show based on its active and inactive state.
//         A tab turns active on click and relate page shows below the header.
//     -- TabContent: defines the content/page to show for each tab
//         based on its active/inactive state.
//     -- TabIcons: defines the navigation icons and mimics TabNavItem
//         in terms of behaviour. Difference being this component takes in icons as view
//         as opposed to text based items.

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/logo_long.png";
import ErrorAlert from "../components/ErrorAlert";
import TabContent from "../components/TabContent";
import TabIcons from "../components/TabIcons";
import TabNavItem from "../components/TabNavItem";
import { AssetObj } from "../interfaces/AssetProps";
import apiConfig from "../services/apiConfig.json";
import Analytics from "./Analytics";
import "./Home.css";
import NetworkActivity from "./NetworkActivity";
import Outdoor from "./Outdoor";
import Profile from "./Profile";
import Settings from "./Settings";

const Home: React.FC = () => {
  const navigate = useNavigate(); // Hook for navigation used in loginClicked()
  const client = axios.create({
    baseURL: `${window.location.protocol}//${window.location.hostname}${
      window.location.hostname === "localhost" ? ":5000" : "/api"
    }`,
  });

  // State Hooks
  const [activeTab, setActiveTab] = useState("outdoor");
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isInstaller, setIsInstaller] = useState(false);
  const [isCPI, setIsCPI] = useState(false);
  const [assetToFocus, setAssetToFocus] = useState<AssetObj | undefined>(undefined); // Asset to focus on map if redirected from NetworkActivity page.

  useEffect(() => {
    setIsInstaller(localStorage.getItem("role") === "installer");
    setIsCPI(localStorage.getItem("role") === "cpi");
  }, []);

  useEffect(() => {
    if (assetToFocus) {
      setActiveTab("outdoor");
    }
  }, [assetToFocus]);

  useEffect(() => {
    if (activeTab !== "outdoor") {
      setAssetToFocus(undefined); // Clear assetToFocus if user navigates away from outdoor page.
    }
  }, [activeTab]);

  // Methods
  const logout = () => {
    let accessToken = localStorage.getItem("access_token");
    let headers: any = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    client
      .post(apiConfig["api"]["logout"]["url"], {}, { headers: headers })
      .then((response: any) => {
        localStorage.clear();
        navigate("/login", { replace: true });
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login", { replace: true });
        } else {
          setErrorMessage(error.response.data.message);
          setErrorAlert(true);
        }
      });
  };

  // View
  return (
    <div>
      <div className="header">
        {/* Logo of C3 Spectra : takes to outdoor page on clicked */}
        <div className="vertical-center">
          <img
            className="header-logo"
            src={Logo}
            onClick={() => {
              setActiveTab("outdoor");
            }}
            alt="c3-spectra logo"
          ></img>
        </div>

        {/* Navigation Tabs */}
        <ul className="nav">
          <TabNavItem title="Map" id="outdoor" activeTab={activeTab} setActiveTab={setActiveTab} />
          <TabNavItem
            show={!isInstaller}
            title="Network Activity"
            id="netactivity"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <TabNavItem
            show={!isInstaller}
            title="Analytics"
            id="analytics"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </ul>

        {/* Navigation Icons */}
        <div className="row-wise vertical-center">
          <TabIcons
            show={!isInstaller && !isCPI}
            icon="settings"
            id="settings"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <TabIcons icon="person" id="profile" activeTab={activeTab} setActiveTab={setActiveTab} />

          {/* Logout */}
          <div className="icon-background" onClick={logout}>
            <span className="material-icons-round icon font-20">logout</span>
          </div>
        </div>
      </div>

      {/* Content of the Navigation Tabs and Navigation Icons */}
      <div>
        <TabContent id="outdoor" activeTab={activeTab}>
          <Outdoor
            preSelectedAsset={assetToFocus}
            goToProfile={() => {
              setActiveTab("profile");
            }}
          />
        </TabContent>
        <TabContent id="netactivity" activeTab={activeTab}>
          <NetworkActivity
            takeToMap={(asset: AssetObj) => {
              setAssetToFocus(asset);
            }}
          />
        </TabContent>
        <TabContent id="analytics" activeTab={activeTab}>
          <Analytics />
        </TabContent>
        <TabContent id="settings" activeTab={activeTab}>
          <Settings />
        </TabContent>
        <TabContent id="profile" activeTab={activeTab}>
          <Profile />
        </TabContent>
      </div>

      <ErrorAlert
        show={errorAlert}
        onDismiss={() => {
          setErrorAlert(false);
        }}
        message={errorMessage}
      />
    </div>
  );
};

export default Home;
