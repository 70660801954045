// Author: Rahul Choudhary
// Date: 2022-11-11
// Description:
//     -- Gets the asset details from the parent component and displays it in a modal.
//     -- Uses google maps API to display the location of the asset on a map

import { GoogleMap } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import AssetProps, { AssetObj, RMAHistoryObj, SectorObj, VendorObj } from "../interfaces/AssetProps";
import Modal from "./Modal";
import PrimaryButton from "./PrimaryButton";
import AerialImage from "../assets/aerial.svg";
import RoeImage from "../assets/roe.svg";
import SmbImage from "../assets/smb.png";
import Spinner from "./Spinner";
import { saveAs } from "file-saver";
import { ApiHandler } from "../services/apiHandler";
import { useNavigate } from "react-router-dom";
import ErrorAlert from "./ErrorAlert";
import moment from "moment";
import ZoomPlus from "./ZoomPlus";
import ZoomMinus from "./ZoomMinus";
import "./NetworkActivityModal.css";
import ToggleMapType from "./ToggleMapType";

interface NetworkActivityModalProps {
  show: boolean;
  close: any;
  assetDetails: AssetProps | undefined;
  takeToMap: any; // Function to take the user to the map page with the asset tracking id
}

const NetworkActivityModal: React.FC<NetworkActivityModalProps> = ({ show, close, assetDetails, takeToMap }) => {
  {
    /** places librabry is not needed for this component,
        but first time maps api loaded in outdoor map component with this library and 
        loader must not be called again with different options. 
    */
  }
  let navigate = useNavigate();
  const [map, setMap] = useState<google.maps.Map>();
  const [selectedSectorIndex, setSectorindex] = useState(0);
  const [asset, setAsset] = useState<AssetObj>();
  const [sectors, setSectors] = useState<SectorObj[]>();
  const [vendor, setVendor] = useState<VendorObj>();
  const [rmaHistory, setRmaHistory] = useState<RMAHistoryObj[] | undefined>([]);
  const [showRMAHistory, setShowRMAHistory] = useState(false);
  const [rotate, setRotate] = useState(""); // css transform property to rotate the image. Angle is set on assetDetails change.
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [siteImages, setSiteImages] = useState<string[]>([]); // list of paths of site images
  const [viewImage, setViewImage] = useState({
    image: "",
    fileName: "",
    popup: false,
  }); // url of the image to be displayed in the modal
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: "",
    alert: false,
  });

  const mountingTypeImage: any = {
    Aerial: AerialImage,
    ROE: RoeImage,
    SMB: SmbImage,
    "": "",
  };

  const mapStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  useEffect(() => {
    setAsset(assetDetails?.asset);
    setSectors(assetDetails?.sectors);
    setVendor(assetDetails?.vendor);
    setRmaHistory(assetDetails?.rma_history);
  }, [assetDetails]);

  useEffect(() => {
    // Set the css transform rotate property to rotate the image
    let angle: string = "0";
    if (assetDetails?.asset.mounting_type === "Aerial") {
      let adjustedAngle: number = parseInt(assetDetails?.sectors[0]?.azimuth?.toString() || "0") - 225; // 225 is the angle added to first sector's azimuth while uploading the asset.
      if (adjustedAngle < 0) adjustedAngle = 360 + adjustedAngle; // If the adjusted angle is negative, add 360 to it to get the correct angle for rotation.
      angle = adjustedAngle.toString();
    } else if (assetDetails?.asset.mounting_type === "ROE") {
      angle = assetDetails?.sectors[0]?.azimuth?.toString() || "0"; // For ROE, the angle is the same as the azimuth.
    }
    setRotate(`rotate(${angle}deg)`);

    // Set the map center to the location of the asset
    setMapCenter({ lat: assetDetails?.asset.latitude || 0, lng: assetDetails?.asset.longitude || 0 });

    // Set the list of site images
    let siteImg: string[] = [];
    if (assetDetails?.asset.site_image_1) siteImg.push(assetDetails?.asset.site_image_1);
    if (assetDetails?.asset.site_image_2) siteImg.push(assetDetails?.asset.site_image_2);
    if (assetDetails?.asset.site_image_3) siteImg.push(assetDetails?.asset.site_image_3);
    if (assetDetails?.asset.site_image_4) siteImg.push(assetDetails?.asset.site_image_4);
    setSiteImages(siteImg);
  }, [assetDetails]);

  const getImage = (path: string) => {
    setLoading(true);
    ApiHandler({ apiName: "download", body: { path: path }, params: {} })
      .then((response: any) => {
        setViewImage({ image: response.data.file, fileName: path.slice(path.lastIndexOf("/") + 1), popup: true });
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const downloadImage = () => {
    try {
      saveAs(viewImage.image, `${assetDetails?.asset.tracking_id}_${viewImage.fileName}`);
    } catch (error: any) {
      console.info(error);
    }
  };

  const onMapLoad = useCallback(function callback(map: any) {
    setMap(map);
  }, []);

  const onMapUnmount = useCallback(function callback(map: any) {
    setMap(undefined);
  }, []);

  return (
    <div>
      <Modal show={show} isNetworkActivity>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content app-card border-0 p-0">
            <div className="modal-header">
              <h5 className="modal-title">{"Asset Details"}</h5>
              {(() => {
                if (rmaHistory?.length) {
                  return (
                    <div
                      className="rma-history-button"
                      onClick={() => {
                        setShowRMAHistory(true);
                      }}
                    >
                      <span className="rma-history-label">RMA History</span>
                    </div>
                  );
                } else {
                  return "";
                }
              })()}
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  close();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row g-0">
                {/* Sector Details */}
                <div className={`col-8 ${asset?.mounting_type !== "SMB" ? "mt-3" : ""}`}>
                  <div className="col-wise">
                    <div className="row-wise">
                      {asset?.mounting_type !== "SMB" &&
                        sectors?.map((sector: any, index: any) => {
                          return (
                            <div
                              key={sector.sector_id}
                              onClick={() => {
                                setSectorindex(index);
                              }}
                              className={`w-100 ${index === selectedSectorIndex ? "sector-active" : "sector-tab"} ${
                                index !== sectors.length - 1 ? "sector-border" : "" // To remove the border from the last sector
                              }`}
                            >
                              SECTOR {sector.sector_id}
                            </div>
                          );
                        })}
                    </div>

                    <div className="net-act-details-box mt-2">
                      <div className="row">
                        <div className="col-6 col-wise">
                          <span className="net-act-bold">
                            Tracking ID:<span className="net-act-label ms-2">{asset?.tracking_id}</span>
                          </span>
                          <span className="net-act-bold mt-2">
                            Site ID:<span className="net-act-label ms-2">{asset?.site_id}</span>
                          </span>
                          <div className="row-wise">
                            {siteImages.map((img, index) => {
                              return (
                                <span
                                  key={img}
                                  style={{ color: "0059b2", cursor: "pointer" }}
                                  className="material-icons me-2 font-14"
                                  onClick={() => {
                                    getImage(img);
                                  }}
                                >
                                  image
                                </span>
                              );
                            })}
                          </div>
                        </div>
                        <div className="col-6 col-wise">
                          <span className="net-act-bold">
                            Status:
                            <span className="net-act-label ms-2">
                              {sectors ? sectors[selectedSectorIndex].status : ""}
                            </span>
                          </span>
                        </div>
                      </div>

                      <hr></hr>

                      <label className="net-act-title mb-3">Installation Parameters</label>

                      <div className="row">
                        <div className="col-6 col-wise">
                          <span className="net-act-bold">
                            Latitude:<span className="net-act-label ms-2">{asset?.latitude}°</span>
                          </span>
                          <span className="net-act-bold mt-2">
                            Longitude:<span className="net-act-label ms-2">{asset?.longitude}°</span>
                          </span>
                          <span className="net-act-bold mt-2">
                            {"Height (m):"}
                            <span className="net-act-label ms-2">
                              {sectors ? sectors[selectedSectorIndex].height : "0"}
                            </span>
                            {(() => {
                              if (sectors && sectors[selectedSectorIndex].height_image) {
                                return (
                                  <span
                                    style={{ color: "0059b2", cursor: "pointer" }}
                                    className="material-icons ms-2 font-14"
                                    onClick={() => {
                                      getImage(sectors ? sectors[selectedSectorIndex].height_image || "" : "");
                                    }}
                                  >
                                    image
                                  </span>
                                );
                              } else {
                                return "";
                              }
                            })()}
                          </span>
                          <span className="net-act-bold mt-2">
                            Height Type:
                            <span className="net-act-label ms-2">
                              {sectors ? sectors[selectedSectorIndex].height_type : ""}
                            </span>
                          </span>
                          <span className="net-act-bold mt-2">
                            {"Horizontal Accuracy (m):"}
                            <span className="net-act-label ms-2">
                              {sectors ? sectors[selectedSectorIndex].horizontal_accuracy : ""}
                            </span>
                          </span>
                          <span className="net-act-bold mt-2">
                            {"Vertical Accuracy (m):"}
                            <span className="net-act-label ms-2">
                              {sectors ? sectors[selectedSectorIndex].vertical_accuracy : ""}
                            </span>
                          </span>
                          <span className="net-act-bold mt-2">
                            Indoor Deployment:
                            <span className="net-act-label ms-2">
                              {sectors && sectors[selectedSectorIndex].deployment_type === "indoor" ? "Yes" : "No"}
                            </span>
                          </span>
                        </div>
                        <div className="col-6 col-wise">
                          <span className="net-act-bold">
                            {"HAAT (m):"}
                            <span className="net-act-label ms-2">
                              {sectors ? sectors[selectedSectorIndex].haat : ""}
                            </span>
                          </span>
                          <span className="net-act-bold mt-2">
                            {"Antenna Azimuth (Degrees)"}
                            <span className="net-act-label ms-2">
                              {sectors ? sectors[selectedSectorIndex].azimuth : ""}
                            </span>{" "}
                            {(() => {
                              if (sectors && sectors[selectedSectorIndex].azimuth_image) {
                                return (
                                  <span
                                    style={{ color: "0059b2", cursor: "pointer" }}
                                    className="material-icons ms-2 font-14"
                                    onClick={() => {
                                      getImage(sectors ? sectors[selectedSectorIndex].azimuth_image || "" : "");
                                    }}
                                  >
                                    image
                                  </span>
                                );
                              } else {
                                return "";
                              }
                            })()}
                          </span>
                          <span className="net-act-bold mt-2">
                            {"Antenna Downtilt (Degrees):"}
                            <span className="net-act-label ms-2">
                              {sectors ? sectors[selectedSectorIndex].downtilt : ""}
                            </span>{" "}
                            {(() => {
                              if (sectors && sectors[selectedSectorIndex].downtilt_image) {
                                return (
                                  <span
                                    style={{ color: "0059b2", cursor: "pointer" }}
                                    className="material-icons ms-2 font-14"
                                    onClick={() => {
                                      getImage(sectors ? sectors[selectedSectorIndex].downtilt_image || "" : "");
                                    }}
                                  >
                                    image
                                  </span>
                                );
                              } else {
                                return "";
                              }
                            })()}
                          </span>
                          <span className="net-act-bold mt-2">
                            {"Antenna Gain (dBi):"}
                            <span className="net-act-label ms-2">{vendor?.antenna_gain}</span>
                          </span>
                          <span className="net-act-bold mt-2">
                            {"Eirp Capability (dBm):"}
                            <span className="net-act-label ms-2">{vendor?.eirp_capability}</span>
                          </span>
                          <span className="net-act-bold mt-2">
                            {"Antenna Beamwidth (Degrees):"}
                            <span className="net-act-label ms-2">{vendor?.antenna_beamwidth}</span>
                          </span>
                          <span className="net-act-bold mt-2">
                            Antenna Model:
                            <span className="net-act-label ms-2">{vendor?.antenna_model}</span>
                          </span>
                        </div>
                      </div>

                      <hr></hr>

                      <label className="net-act-title mb-3">CBSD Info</label>

                      <div className="row">
                        <div className="col-6 col-wise">
                          <span className="net-act-bold">
                            FCC ID:<span className="net-act-label ms-2">{vendor?.fcc_id}</span>
                          </span>
                          <span className="net-act-bold mt-2">
                            Serial ID:<span className="net-act-label ms-2">{asset?.serial_number}</span>
                          </span>
                          <span className="net-act-bold mt-2">
                            Vendor Model:
                            <span className="net-act-label ms-2">{`${vendor?.vendor_name}-${vendor?.vendor_model}`}</span>
                          </span>
                        </div>
                        <div className="col-6 col-wise">
                          <span className="net-act-bold">
                            Hardware Version:
                            <span className="net-act-label ms-2">{vendor?.hardware_version}</span>
                          </span>
                          <span className="net-act-bold mt-2">
                            Firmware Version:
                            <span className="net-act-label ms-2">{vendor?.firmware_version}</span>
                          </span>
                          <span className="net-act-bold mt-2">
                            Software Version:
                            <span className="net-act-label ms-2">{vendor?.software_version}</span>
                          </span>
                        </div>
                      </div>

                      <hr></hr>

                      <label className="net-act-title mb-3">Optional</label>

                      <div className="row">
                        <div className="col-6 col-wise">
                          <span className="net-act-bold">
                            Call Sign:
                            <span className="net-act-label ms-2">
                              {sectors ? sectors[selectedSectorIndex].call_sign : ""}
                            </span>
                          </span>
                          <span className="net-act-bold mt-2">
                            Grouping Param:
                            <span className="net-act-label ms-2">
                              {sectors ? sectors[selectedSectorIndex].grouping_params : ""}
                            </span>
                          </span>
                        </div>
                        <div className="col-6 col-wise">
                          <span className="net-act-bold">
                            Category:
                            <span className="net-act-label ms-2">{vendor?.cbsd_category}</span>
                          </span>
                          <span className="net-act-bold mt-2">
                            Air Interface:
                            <span className="net-act-label ms-2">{vendor?.radio_technology}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* google maps to show the location and azimuth */}
                <div className="col-4 col-wise ps-3">
                  <PrimaryButton
                    className="mb-2"
                    clicked={() => {
                      takeToMap(asset);
                    }}
                    label="Show on Map"
                  />

                  <GoogleMap
                    onLoad={onMapLoad}
                    onUnmount={onMapUnmount}
                    mapContainerStyle={mapStyle}
                    center={mapCenter}
                    options={{
                      draggable: false,
                      minZoom: 15,
                      disableDefaultUI: false,
                      styles: [
                        {
                          featureType: "poi",
                          stylers: [{ visibility: "off" }],
                        },
                      ],
                      keyboardShortcuts: false,
                      streetViewControl: false,
                      fullscreenControl: false,
                      mapTypeControl: false,
                      zoomControl: false,
                      clickableIcons: false,
                      draggableCursor: "not-allowed",
                      tilt: 0,
                    }}
                    zoom={22}
                    mapTypeId={google.maps.MapTypeId.HYBRID}
                  >
                    <img
                      className="position-absolute"
                      style={{ transform: rotate }}
                      src={mountingTypeImage[asset?.mounting_type || ""]}
                    ></img>
                    <div className={"zoom-buttons "}>
                      <ZoomPlus
                        map={map}
                        clicked={(currentZoom: number) => {
                          map?.setZoom(currentZoom + 1);
                        }}
                      />
                      <ZoomMinus
                        map={map}
                        clicked={(currentZoom: number) => {
                          map?.setZoom(currentZoom - 1);
                        }}
                      />
                    </div>
                    <div className={"map-type-button"}>
                      <ToggleMapType
                        change={() => {
                          map?.getMapTypeId() === google.maps.MapTypeId.ROADMAP
                            ? map?.setMapTypeId(google.maps.MapTypeId.HYBRID)
                            : map?.setMapTypeId(google.maps.MapTypeId.ROADMAP);
                        }}
                      />
                    </div>
                  </GoogleMap>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={viewImage.popup}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content app-card border-0 p-0">
            <div className="modal-header">
              <h5 className="modal-title">{"Asset Image"}</h5>
              <div className="row-wise download-box ms-2" onClick={downloadImage}>
                <span className="material-icons-round me-1 download-icon">file_download</span>
                <span className="download-label">DOWNLOAD</span>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  setViewImage({ image: "", fileName: "", popup: false });
                }}
              ></button>
            </div>
            <div className="modal-body p-0 overflow-hidden">
              <img
                loading="eager"
                onLoad={() => {
                  setLoading(false);
                }}
                className="w-100"
                src={viewImage.image}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={showRMAHistory}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content app-card border-0 p-0">
            <div className="modal-header">
              <h5 className="modal-title">{"RMA History"}</h5>
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  setShowRMAHistory(false);
                }}
              ></button>
            </div>
            <div className="modal-body overflow-auto">
              <div className="row g-0 table-header-card vertical-center font-14">
                <span className="col ms-3">Old Serial Number</span>
                <span className="col">Replaced On</span>
              </div>
              {(() => {
                if (rmaHistory) {
                  return rmaHistory.map((rma: any) => {
                    return (
                      <div key={rma.serial_number} className="row vertical-center g-0 ms-3 mt-3">
                        <span className="col">{rma.serial_number}</span>
                        <span className="col">{moment.utc(rma.timestamp).format("MMM Do YYYY h:mm:ss")}</span>
                      </div>
                    );
                  });
                } else {
                  return "";
                }
              })()}
            </div>
          </div>
        </div>
      </Modal>

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: "", alert: false });
        }}
        message={error.message}
      />

      <Spinner show={loading} />
    </div>
  );
};

export default NetworkActivityModal;
