import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AssetObj, SectorObj, VendorObj } from "../interfaces/AssetProps";
import { ApiHandler } from "../services/apiHandler";
import AccordionInput from "./AccordionInput";
import ErrorAlert from "./ErrorAlert";
import Spinner from "./Spinner";

interface CbsdAccordionProps {
  assetDetails: AssetObj | undefined;
  sectorDetails: SectorObj | undefined;
  vendorDetails: VendorObj | undefined;
  updateDetails: any;
}
const CbsdAccordion: React.FC<CbsdAccordionProps> = ({ assetDetails, sectorDetails, vendorDetails, updateDetails }) => {
  let navigate = useNavigate();
  const [vendors, setVendors] = useState<{ id: string; vendor_name: string; vendor_model: string }[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: "",
    alert: false,
  });

  useEffect(() => {
    listVendors(assetDetails?.mounting_type?.toLowerCase() || "");
  }, [assetDetails]);

  const listVendors = (mountingType: string) => {
    ApiHandler({ apiName: "listVendors", body: {}, params: {} })
      .then((response: any) => {
        let technology: string = assetDetails?.technology_type || "";
        if (technology === "wifi_6e") {
          setVendors(response.data["wifi"]);
        } else if (technology === "cpe_cbsd") {
          setVendors(response.data["cpe_cbsd"]);
        } else if (technology === "cpe_wifi") {
          setVendors(response.data["cpe_wifi"]);
        } else setVendors(response.data[mountingType || ""]);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  return (
    <div>
      <AccordionInput
        type="vendor-dropdown"
        title="Vendor Name"
        value={assetDetails?.vendor_id?.toString()}
        options={vendors}
        onChange={(e: any) => {
          updateDetails("vendor_id", parseInt(e.target.value), "asset");
        }}
      />
      <AccordionInput type="input" title="Software Version" disabled value={vendorDetails?.software_version || ""} />
      <AccordionInput type="input" title="Hardware Version" disabled value={vendorDetails?.hardware_version || ""} />
      <AccordionInput type="input" title="Firmware Version" disabled value={vendorDetails?.firmware_version || ""} />

      <Spinner show={loading} />
      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: "", alert: false });
        }}
        message={error.message}
      />
    </div>
  );
};

export default CbsdAccordion;
