import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ApiHandler } from "../services/apiHandler";
import Spinner from "../components/Spinner";
import ErrorAlert from "../components/ErrorAlert";
import SuccessAlert from "../components/SuccessAlert";
import WarningAlert from "../components/WarningAlert";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import doughnutChartlabel from "chartjs-plugin-doughnutlabel-rebourne";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
    ArcElement
} from 'chart.js';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import {
    barOptions,
    barData,
    doughnutData,
    doughnutOptions,
    lineOptions,
    lineData
} from '../constants/ChartOptions';

import { CBSDAge, CBSDMountingTypes } from "../constants/GlobalConstants";

interface ChartsInputProps {
    title: string;
    placeholder?: string;
    onChange?: any;
    disabled?: boolean;
    value?: string | number;
    type?: string;
    className?: string;
    name?: string;
    chartFilters: any;
}

const Charts: React.FC<ChartsInputProps> = ({ chartFilters }) => {
    const [age, setAge] = useState("");
    const [mountingType, setMountingType] = useState("");

    let userCount: any;
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        message: "",
        alert: false,
    });
    const [warning, setWarning] = useState({
        message: "",
        alert: false,
    });
    const [success, setSuccess] = useState({
        message: "",
        alert: false,
    });
    const [lineOption, setLineOption] = useState(lineData);
    const [lineChartRendered, renderLineChart] = useState(false);
    const [barChartRendered, renderBarChart] = useState(false);
    const [doughnutChartRendered, renderDoughnutChart] = useState(false);
    const lineChartRef = useRef<ChartJS<"line", number[], string>>(null);

    useEffect(() => {
        getAssetCountByDays();
        getAssetCountByMountingType();
        getUserCounts();
    }, []);

    useEffect(() => {
        applyFilters();
        if (lineChartRef.current) {
            if (age === CBSDAge.ZeroFive) {
                lineChartRef.current.data.datasets[0].pointBackgroundColor = ['#4091FC', 'white', 'white', 'white'];
            } else if (age === CBSDAge.SixTen) {
                lineChartRef.current.data.datasets[0].pointBackgroundColor = ['white', '#4091FC', 'white', 'white'];
            } else if (age === CBSDAge.ElevenTwenty) {
                lineChartRef.current.data.datasets[0].pointBackgroundColor = ['white', 'white', '#4091FC', 'white'];
            } else if (age === CBSDAge.GtTwenty) {
                lineChartRef.current.data.datasets[0].pointBackgroundColor = ['white', 'white', 'white', '#4091FC'];
            }
            lineChartRef.current?.update()
        }
    }, [age]);

    useEffect(() => {
        applyFilters();
    }, [mountingType])

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        LineElement,
        PointElement,
        ArcElement,
        ChartDataLabels,
        doughnutChartlabel
    );

    /** Get Asset Count by days for line chart data*/
    const getAssetCountByDays = () => {
        setLoading(true);
        ApiHandler({ apiName: "assetCountByDays", body: {}, params: {} })
            .then((response: any) => {
                setLoading(false);
                let result: any = [];
                let count = response.data.count;
                for (let key in count) {
                    result.push(count[key]);
                };
                setLineOption(lineData);
                lineData.datasets[0].data = result;
                renderLineChart(true);
            })
            .catch((error: any) => {
                let result: any = [0, 0, 0, 0];
                lineData.datasets[0].data = result;
                lineOptions.layout.padding.top = 130;
                renderLineChart(true);
                if (error.doLogout === true) {
                    setError({ message: "Session Timed Out", alert: true });
                    setTimeout(() => navigate("/login", { replace: true }), 2000);
                } else {
                    setError({ message: error.data.message, alert: true });
                }
            });
    }

    /** Get Asset Count by mounting type for bar chart data*/
    const getAssetCountByMountingType = () => {
        setLoading(true);
        ApiHandler({ apiName: "assetCountByMountingType", body: {}, params: {} })
            .then((response: any) => {
                setLoading(false);
                let count: any = response.data.count;
                let cloudList: any = [];
                let SASList: any = [];
                let RMAList: any = [];
                for (let key in count) {
                    cloudList.push(count[key].CLOUD);
                    SASList.push(count[key].SAS);
                    RMAList.push(count[key].RMA_REQUESTED);
                }
                barData.datasets[0].data = cloudList;
                barData.datasets[1].data = SASList;
                barData.datasets[2].data = RMAList;
                barOptions.plugins.value = JSON.parse(JSON.stringify(barData.datasets)) as typeof barData;
                let mountingTypeTotal: any = [];
                for (let i = 0; i < 3; i++) {
                    mountingTypeTotal.push(barData.datasets.map((value: any) => value.data[i]).reduce((a: any, b: any) => a + b, 0));
                }
                let updatedDataset: any = [];
                let tmpDataset: any = [];
                barData.datasets.forEach((dataList: any) => {
                    dataList.data.forEach((value: any, index: any) =>
                        tmpDataset.push(parseFloat((value / mountingTypeTotal[index] * 100).toFixed(1)))
                    );
                    updatedDataset.push(tmpDataset);
                    tmpDataset = [];
                });
                for (let i = 0; i < 3; i++) {
                    barData.datasets[i].data = updatedDataset[i];
                }
                barOptions.plugins.tooltip.callbacks = {
                    label: (barData) => {
                        let datasets = barData.chart.config._config.data.datasets;
                        let percentage = datasets[barData.datasetIndex].data[barData.dataIndex];
                        return barData.dataset.label + ' : ' + percentage + '%'
                    },
                    afterLabel: (context) => {
                        let currentvalue = context.chart.config._config.options.plugins.value[context.datasetIndex].data[context.dataIndex]
                        return "Abs : " + currentvalue
                    }
                };
                renderBarChart(true);
            })
            .catch((error: any) => {
                let cloudList: any = [0, 0, 0];
                let SASList: any = [0, 0, 0];
                let RMAList: any = [0, 0, 0];
                barData.datasets[0].data = cloudList;
                barData.datasets[1].data = SASList;
                barData.datasets[2].data = RMAList;
                renderBarChart(true);
                if (error.doLogout === true) {
                    setError({ message: "Session Timed Out", alert: true });
                    setTimeout(() => navigate("/login", { replace: true }), 2000);
                } else {
                    setError({ message: error.data.message, alert: true });
                }
            });
    }

    /** Get user Count for Doughnut chart data*/
    const getUserCounts = () => {
        setLoading(true);
        ApiHandler({ apiName: "getUserCount", body: {}, params: {} })
            .then((response: any) => {
                setLoading(false);
                let result: any = [];
                userCount = response.data.count;

                {/** doughnut chart labels */ }
                doughnutData.labels = [];
                for (let key in userCount) {
                    result.push(userCount[key]);
                    doughnutData.labels.push(key);
                }
                doughnutData.labels=[...doughnutData.labels].reverse()

                {/** doughnut chart inner text labels */ }
                let userRatio = calculateRatio(userCount['CPI'], userCount['INSTALLER']);
                let ratioArray = userRatio.split(':');
                doughnutOptions.plugins.doughnutlabel.labels[0].text = ratioArray[0];
                doughnutOptions.plugins.doughnutlabel.labels[2].text = ratioArray[1];

                {/** doughnut chart datasets */ }
                doughnutData.datasets[0].data = result.reverse();
                renderDoughnutChart(true);
            })
            .catch((error: any) => {
                let result: any = [];
                doughnutData.datasets[0].data = result;
                renderDoughnutChart(true);
                if (error.doLogout === true) {
                    setError({ message: "Session Timed Out", alert: true });
                    setTimeout(() => navigate("/login", { replace: true }), 2000);
                } else {
                    setError({ message: error.data.message, alert: true });
                }
            });
    }

    {/** Calculate ratio between 2 numbers */ }
    const calculateRatio = (num1: number, num2: number) => {
        for (let num = num2; num > 1; num--) {
            if ((num1 % num) === 0 && (num2 % num) === 0) {
                num1 = num1 / num;
                num2 = num2 / num;
            }
        }
        let ratio = num1 + ":" + num2;
        return ratio;
    }

    /** Method to apply filters to the chart */
    const applyFilters = () => {
        chartFilters({
            age: age,
            mountingType: mountingType
        });
    };

    /** check selected chart btn */
    const chartBtnColor = (selectedBtn:any,chartType:any) => {
        if (chartType === 'age') {
            return (age === selectedBtn) ? "selected-chart-btn" : "unselected-chart-btn";
        } else if (chartType === 'mountingType') {
            return (mountingType === selectedBtn) ? "selected-chart-btn" : "unselected-chart-btn";
        }
    }

    /** set selected mounting type */
    const selectedMountingType = (selectedMountingType: any) => {
        if (mountingType === selectedMountingType) {
            setMountingType('');
        } else {
            setMountingType(selectedMountingType);
        }
    }

    /** set selected Age */
    const selectedAge = (selectedAge: any) => {
        if (age === selectedAge) {
            setAge('');
            if (lineChartRef.current) {
                lineChartRef.current.data.datasets[0].pointBackgroundColor = ['white', 'white', 'white', 'white'];
                lineChartRef.current?.update()
            }
        } else {
            setAge(selectedAge);
        }
    }

    return (
        <div className="row-wise">
            <div className="card-content">
                {/* cbsd age's in days line chart */}
                <div className="col-4 app-card">
                    <div className="font14 fw-bold">CBSD'S AGE IN DAYS</div>
                    {lineChartRendered ? (
                        <div className="chartsize">
                            <div className="col float-lg-start daystext">Days</div>
                            <div className="col">
                                <div className="row pt-2">
                                    <Line options={lineOptions} ref={lineChartRef} plugins={[ChartDataLabels]} data={lineOption} />
                                </div>
                                <div className="row">
                                    <div className={`${chartBtnColor(CBSDAge.ZeroFive,'age')} chartlabel zero-five-btn`}
                                        onClick={() => selectedAge(CBSDAge.ZeroFive)}>
                                        {CBSDAge.ZeroFive}
                                    </div>
                                    <div className={`${chartBtnColor(CBSDAge.SixTen,'age')} chartlabel five-ten-btn`}
                                        onClick={() => selectedAge(CBSDAge.SixTen)}>
                                        {CBSDAge.SixTen}
                                    </div>
                                    <div className={`${chartBtnColor(CBSDAge.ElevenTwenty,'age')} chartlabel ten-fifteen-btn`}
                                        onClick={() => selectedAge(CBSDAge.ElevenTwenty)}>
                                        {CBSDAge.ElevenTwenty}
                                    </div>
                                    <div className={`${chartBtnColor(CBSDAge.GtTwenty,'age')} chartlabel gt-twenty-btn`}
                                        onClick={() => selectedAge(CBSDAge.GtTwenty)}>
                                        {CBSDAge.GtTwenty}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (null)}
                </div>
                {/*cbsd mounting type stacked bar chart */}
                <div className="col-4 app-card ms-4">
                    <div className="font14 fw-bold">CBSD MOUNTING TYPE</div>
                    {barChartRendered ? (
                        <div className="row-wise">
                            <div className="col-2">
                                <div className={`${chartBtnColor(CBSDMountingTypes.AERIAL,'mountingType')} chartlabel aerial-btn`}
                                    onClick={() => selectedMountingType(CBSDMountingTypes.AERIAL)}>
                                    AERIAL
                                </div>
                                <div className={`${chartBtnColor(CBSDMountingTypes.ROE,'mountingType')} chartlabel roe-btn`}
                                    onClick={() => selectedMountingType(CBSDMountingTypes.ROE)}>
                                    ROE
                                </div>
                                <div className={`${chartBtnColor(CBSDMountingTypes.SMB,'mountingType')} chartlabel smb-btn`}
                                    onClick={() => selectedMountingType(CBSDMountingTypes.SMB)}>
                                    SMB
                                </div>
                            </div>
                            <div className="col-10" style={{ height: '215px' }}>
                                <Bar height={200} data={barData} options={barOptions} />
                            </div>
                        </div>
                    ) : (null)}
                </div>
                {/* CPI vs INSTALLER doughnut chart*/}
                <div className="col app-card ms-4">
                    <div className="font14 fw-bold">CPI VS INSTALLERS RATIO</div>
                    {doughnutChartRendered ? (
                        <div className="d-flex justify-content-center align-item-center">
                            <div className="doughnutchart pt-2">
                                <Doughnut data={doughnutData} options={doughnutOptions} />
                            </div>
                        </div>
                    ) : (null)}
                </div>
                <Spinner show={loading} />
                <ErrorAlert
                    show={error.alert}
                    onDismiss={() => {
                        setError({ message: "", alert: false });
                    }}
                    message={error.message} />
                <SuccessAlert
                    show={success.alert}
                    onDismiss={() => {
                        setSuccess({ message: "", alert: false });
                    }}
                    message={success.message} />
                <WarningAlert
                    show={warning.alert}
                    onDismiss={() => {
                        setWarning({ message: "", alert: false });
                    }}
                    message={warning.message} />
            </div>
        </div>
    )
}

export default Charts;