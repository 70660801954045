import React from "react";

interface ZoomPlusProps {
  clicked: any;
  map: google.maps.Map | undefined;
}

const ZoomPlus: React.FC<ZoomPlusProps> = ({ clicked, map }) => {
  const zoomIncreased = () => {
    clicked(map?.getZoom());
  };
  return (
    <div onClick={zoomIncreased} title="Legend" className="map-icon-background mb-3">
      <span className="material-icons-outlined map-icon">add</span>
    </div>
  );
};

export default ZoomPlus;
