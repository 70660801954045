import React, { useEffect, useState } from "react";
import { TablePagination } from "@mui/material";
import { ApiHandler } from "../services/apiHandler";
import CBSDAssetTableHeader from "./CBSDAssetTableHeader";
import CBSDAssetActions from "./CBSDAssetActions";
import CBSDAssetsNav from "./CBSDAssetsNav";
import { CBSDStatuses } from "../constants/GlobalConstants";
import { exists } from "../services/helper";
import "./Users.css";
import Spinner from "./Spinner";
import ErrorAlert from "../components/ErrorAlert";
import AssetProps from "../interfaces/AssetProps";
import NetworkActivityModal from "./NetworkActivityModal";
import PrimaryButton from "./PrimaryButton";

interface CBSDAssetFilters {
  chartFilters: any;
  searchFilters: any;
  assetFilters: any;
  takeToMap: any; // Function to take user to map.
}

const CBSDAssets: React.FC<CBSDAssetFilters> = ({ chartFilters, searchFilters, assetFilters, takeToMap }) => {
  const [assetCount, setAssetCount] = useState(0);
  const [assets, setAssets] = useState<any>([]);
  const [assetSummary, setAssetSummary] = useState({ cloudCount: 0, rmaCount: 0, sasCount: 0 });
  const [viewType, setViewType] = useState(CBSDStatuses.CLOUD.key);
  const [assetRowsPerPage, setAssetRowsPerPage] = useState(10);
  const [currentAssetPage, setCurrentAssetPage] = useState(0);
  const [error, setError] = useState({
    message: "",
    alert: false,
  });
  const [loading, setLoading] = useState(false);
  const [selectedAssetDetails, setSelectedAssetDetails] = useState<AssetProps>();
  const [assetDetailsModal, showAssetDetailsModal] = useState(false);

  useEffect(() => {
    getAssets();
  }, []);

  useEffect(() => {
    getAssets();
  }, [currentAssetPage, assetRowsPerPage, viewType, chartFilters, assetFilters]);

  {
    /** The searchString will be cleared once the searched asset is selected */
  }
  useEffect(() => {
    if (exists(searchFilters.trackingId) || searchFilters.abortSearch) {
      changeViewType();
    }
  }, [searchFilters]);

  const catchApiError = (error: any) => {
    setLoading(false);
    if (error.doLogout === true) {
      setError({ message: "Session Timed Out", alert: true });
    } else {
      setError({ message: error.data.message, alert: true });
    }
  };

  {
    /** The viewtype to be changed based on the response from search filter */
  }
  const changeViewType = () => {
    let changedViewType = viewType;
    if (searchFilters && exists(searchFilters.trackingId)) {
      if (searchFilters.status.toLowerCase() === CBSDStatuses.CLOUD.value.toLowerCase()) {
        changedViewType = CBSDStatuses.CLOUD.key;
      } else if (searchFilters.status.toLowerCase() === CBSDStatuses.SAS.value.toLowerCase()) {
        changedViewType = CBSDStatuses.SAS.key;
      } else if (searchFilters.status.toLowerCase() === CBSDStatuses.RMA_REQUESTED.value.toLowerCase()) {
        changedViewType = CBSDStatuses.RMA_REQUESTED.key;
      }
    }
    setViewType(changedViewType);
    getAssets();
  };

  {
    /** This method will be called whenever there is a change
       happen in the asset status type (cloud or sas or rma) */
  }
  const refreshAssets = (viewType: string) => {
    setViewType(viewType);
  };

  {
    /** This method is used to get the filters for fetching assets*/
  }
  const getFilters = () => {
    let filters: any = {};

    {
      /** Search filters. if tracking_id is set, should not apply other filters */
    }
    if (searchFilters && exists(searchFilters.trackingId)) {
      filters["tracking_id"] = searchFilters.trackingId;
      return filters;
    }

    {
      /** Chart filters */
    }
    if (chartFilters && exists(chartFilters.age)) {
      filters["age"] = chartFilters.age;
    }
    if (chartFilters && exists(chartFilters.mountingType)) {
      filters["mounting_type"] = [chartFilters.mountingType];
    }

    {
      /** Other filters */
    }
    if (assetFilters && exists(assetFilters.mountingType)) {
      filters["mounting_type"] = [assetFilters.mountingType];
    }
    if (assetFilters && exists(assetFilters.installer)) {
      filters["installer"] = [assetFilters.installer];
    }
    if (assetFilters && isDateFilterExists(assetFilters)) {
      filters["date_range"] = {
        from_date: assetFilters.date.startDate,
        to_date: assetFilters.date.endDate,
      };
    }

    return filters;
  };

  {
    /** Method to check date filter exists */
  }
  const isDateFilterExists = (assetFilters: any) => {
    return assetFilters.date && exists(assetFilters.date.startDate) && exists(assetFilters.date.endDate);
  };

  /** This method will get single asset details */
  const getSelectedAssetDetails = (trackingId: string) => {
    setLoading(true);
    ApiHandler({ apiName: "getAsset", body: {}, params: { tracking_id: trackingId } })
      .then((response: any) => {
        setLoading(false);
        setSelectedAssetDetails(response.data);
        showAssetDetailsModal(true);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  {
    /** This method is used to call the asset API to 
       get the summary and list of assets */
  }
  const getAssets = () => {
    {
      /** Get Asset Request Body */
    }
    let requestBody: any = {
      filter: getFilters(),
      status: viewType,
      rows_per_page: assetRowsPerPage,
      page: currentAssetPage,
    };

    ApiHandler({ apiName: "listAssets", body: requestBody, params: {} })
      .then((response: any) => {
        setAssetCount(response.data.assets.assets_length);
        setAssets(response.data.assets.assets_list);
        setAssetSummary({
          cloudCount: response.data.summary.cloud_count,
          rmaCount: response.data.summary.rma_count,
          sasCount: response.data.summary.sas_count,
        });
      })
      .catch((error: any) => {
        setError({ message: error.data.message, alert: true });
      });
  };

  const exportData = () => {
    setLoading(true);
    ApiHandler({ apiName: "exportAssets", body: {}, params: {} })
      .then((response: any) => {
        setLoading(false);
        window.open(response.data.url, "_blank");
      })
      .catch((error: any) => {
        catchApiError(error);
      });
  };

  return (
    <div className="app-card h-100 mt-3">
      <div className="row-wise justify-content-between">
        {/** Asset Navigations */}
        <CBSDAssetsNav searchedView={viewType} summary={assetSummary} refreshAssets={refreshAssets} />
        <PrimaryButton
          clicked={() => {
            exportData();
          }}
          label="Export Data"
        />
      </div>

      {/** Asset Table Pagination */}
      <div>
        <TablePagination
          component="div"
          count={assetCount}
          page={currentAssetPage}
          onPageChange={(e: any, newPage: any) => {
            setCurrentAssetPage(newPage);
          }}
          rowsPerPage={assetRowsPerPage}
          onRowsPerPageChange={(event: any) => {
            setAssetRowsPerPage(event.target.value);
            setCurrentAssetPage(0);
          }}
        />
      </div>

      {/** Asset Table List */}
      <table className="w-100 d-table">
        <thead className="d-table w-100">
          <tr>
            <CBSDAssetTableHeader />
          </tr>
        </thead>
        <tbody className="user-tablebody">
          {assets.map((asset: any) => {
            return (
              <tr key={asset.tracking_id}>
                <CBSDAssetActions
                  asset={asset}
                  assetClicked={() => {
                    getSelectedAssetDetails(asset.tracking_id);
                  }}
                />
              </tr>
            );
          })}
        </tbody>
      </table>

      <NetworkActivityModal
        show={assetDetailsModal}
        close={() => {
          showAssetDetailsModal(false);
        }}
        assetDetails={selectedAssetDetails}
        takeToMap={takeToMap}
      />

      <Spinner show={loading} />
      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: "", alert: false });
        }}
        message={error.message}
      />
    </div>
  );
};

export default CBSDAssets;
