import React from "react";

const Analytics: React.FC = () => {
  return (
    <div className="h-100 w-100">
      <h1>Analytics</h1>
    </div>
  );
};

export default Analytics;
