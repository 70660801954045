import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { ApiHandler } from "../services/apiHandler";
import ErrorAlert from "./ErrorAlert";
import Spinner from "./Spinner";

interface MeasureProps {
  goBack: any;
  measurePoints: any[];
  assetsForMeasure: string[];
  setInfoPopup: (details: any) => void;
}

const Measure: React.FC<MeasureProps> = ({ goBack, measurePoints, assetsForMeasure, setInfoPopup }) => {
  const elevator = new google.maps.ElevationService();
  const [chartValues, setChartValues] = useState<any[][]>([]);
  const [distance, setDistance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: "",
    alert: false,
  });

  useEffect(() => {
    if (measurePoints.length === 2) {
      setLoading(true);
      ApiHandler({ apiName: "getDistance", body: { points: measurePoints, assets: assetsForMeasure }, params: {} })
        .then((response: any) => {
          setDistance(response.data.distance);
          elevationProfileGraph();
          setInfoPopup(response.data);
          setLoading(false);
        })
        .catch((error: any) => {
          catchApiError(error);
        });
    }
  }, [measurePoints]);

  const catchApiError = (error: any) => {
    setLoading(false);
    if (error.doLogout === true) {
      setError({ message: "Session Timed Out", alert: true });
    } else {
      setError({ message: error.data.message, alert: true });
    }
  };

  const elevationProfileGraph = () => {
    elevator.getElevationAlongPath(
      {
        path: measurePoints,
        samples: 500,
      },
      plotElevation
    );
  };

  const plotElevation = (elevations: any, status: any) => {
    let chartData: any[][] = [];
    chartData.push(["x", "Elevation", { type: "string", role: "tooltip" }]);
    elevations.map((data: any, index: any) => {
      let setValues = [];
      setValues.push(index);
      setValues.push(parseFloat(data.elevation.toFixed(1)));
      setValues.push("Elevation: " + data.elevation.toFixed(1) + " m");
      chartData.push(setValues);
    });
    setChartValues(chartData);
  };

  return (
    <div>
      <div className="app-card position-relative measure-app-card-size">
        <div className="row-wise vertical-center">
          <div
            className="icon-background-active"
            onClick={() => {
              goBack("home");
            }}
          >
            <span className="material-icons-round icon-active">arrow_back</span>
          </div>

          <span className="app-card-action-heading ms-3">MEASURE</span>
        </div>

        <span className="asset-drop-info mt-2 mb-2 w-100">Measure distances between CBSDs</span>

        <span className="measure-info mb-2 mt-3">1. Select First CBSD</span>
        <span className="measure-info mb-4">2. Select Second CBSD</span>

        <div className="result-box col-wise">
          <span className="result-title mt-3">{"Distance (m)"}</span>
          <span className="result-label">{`${distance} m`}</span>
        </div>

        <Spinner show={loading} />
        <ErrorAlert
          show={error.alert}
          onDismiss={() => {
            setError({ message: "", alert: false });
          }}
          message={error.message}
        />
      </div>

      {chartValues && chartValues.length > 0 && (
        <div className="elevation-profile-chart">
          <Chart
            width={"100%"}
            height={"250px"}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={chartValues}
            options={{
              title: "Elevation Profile",
              vAxis: {
                title: "Elevation(m)",
              },
              hAxis: {
                textPosition: "none",
              },
              legend: "none",
            }}
            rootProps={{ "data-testid": "1" }}
          />
        </div>
      )}
    </div>
  );
};

export default Measure;
