import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiHandler } from "../services/apiHandler";
import ConfirmAlert from "./confirmAlert";
import ErrorAlert from "./ErrorAlert";
import LabelledInput from "./LabelledInput";
import LabelLessDropdown from "./LabelLessDropdown";
import Modal from "./Modal";
import PrimaryButton from "./PrimaryButton";
import SuccessAlert from "./SuccessAlert";
import "./Vendor.css";
import WarningAlert from "./WarningAlert";

interface VendorProps {
  type: string;
  title: string;
  selectedVendorId: string | number;
  onChange: any;
  options: any[];
  refresh: any;
  processing: any;
}

interface IdefaultVendor {
  id?: string;
  vendor_model: string;
  software_version: string;
  hardware_version: string;
  firmware_version: string;
  vendor_name: string;
  antenna_pattern: string;
  fcc_id: string;
  antenna_beamwidth: number;
  antenna_model: string;
  antenna_gain: string;
  eirp_capability: string;
  cbsd_category: string;
  radio_technology: string;
  transmitters: string;
  directionality: string;
  mounting_type: string;
  technology_type: string;
}

const Vendor: React.FC<VendorProps> = ({ title, selectedVendorId, onChange, options, refresh, processing, type }) => {
  let navigate = useNavigate();
  const defaulVendorDetails: IdefaultVendor = {
    id: "",
    vendor_model: "",
    software_version: "",
    hardware_version: "",
    firmware_version: "",
    vendor_name: "",
    antenna_pattern: "",
    fcc_id: "",
    antenna_beamwidth: 0,
    antenna_model: "",
    antenna_gain: "",
    eirp_capability: "",
    cbsd_category: "",
    radio_technology: "",
    transmitters: "",
    directionality: ["roe", "aerial"].includes(title.toLowerCase()) ? "Directional" : "Omni",
    mounting_type: "",
    technology_type: type || "",
  };

  const [modalVisible, toggleModalVisibility] = useState(false);
  const [vendorDetails, setVendorDetails] = useState<IdefaultVendor>(defaulVendorDetails);
  const [actionType, setActionType] = useState<string>("add");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [warningAlert, setWarningAlert] = useState(false);
  const [deleteConfirmVisible, setDeleteConfirmVisibility] = useState(false);

  useEffect(() => {
    if (modalVisible === false || actionType === "add") {
      setVendorDetails(defaulVendorDetails);
    }
  }, [modalVisible, actionType]);

  const setWarning = (message: string) => {
    setWarningMessage(message);
    setWarningAlert(true);
  };

  const validateDetails = (): boolean => {
    console.table(vendorDetails);
    if (vendorDetails.vendor_name.trim() === "") {
      setWarning("Name is required");
      return false;
    } else if (vendorDetails.vendor_model.trim() === "") {
      setWarning("Model is required");
      return false;
    } else if (
      isNaN(Number(vendorDetails.antenna_beamwidth)) ||
      vendorDetails.antenna_beamwidth.toString().trim() === ""
    ) {
      setWarning("Antenna Beamwidth is required");
      return false;
    } else if (vendorDetails.antenna_model.trim() === "") {
      setWarning("Antenna Model is required");
      return false;
    } else if (isNaN(Number(vendorDetails.antenna_gain)) || vendorDetails.antenna_gain.trim() === "") {
      setWarning("Antenna Gain is required");
      return false;
    } else if (isNaN(Number(vendorDetails.eirp_capability)) || vendorDetails.eirp_capability.trim() === "") {
      setWarning("EIRP Capability is required");
      return false;
    } else if (vendorDetails.cbsd_category.trim() === "") {
      setWarning("Category is required");
      return false;
    } else if (vendorDetails.radio_technology.trim() === "") {
      setWarning("Radio Technology is required");
      return false;
    }
    return true;
  };

  const create = () => {
    let validated: boolean = validateDetails();
    console.log(validated);

    if (!validated) return;
    processing(true);
    let requestBody: any = vendorDetails;
    requestBody["mounting_type"] = ["wifi_6e", "cpe_wifi", "cpe_cbsd"].includes(type) ? "SMB" : title;
    requestBody["technology_type"] = type;
    delete requestBody.id;
    ApiHandler({ apiName: "addVendor", body: requestBody, params: {} })
      .then((response: any) => {
        processing(false);
        setSuccessMessage(response.data.message);
        setSuccessAlert(true);
        toggleModalVisibility(false);
        refresh();
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setErrorMessage("Session Timed Out");
          setErrorAlert(true);
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setErrorMessage(error.data.message);
          setErrorAlert(true);
        }
      });
  };
  const edit = () => {
    processing(true);
    ApiHandler({ apiName: "getVendorDetails", body: {}, params: { vendor_id: selectedVendorId } })
      .then((response: any) => {
        processing(false);
        setVendorDetails(response.data.vendor);
        setActionType("edit");
        toggleModalVisibility(true);
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setErrorMessage("Session Timed Out");
          setErrorAlert(true);
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setErrorMessage(error.data.message);
          setErrorAlert(true);
        }
      });
  };

  const update = () => {
    let validated: boolean = validateDetails();
    if (!validated) return;
    processing(true);
    let requestBody: any = vendorDetails;
    requestBody["mounting_type"] = ["wifi_6e", "cpe_wifi", "cpe_cbsd"].includes(type) ? "SMB" : title;
    requestBody["technology_type"] = type;
    delete requestBody.id;
    ApiHandler({ apiName: "updateVendor", body: requestBody, params: { vendor_id: selectedVendorId } })
      .then((response: any) => {
        processing(false);
        setSuccessMessage(response.data.message);
        setSuccessAlert(true);
        toggleModalVisibility(false);
        refresh();
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setErrorMessage("Session Timed Out");
          setErrorAlert(true);
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setErrorMessage(error.data.message);
          setErrorAlert(true);
        }
      });
  };
  const remove = (e: any) => {
    e.preventDefault();
    setDeleteConfirmVisibility(false);
    processing(true);
    ApiHandler({ apiName: "deleteVendor", body: {}, params: { vendor_id: selectedVendorId } })
      .then((response: any) => {
        processing(false);
        setSuccessMessage(response.data.message);
        setSuccessAlert(true);
        toggleModalVisibility(false);
        refresh();
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setErrorMessage("Session Timed Out");
          setErrorAlert(true);
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setErrorMessage(error.data.message);
          setErrorAlert(true);
        }
      });
  };

  const handleDetailsChange = (e: any) => {
    setVendorDetails((prevValues: any) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  return (
    <div>
      <div className="col-wise background-separator mt-2">
        <div className="row-wise">
          <span className="sub-heading-vendors">{title}</span>

          <button
            className="add-button"
            style={{ marginTop: "10px", marginLeft: "10px" }}
            onClick={(e: any) => {
              e.preventDefault();
              setActionType("add");
              toggleModalVisibility(!modalVisible);
            }}
          >
            <span className="material-icons-round font-14">add</span>
            ADD
          </button>
        </div>
        <div className="row-wise w-100 vertical-center">
          <div className={`dropdown w-100`}>
            <button className="w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              {options.filter((option: any) => option.id.toString() === selectedVendorId.toString()).length > 0
                ? `${
                    options.filter((option: any) => option.id.toString() === selectedVendorId.toString())[0].vendor_name
                  }-${
                    options.filter((option: any) => option.id.toString() === selectedVendorId.toString())[0]
                      .vendor_model
                  }`
                : "Select " + title + " Vendor"}
            </button>

            <ul className={`dropdown-menu w-100`}>
              {options.map((option: any) => {
                return (
                  <li key={option.id}>
                    <span
                      onClick={() => {
                        onChange(option.id);
                      }}
                      className="dropdown-item"
                    >
                      {`${option.vendor_name}-${option.vendor_model}`}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>

          <span
            onClick={edit}
            className="material-icons-round ms-2"
            title="Edit selected vendor"
            style={{ color: "#0059b2", cursor: "pointer" }}
          >
            edit
          </span>
          <span
            onClick={() => {
              setDeleteConfirmVisibility(true);
            }}
            className="material-icons-round ms-1"
            title="Delete selected vendor"
            style={{ color: "#d11a2a", cursor: "pointer" }}
          >
            delete
          </span>
        </div>
      </div>

      <Modal show={modalVisible}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content app-card border-0 p-0">
            <div className="modal-header">
              <h5 className="modal-title">
                {actionType} {title} Vendor
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  toggleModalVisibility(!modalVisible);
                }}
              ></button>
            </div>
            <div className="modal-body">
              <LabelledInput
                title="Vendor Name *"
                name="vendor_name"
                value={vendorDetails.vendor_name}
                onChange={handleDetailsChange}
              />
              <LabelledInput
                title="Model *"
                name="vendor_model"
                value={vendorDetails.vendor_model}
                onChange={handleDetailsChange}
              />
              <LabelledInput
                title="Antenna Pattern"
                name="antenna_pattern"
                value={vendorDetails.antenna_pattern}
                onChange={handleDetailsChange}
              />
              <LabelledInput title="FCC ID" name="fcc_id" value={vendorDetails.fcc_id} onChange={handleDetailsChange} />
              <LabelledInput
                value={vendorDetails.antenna_beamwidth}
                title="Antenna Beamwidth (degrees) *"
                name="antenna_beamwidth"
                onChange={handleDetailsChange}
              />
              <LabelledInput
                title="Antenna Model *"
                value={vendorDetails.antenna_model}
                name="antenna_model"
                onChange={handleDetailsChange}
              />
              <LabelledInput
                title="Software Version"
                value={vendorDetails.software_version}
                name="software_version"
                onChange={handleDetailsChange}
              />
              <LabelledInput
                title="Hardware Version"
                value={vendorDetails.hardware_version}
                name="hardware_version"
                onChange={handleDetailsChange}
              />
              <LabelledInput
                title="Firmware Version"
                value={vendorDetails.firmware_version}
                name="firmware_version"
                onChange={handleDetailsChange}
              />
              <LabelledInput
                title="Antenna Gain (dBi) *"
                value={vendorDetails.antenna_gain}
                name="antenna_gain"
                onChange={handleDetailsChange}
              />
              <LabelledInput
                title="EIRP Capability (dBm) *"
                value={vendorDetails.eirp_capability}
                name="eirp_capability"
                onChange={handleDetailsChange}
              />
              <span className="input-label ms-2">{"Category *"}</span>
              <LabelLessDropdown
                className="mb-2 mt-1"
                fullWidth
                title="Category *"
                options={title.toLowerCase() === "cpe" ? ["A", "B", "EUD"] : ["A", "B"]}
                name="cbsd_category"
                onChange={handleDetailsChange}
                value={vendorDetails.cbsd_category}
              />
              <span className="input-label ms-2">{"Radio Technologies *"}</span>
              <LabelLessDropdown
                name="radio_technology"
                className="mb-2 mt-1"
                fullWidth
                title="Radio Technologies *"
                options={[
                  "RADIO_TECHNOLOGY_UNSPECIFIED",
                  "E_UTRA",
                  "CAMBIUM_NETWORKS",
                  "FOUR_G_BBW_SAA_1",
                  "NR",
                  "DOODLE_CBRS",
                  "REDLINE",
                  "TARANA_WIRELESS",
                ]}
                value={vendorDetails.radio_technology}
                onChange={handleDetailsChange}
              />
              <span className="input-label ms-2">{"No. of Transmitters"}</span>
              <LabelLessDropdown
                name="transmitters"
                className="mb-2 mt-1"
                fullWidth
                title="No. of Transmitters"
                options={["1", "2", "3", "4"]}
                onChange={handleDetailsChange}
                value={vendorDetails.transmitters}
              />
              <LabelledInput
                title="Directionality *"
                value={vendorDetails.directionality}
                disabled
                name="directionality"
              />
            </div>
            <div className="modal-footer">
              <PrimaryButton
                label={actionType === "add" ? "Create" : "Update"}
                disable={false}
                clicked={actionType === "add" ? create : update}
              />
            </div>
          </div>
        </div>
      </Modal>

      <ErrorAlert
        show={errorAlert}
        onDismiss={() => {
          setErrorAlert(false);
        }}
        message={errorMessage}
      />
      <SuccessAlert
        show={successAlert}
        onDismiss={() => {
          setSuccessAlert(false);
        }}
        message={successMessage}
      />
      <WarningAlert
        show={warningAlert}
        onDismiss={() => {
          setWarningAlert(false);
        }}
        message={warningMessage}
      />
      <ConfirmAlert
        title="Confirm Delete"
        message="Are you sure you want to delete the vendor?"
        primaryText="Confirm"
        secondaryText="Cancel"
        secondaryAction={() => {
          setDeleteConfirmVisibility(false);
        }}
        primaryAction={remove}
        visible={deleteConfirmVisible}
      />
    </div>
  );
};

export default Vendor;
