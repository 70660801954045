import React from "react";

interface LabelledInputProps {
  title: string;
  placeholder?: string;
  onChange?: any;
  disabled?: boolean;
  value?: string | number;
  type?: string;
  className?: string;
  name?: string;
}

const LabelledInput: React.FC<LabelledInputProps> = ({
  disabled = false,
  title,
  placeholder = "placeholder",
  onChange,
  value = "",
  type = "text",
  className = "",
  name,
}) => {
  const updateValue = (e: any) => {
    onChange(e);
  };

  return (
    <div className={"form-floating w-100 mb-2 " + className}>
      <input
        name={name}
        disabled={disabled}
        autoComplete={"new-password"}
        className="form-control input-box input-text vertical-center"
        placeholder={placeholder}
        onChange={(e: any) => {
          e.preventDefault();
          updateValue(e);
        }}
        value={value}
        type={type}
      ></input>
      <label className="input-label vertical-center" htmlFor="labelledInput">
        {title}
      </label>
    </div>
  );
};

export default LabelledInput;
