import React from "react";
import { AssetObj, SectorObj, VendorObj } from "../interfaces/AssetProps";
import AccordionInput from "./AccordionInput";

interface OptionalAccordionProps {
  assetDetails: AssetObj | undefined;
  sectorDetails: SectorObj | undefined;
  vendorDetails: VendorObj | undefined;
  updateDetails: any;
}

const OptionalAccordion: React.FC<OptionalAccordionProps> = ({
  assetDetails,
  sectorDetails,
  vendorDetails,
  updateDetails,
}) => {
  return (
    <div>
      <AccordionInput
        title="Call Sign"
        type="dropdown"
        value={sectorDetails?.call_sign || ""}
        options={["1", "2"]}
        onChange={(e: any) => {
          updateDetails("call_sign", e.target.value, "sector");
        }}
      />
      <AccordionInput
        title="Grouping Param"
        type="dropdown"
        value={sectorDetails?.grouping_params || ""}
        options={["1", "2"]}
        onChange={(e: any) => {
          updateDetails("grouping_params", e.target.value, "sector");
        }}
      />
      <AccordionInput
        title={`${assetDetails?.technology_type === "wifi_6e" ? "WIFI" : "CBSD"} Category`}
        type="input"
        value={vendorDetails?.cbsd_category ? vendorDetails?.cbsd_category : ""}
        disabled
      />
      <AccordionInput
        title="Air Interface"
        type="input"
        value={vendorDetails?.radio_technology ? vendorDetails?.radio_technology : ""}
        disabled
      />
      <AccordionInput
        title="Supported Spec"
        type="input"
        value={sectorDetails?.supported_spec || ""}
        onChange={(e: any) => {
          updateDetails("supported_spec", e.target.value, "sector");
        }}
      />
      <AccordionInput
        title="Antenna Vertical Beamwidth"
        type="input"
        value={sectorDetails?.antenna_vertical_beamwidth?.toString() || ""}
        onChange={(e: any) => {
          updateDetails("antenna_vertical_beamwidth", e.target.value, "sector");
        }}
      />
      <AccordionInput
        title="Front to Back Ratio"
        type="input"
        value={sectorDetails?.front_to_back_ratio?.toString() || ""}
        onChange={(e: any) => {
          updateDetails("front_to_back_ratio", e.target.value, "sector");
        }}
      />
      <AccordionInput
        title="Interference Coordination Group"
        type="input"
        value={sectorDetails?.interference_coordination_group || ""}
        onChange={(e: any) => {
          updateDetails("interference_coordination_group", e.target.value, "sector");
        }}
      />
      <AccordionInput
        title="Common Channel Group"
        type="input"
        value={sectorDetails?.common_channel_group || ""}
        onChange={(e: any) => {
          updateDetails("common_channel_group", e.target.value, "sector");
        }}
      />
    </div>
  );
};

export default OptionalAccordion;
