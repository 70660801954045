// AUTHOR: Rahul Choudhary | Whirldata Inc.
// DATE: 22 Aug 2022
// DESCRIPTION:
// -- Touch point of the web-app.
// -- Contains all the routes and paths of all the pages.
// -- Uses React's react-router-dom package to navigate between the pages.
// -- CSS classes used throughout the app are from,
//   -- Bootstrap v5
//   -- App.css (global)
//   -- individual page css (bound to that page only)

import React from "react";
import Login from "./pages/Login";
import Home from "./pages/Home";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import SetPassword from "./pages/SetPassword";

const App: React.FC = () => (
  <Router>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/home" element={<Home />} />
      <Route path="/set-password" element={<SetPassword />} />
      <Route path="/" element={<Navigate to="/login" replace />} />
    </Routes>
  </Router>
);

export default App;
