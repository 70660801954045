import React from "react";

interface ZoomMinusProps {
  clicked: any;
  map: google.maps.Map | undefined;
}

const ZoomMinus: React.FC<ZoomMinusProps> = ({ clicked, map }) => {
  const zoomDecreased = () => {
    clicked(map?.getZoom());
  };
  return (
    <div onClick={zoomDecreased} title="Legend" className="map-icon-background mb-3">
      <span className="material-icons-outlined map-icon">remove</span>
    </div>
  );
};

export default ZoomMinus;
