import React, { useState } from "react";
import AssetProps from "../interfaces/AssetProps";
import Asset from "./Asset";
import AssetDetails from "./AssetDetails";
import Calculate from "./Calculate";
import EditAsset from "./EditAsset";
import HomeActions from "./homeActions";
import Measure from "./Measure";
import Search from "./Search";
import { AddVenue } from "./AddVenue/AddVenue";
import { VenueDetails } from "./VenueDetails/VenueDetails";
import { AddBuilding } from "./AddBuilding/AddBuilding";
import { EditVenue } from "./EditVenue/EditVenue";
import { BuildingDetails } from "./BuildingDetails/BuildingDetails";
import { EditBuilding } from "./EditBuilding/EditBuilding";
import { FloorActions } from "./FloorActions/FloorActions";
import { FloorDetails } from "./FloorDetails/FloorDetails";
import WifiIcon from "../assets/wifi_icon.png";
import "./OutdoorRoutes.css";
import AddWifi from "./AddWifi";
import { SMB_ICONS } from "../constants/IconConstants";
import AddCpe from "./AddCpe";

interface OutdoorRoutesProps {
  map: any;
  currentPage: string;
  setCurrentPage: (page: string) => void;
  addAssetClicked: () => void;
  addWifiClicked: () => void;
  addCpeClicked: () => void;
  addVenueClicked: () => void;
  measureClicked: () => void;
  calculateClicked: () => void;
  setCenter: (center: any) => void;
  showSearchLocation: (location: boolean) => void;
  assetDropLocation: any;
  getAssetsInBounds: (map: any) => void;
  changeMapForAzimuth: (isActive: boolean) => void;
  goToProfile: () => void;
  rmaNewSerialNumber: string;
  setSelectedAsset: (asset: any) => void;
  assetEditAction: any;
  selectedAsset: any;
  calculateLocation: any;
  setCalculateLocation: (location: any) => void;
  getSingleAssetDetails: (trackingId: string) => void;
  setAssetEditAction: (action: any) => void;
  setRmaNewSerialNumber: (serialNumber: string) => void;
  setAssetDropLocation: (location: any) => void;
  center: any;
  setMeasurePoints: (points: any) => void;
  showMeasurePopup: (show: boolean) => void;
  setDetailsOfMeasure: (info: any) => void;
  measurePoints: any;
  assetsForMeasure: any;
  boundaryPolygon: google.maps.LatLng[] | undefined;
  setBoundaryDrawMode: (mode: "draw" | null) => void;
  cancelBoundaryDraw: () => void;
  setBoundaryColor: (color: string) => void;
  boundaryColor: string;
  setCurrentVenueId: (id: string) => void;
  venueId: string;
  setBuildingsForVenue: (buildings: any) => void;
  setBuildingId: (buildingId: string) => void;
  buildingId: string;
  setFloorId: (floorId: string) => void;
  floorId: string;
  venuesOnMap: any;
  assetsInBounds: any[];
  fitVenueOnMap: () => void;
  setFloorGeoJSON: (geoJSON: any) => void;
  setFloorGeoJSONUrl: (url: string) => void;
  panToBuilding: (buildingId: string) => void;
  unsetFloorGeoJSON: () => void;
}

const OutdoorRoutes: React.FC<OutdoorRoutesProps> = ({
  currentPage,
  setCurrentPage,
  addAssetClicked,
  addWifiClicked,
  addCpeClicked,
  addVenueClicked,
  measureClicked,
  calculateClicked,
  map,
  setCenter,
  showSearchLocation,
  assetDropLocation,
  getAssetsInBounds,
  changeMapForAzimuth,
  goToProfile,
  rmaNewSerialNumber,
  setSelectedAsset,
  assetEditAction,
  selectedAsset,
  calculateLocation,
  setCalculateLocation,
  getSingleAssetDetails,
  setAssetEditAction,
  setRmaNewSerialNumber,
  setAssetDropLocation,
  center,
  setMeasurePoints,
  showMeasurePopup,
  setDetailsOfMeasure,
  assetsForMeasure,
  measurePoints,
  boundaryPolygon,
  setBoundaryDrawMode,
  cancelBoundaryDraw,
  setBoundaryColor,
  boundaryColor,
  setCurrentVenueId,
  venueId,
  setBuildingsForVenue,
  setBuildingId,
  buildingId,
  setFloorId,
  floorId,
  venuesOnMap,
  assetsInBounds,
  fitVenueOnMap,
  setFloorGeoJSON,
  setFloorGeoJSONUrl,
  panToBuilding,
  unsetFloorGeoJSON,
}) => {
  const [floorActionType, setFloorActionType] = useState("");
  let technologyType = localStorage.getItem("technology_type_name") || "";

  if (currentPage === "home") {
    return (
      <div className="app-card position-absolute m-3" style={{ width: "350px" }}>
        <div
          className="search-box mb-3 col-12 center ps-3"
          onClick={() => {
            setCurrentPage("search");
          }}
        >
          <span className="material-icons-round me-3" style={{ color: "#8f9fac" }}>
            search
          </span>

          <span className="w-100 search-label">Search Tracking ID | Address | Venue</span>
        </div>
        <span className="home-action-heading mb-2">Add New</span>

        <div className="row g-2">
          <div className="col">
            {technologyType == "cbrs_5g" ? (
              <HomeActions label="CBSD" action="add" clicked={addAssetClicked} />
            ) : (
              <div className="action-box ps-1 pe-1 center" onClick={addWifiClicked}>
                <img src={WifiIcon} className="h-20 me-2" />
                <span className="action-label">{"WIFI"}</span>
              </div>
            )}
          </div>
          <div className="col">
            <div className="action-box ps-1 pe-1 center" onClick={addCpeClicked}>
              <img src={SMB_ICONS["Field Tech"]} className="h-20 me-2" />
              <span className="action-label">{"CPE"}</span>
            </div>
          </div>
        </div>

        {!["cpi", "installer", null].includes(localStorage.getItem("role")) ? (
          <div className="action-box ps-1 pe-1 center mt-2" onClick={addVenueClicked}>
            <span className="material-icons-outlined me-2" style={{ color: "#0059b2" }}>
              pin_drop
            </span>
            <span className="action-label">{"Venue"}</span>
          </div>
        ) : (
          <></>
        )}

        <hr className="" style={{ borderTop: "3px solid #ccc" }}></hr>
        <span className="home-action-heading">Utility</span>
        <div className="row gx-2 mt-2">
          <div className="col-6">
            <HomeActions label="Measure" action="measure" clicked={measureClicked} />
          </div>
          <div className="col-6">
            <HomeActions label="Calculate" action="calculate" clicked={calculateClicked} />
          </div>
        </div>
      </div>
    );
  } else if (currentPage === "search") {
    return (
      <Search
        setPlace={(coordinates: any) => {
          setCenter(coordinates);
          map?.setZoom(18);
          showSearchLocation(true);
        }}
        closeSearch={() => {
          setCurrentPage("home");
        }}
      />
    );
  } else if (currentPage === "addAsset") {
    return (
      <Asset
        goBack={(page: string) => {
          setCurrentPage(page);
          getAssetsInBounds(map);
        }}
        position={assetDropLocation}
        azimuthActivity={(isActive: boolean) => {
          changeMapForAzimuth(isActive);
        }}
        goToProfile={() => {
          goToProfile();
        }}
        venuesOnMap={venuesOnMap}
        setFloorGeoJSONUrl={setFloorGeoJSONUrl}
        unsetFloorGeoJSON={unsetFloorGeoJSON}
        setVenueId={setCurrentVenueId}
        setBuildingId={setBuildingId}
        setFloorId={setFloorId}
      />
    );
  } else if (currentPage === "addCpe") {
    return (
      <AddCpe
        goBack={(page: string) => {
          setCurrentPage(page);
          getAssetsInBounds(map);
        }}
        position={assetDropLocation}
        azimuthActivity={(isActive: boolean) => {
          changeMapForAzimuth(isActive);
        }}
        goToProfile={() => {
          goToProfile();
        }}
        venuesOnMap={venuesOnMap}
        setFloorGeoJSONUrl={setFloorGeoJSONUrl}
        unsetFloorGeoJSON={unsetFloorGeoJSON}
        setVenueId={setCurrentVenueId}
        setBuildingId={setBuildingId}
        setFloorId={setFloorId}
      />
    );
  } else if (currentPage === "addWifi") {
    return (
      <AddWifi
        goBack={(page: string) => {
          setCurrentPage(page);
          getAssetsInBounds(map);
        }}
        position={assetDropLocation}
        azimuthActivity={(isActive: boolean) => {
          changeMapForAzimuth(isActive);
        }}
        goToProfile={() => {
          goToProfile();
        }}
        venuesOnMap={venuesOnMap}
        setFloorGeoJSONUrl={setFloorGeoJSONUrl}
        unsetFloorGeoJSON={unsetFloorGeoJSON}
        setVenueId={setCurrentVenueId}
        setBuildingId={setBuildingId}
        setFloorId={setFloorId}
      />
    );
  } else if (currentPage === "editAsset") {
    return (
      <EditAsset
        rmaSerialNumber={rmaNewSerialNumber}
        goBack={(page: string) => {
          setCurrentPage(page);
          setSelectedAsset(undefined);
        }}
        action={assetEditAction}
        position={assetDropLocation}
        assetDetails={selectedAsset}
        azimuthActivity={(isActive: boolean) => {
          changeMapForAzimuth(isActive);
        }}
        setFloorGeoJSONUrl={setFloorGeoJSONUrl}
        unsetFloorGeoJSON={unsetFloorGeoJSON}
        setBuildingId={setBuildingId}
        setFloorId={setFloorId}
        venuesOnMap={venuesOnMap}
        setVenueId={setCurrentVenueId}
      />
    );
  } else if (currentPage === "calculate") {
    return (
      <Calculate
        goBack={(page: string) => {
          setCurrentPage(page);
        }}
        latitude={calculateLocation.lat}
        longitude={calculateLocation.lng}
        setLatitude={(value: string) => {
          setCalculateLocation({ lat: value, lng: calculateLocation.lng });
          setCenter({ lat: parseFloat(value), lng: parseFloat(calculateLocation.lng) });
        }}
        setLongitude={(value: string) => {
          setCalculateLocation({ lat: calculateLocation.lat, lng: value });
          setCenter({ lat: parseFloat(calculateLocation.lat), lng: parseFloat(value) });
        }}
      />
    );
  } else if (currentPage === "assetDetails") {
    return (
      <AssetDetails
        setUpdatedAsset={(asset: AssetProps) => {
          setSelectedAsset(asset);
        }}
        refreshAsset={(trackingId: string) => {
          getSingleAssetDetails(trackingId);
          getAssetsInBounds(map);
        }}
        setAction={(action: string) => {
          setAssetEditAction(action);
        }}
        rmaSerialNumber={(value: string) => {
          setRmaNewSerialNumber(value);
        }}
        goBack={(page: string) => {
          setCurrentPage(page);
          getAssetsInBounds(map);
          if (page === "home") {
            setSelectedAsset(undefined);
          } else if (page === "editAsset" && selectedAsset?.asset.status !== "SAS") {
            setAssetDropLocation(center);
          }
        }}
        asset={selectedAsset}
      />
    );
  } else if (currentPage === "measure") {
    return (
      <Measure
        goBack={(page: string) => {
          setCurrentPage(page);
          setMeasurePoints([]);
          showMeasurePopup(false);
        }}
        assetsForMeasure={assetsForMeasure}
        measurePoints={measurePoints}
        setInfoPopup={(info: any) => {
          setDetailsOfMeasure(info);
        }}
      />
    );
  } else if (currentPage === "addVenue") {
    return (
      <AddVenue
        navigateTo={(page: string) => {
          setCurrentPage(page);
          getAssetsInBounds(map);
        }}
        position={assetDropLocation}
        boundaryPolygon={boundaryPolygon}
        setBoundaryDrawMode={setBoundaryDrawMode}
        cancelBoundaryDraw={cancelBoundaryDraw}
        setBoundaryColor={setBoundaryColor}
        boundaryColor={boundaryColor}
        setCurrentVenueId={setCurrentVenueId}
        assetsInBounds={assetsInBounds}
        fitVenueOnMap={fitVenueOnMap}
      />
    );
  } else if (currentPage === "venueDetails") {
    return (
      <VenueDetails
        navigateTo={(page: string) => {
          setCurrentPage(page);
          getAssetsInBounds(map);
        }}
        venueId={venueId}
        setBuildingsForVenue={setBuildingsForVenue}
        setBuildingId={setBuildingId}
      />
    );
  } else if (currentPage === "editVenue") {
    return <EditVenue navigateTo={setCurrentPage} venueId={venueId} />;
  } else if (currentPage === "addBuilding") {
    return (
      <AddBuilding
        navigateTo={(page: string) => {
          setCurrentPage(page);
        }}
        position={assetDropLocation}
        venueId={venueId}
      />
    );
  } else if (currentPage === "buildingDetails") {
    return (
      <BuildingDetails
        navigateTo={(page: string) => {
          setCurrentPage(page);
        }}
        buildingId={buildingId}
        setFloorId={setFloorId}
        floorId={floorId}
        setFloorActionType={setFloorActionType}
      />
    );
  } else if (currentPage === "editBuilding") {
    return (
      <EditBuilding
        buildingId={buildingId}
        navigateTo={(page: string) => {
          setCurrentPage(page);
        }}
      />
    );
  } else if (currentPage === "floorActions") {
    return (
      <FloorActions
        floorId={floorId}
        buildingId={buildingId}
        navigateTo={(page: string) => {
          setCurrentPage(page);
        }}
        floorActionType={floorActionType}
        position={assetDropLocation}
        setFloorGeoJSON={setFloorGeoJSON}
      />
    );
  } else if (currentPage === "floorDetails") {
    return (
      <FloorDetails
        floorId={floorId}
        buildingId={buildingId}
        navigateTo={(page: string) => {
          setCurrentPage(page);
        }}
        setFloorId={setFloorId}
        setFloorActionType={setFloorActionType}
        position={assetDropLocation}
        setFloorGeoJSONUrl={setFloorGeoJSONUrl}
      />
    );
  } else {
    return <></>;
  }
};

export default OutdoorRoutes;
