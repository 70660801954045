import React, { useEffect, useState } from "react";
import { CBSDStatuses } from "../constants/GlobalConstants";
import { exists } from "../services/helper";

interface AssetSummaryProps {
  searchedView: any;
  summary: any;
  refreshAssets: any;
}

const CBSDAssetsNav: React.FC<AssetSummaryProps> = ({ searchedView, summary, refreshAssets }) => {
  const [viewType, setViewType] = useState(CBSDStatuses.CLOUD.key);

  useEffect(() => {
    refreshAssets(viewType);
  }, [viewType]);

  const isCloud = () => {
    return exists(searchedView) 
            ? (searchedView === CBSDStatuses.CLOUD.key)
            : viewType === CBSDStatuses.CLOUD.key;
  }

  const isSAS = () => {
    return exists(searchedView) 
            ? (searchedView === CBSDStatuses.SAS.key)
            : viewType === CBSDStatuses.SAS.key;
  }

  const isRMA = () => {
    return exists(searchedView) 
            ? (searchedView === CBSDStatuses.RMA_REQUESTED.key)
            : viewType === CBSDStatuses.RMA_REQUESTED.key; 
  }

  return (
    <div className="d-flex">
      <div
        onClick={() => {
          setViewType(CBSDStatuses.CLOUD.key);
        }}
        className={"row-wise " + (isCloud() ? "selected-view" : "unselected-view")}>
        <span>{CBSDStatuses.CLOUD.displayText}</span>
        <div className="count-bubble">
          <span>{summary.cloudCount}</span>
        </div>
      </div>

    <span className="vertical"></span>
    <div
      onClick={() => {
        setViewType(CBSDStatuses.SAS.key);
      }}
      className={"row-wise " + (isSAS() ? "selected-view" : "unselected-view")}>
      <span>{CBSDStatuses.SAS.displayText}</span>
      <div className="count-bubble">
        <span>{summary.sasCount}</span>
      </div>
    </div>
    
    <span className="vertical"></span>
    <div
      onClick={() => {
        setViewType(CBSDStatuses.RMA_REQUESTED.key);
      }}
      className={"row-wise " + (isRMA() ? "selected-view" : "unselected-view")} >
      <span>{CBSDStatuses.RMA_REQUESTED.displayText}</span>
      <div className="count-bubble">
        <span>{summary.rmaCount}</span>
      </div>
    </div>
  </div>
  );
};

export default CBSDAssetsNav;
