import React, { useEffect, useState } from "react";
import LabelledInput from "./LabelledInput";
import LabelLessDropdown from "./LabelLessDropdown";
import CalculateConstants from "../constants/calculate";
import PrimaryButton from "./PrimaryButton";
import "./Calculate.css";
import Spinner from "./Spinner";
import ErrorAlert from "./ErrorAlert";
import WarningAlert from "./WarningAlert";
import axios from "axios";

interface CalculateProps {
  goBack: any;
  latitude: string;
  longitude: string;
  setLatitude: any;
  setLongitude: any;
}

const Calculate: React.FC<CalculateProps> = ({ goBack, latitude, longitude, setLatitude, setLongitude }) => {
  const [antennaHeight, setAntennaHeight] = useState("0");
  const [radials, setRadials] = useState("8");
  const [rcamsl, setRcamsl] = useState({ value: "-", unit: "m" });
  const [altitude, setAltitude] = useState({ value: "-", unit: "m" });
  const [source, setSource] = useState("ned_1");
  const [unit, setUnit] = useState("meters");
  const [valueUnit, setValueUnit] = useState("m");
  const [declination, setDeclination] = useState("-");
  const [haat, setHaat] = useState({ value: "-", unit: "m" });
  const [loading, setLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [warningAlert, setWarningAlert] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [allApiCalled, setAllApiCalled] = useState({
    elevation: false,
    haat: false,
    declination: false,
  });

  useEffect(() => {
    if (allApiCalled.declination && allApiCalled.elevation && allApiCalled.haat) {
      setAllApiCalled({
        declination: false,
        haat: false,
        elevation: false,
      });
      setLoading(false);
    }
  }, [allApiCalled]);

  useEffect(() => {
    switch (unit) {
      case "meters":
        setValueUnit("m");
        break;
      case "miles":
        setValueUnit("mi");
        break;
      case "feet":
        setValueUnit("ft");
        break;
    }
  }, [unit]);

  useEffect(() => {
    if (rcamsl.value !== "-") {
      calculateHaat()
        .then((response: any) => {
          setHaat({
            value: response.data.features[0].properties.haat_average.toFixed(2),
            unit: response.data.features[0].properties.unit,
          });
          setAllApiCalled({ haat: true, declination: allApiCalled.declination, elevation: allApiCalled.elevation });
        })
        .catch((error: any) => {
          setAllApiCalled({ haat: true, declination: allApiCalled.declination, elevation: allApiCalled.elevation });
          setErrorMessage(error.response.data.features[0].properties.statusMessage);
          setErrorAlert(true);
        });
    }
  }, [rcamsl]);

  const calculateClicked = (e: any) => {
    e.preventDefault();
    let validated: boolean = validateInput();
    if (!validated) return;
    setLoading(true);
    calculateElevation()
      .then((response: any) => {
        setAltitude({
          value: response.data.features[0].properties.elevation.toFixed(2),
          unit: response.data.features[0].properties.unit,
        });
        let responseRcaml = response.data.features[0].properties.elevation + convertHeightUnit();
        setRcamsl({ value: responseRcaml.toFixed(2), unit: response.data.features[0].properties.unit });
        setAllApiCalled({ haat: allApiCalled.haat, declination: allApiCalled.declination, elevation: true });
      })
      .catch((error: any) => {
        setAllApiCalled({ haat: allApiCalled.haat, declination: allApiCalled.declination, elevation: true });
        setErrorMessage(error.response.data.features[0].properties.statusMessage);
        setErrorAlert(true);
      });

    calculateDeclination()
      .then((response: any) => {
        setLoading(false);
        setDeclination(response.data.result[0].declination.toFixed(2));
        setAllApiCalled({ haat: allApiCalled.haat, declination: true, elevation: allApiCalled.elevation });
      })
      .catch((error: any) => {
        console.log(error.response.data);
        setAllApiCalled({ haat: allApiCalled.haat, declination: true, elevation: allApiCalled.elevation });
        setErrorAlert(true);
      });
  };

  const calculateElevation = () => {
    // https://geo.fcc.gov/api/contours
    let requestParams = {
      lat: parseFloat(parseFloat(latitude).toFixed(6)),
      lon: parseFloat(parseFloat(longitude).toFixed(6)),
      src: source,
      unit: valueUnit,
    };
    return axios.get("https://geo.fcc.gov/api/contours/elevation.json", { params: requestParams });
  };

  const calculateHaat = () => {
    let requestParams = {
      lat: parseFloat(parseFloat(latitude).toFixed(6)),
      lon: parseFloat(parseFloat(longitude).toFixed(6)),
      nradial: parseInt(radials),
      rcamsl: parseFloat(rcamsl.value),
      src: source,
      unit: valueUnit,
    };
    return axios.get("https://geo.fcc.gov/api/contours/haat.json", { params: requestParams });
  };

  const calculateDeclination = () => {
    let requestParams = {
      key: "zNEw7",
      lat1: parseFloat(parseFloat(latitude).toFixed(6)),
      lon1: parseFloat(parseFloat(longitude).toFixed(6)),
      resultFormat: "json",
    };
    return axios.get("https://www.ngdc.noaa.gov/geomag-web/calculators/calculateDeclination", {
      params: requestParams,
    });
  };

  const convertHeightUnit = (): number => {
    switch (unit) {
      case "meters":
        return parseFloat(antennaHeight) / 3.2808;

      case "miles":
        return parseFloat(antennaHeight) / 5280;

      default:
        return parseFloat(antennaHeight);
    }
  };

  const validateInput = (): boolean => {
    if ([undefined, null, NaN, ""].includes(latitude.trim())) {
      setWarningMessage("Invalid latitude value");
      setWarningAlert(true);
      return false;
    } else if ([undefined, null, NaN, ""].includes(longitude.trim())) {
      setWarningMessage("Invalid longitude value");
      setWarningAlert(true);
      return false;
    } else if ([undefined, null, NaN, ""].includes(antennaHeight.trim())) {
      setWarningMessage("Invalid antenna height");
      setWarningAlert(true);
      return false;
    } else if ([undefined, null, NaN, ""].includes(radials.trim())) {
      setWarningMessage("Invalid number of radials");
      setWarningAlert(true);
      return false;
    }
    return true;
  };

  return (
    <div className="h-100 overflow-auto">
      <div className="app-card position-relative app-card-size">
        <div className="row-wise vertical-center">
          <div
            className="icon-background-active"
            onClick={() => {
              goBack("home");
            }}
          >
            <span className="material-icons-round icon-active">arrow_back</span>
          </div>

          <span className="app-card-action-heading ms-3">CALCULATIONS</span>
        </div>

        <span className="asset-drop-info mt-2 mb-2 w-100">Calculate Declination and HAAT</span>

        <div className="overflow-auto pe-3 ps-1 pb-1 mb-1 pt-2 h-100">
          <div className="form-floating w-100 mb-2">
            <input
              autoComplete="new-password"
              id="latitudeInput"
              className="form-control input-box input-text vertical-center"
              placeholder={"Latitude *"}
              onChange={(e: any) => {
                setLatitude(e.target.value);
              }}
              value={latitude}
              type={"number"}
            ></input>
            <label className="input-label vertical-center" htmlFor="labelledInput">
              {"Latitude *"}
            </label>
          </div>

          <div className="form-floating w-100 mb-2">
            <input
              autoComplete="new-password"
              id="latitudeInput"
              className="form-control input-box input-text vertical-center"
              placeholder={"Longitude *"}
              onChange={(e: any) => {
                setLongitude(e.target.value);
              }}
              value={longitude}
              type={"number"}
            ></input>
            <label className="input-label vertical-center" htmlFor="labelledInput">
              {"Longitude *"}
            </label>
          </div>

          {/* <LabelledInput title="Latitude *" type="number" value={position.lat.toFixed(6)} />
          <LabelledInput title="Longitude *" type="number" value={position.lng.toFixed(6)} /> */}

          <LabelLessDropdown
            className="mb-2"
            title="Source"
            options={new CalculateConstants().SOURCE}
            fullWidth
            onChange={(e: any) => {
              setSource(e.target.value);
            }}
            value={source}
          />
          <LabelLessDropdown
            className="mb-2"
            title="Unit"
            options={new CalculateConstants().UNIT}
            fullWidth
            onChange={(e: any) => {
              setUnit(e.target.value);
            }}
            value={unit}
          />
          <LabelledInput
            title="Antenna Height (ft)"
            type="number"
            value={antennaHeight}
            onChange={(e: any) => {
              setAntennaHeight(e.target.value);
            }}
          />
          <LabelledInput
            title="No. of Radials *"
            type="number"
            value={radials}
            onChange={(e: any) => {
              setRadials(e.target.value);
            }}
          />
        </div>

        <div className="row g-0 mt-2">
          <div className="col col-wise result-box me-1">
            <span className="result-title">{`RCAMSL (${rcamsl.unit})`}</span>
            <span className="result-label">{`${rcamsl.value}`}</span>
          </div>
          <div className="col col-wise result-box ms-1">
            <span className="result-title">{`Altitude (${altitude.unit})`}</span>
            <span className="result-label">{`${altitude.value}`}</span>
          </div>
        </div>

        <div className="row g-0 mt-2 mb-2">
          <div className="col col-wise result-box me-1">
            <span className="result-title">{`Declination (degrees)`}</span>
            <span className="result-label">{`${declination}`}</span>
          </div>
          <div className="col col-wise result-box ms-1">
            <span className="result-title">{`HAAT (${haat.unit})`}</span>
            <span className="result-label">{`${haat.value}`}</span>
          </div>
        </div>
        <PrimaryButton className="mt-2" label="Calculate" clicked={calculateClicked} />
      </div>

      <Spinner show={loading} />
      <ErrorAlert
        show={errorAlert}
        onDismiss={() => {
          setErrorAlert(false);
        }}
        message={errorMessage}
      />
      <WarningAlert
        show={warningAlert}
        onDismiss={() => {
          setWarningAlert(false);
        }}
        message={warningMessage}
      />
    </div>
  );
};

export default Calculate;
