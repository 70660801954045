// AUTHOR: Rahul Choudhary | Whirldata Inc.
// DATE: 23 Aug 2022
// DESCRIPTION:
// -- Reusable component for navigation tabs in the header
// -- Allows to have multiple text based navigation tabs.
// -- Defines the style of the tab and sets the corresponding
//    page as active on click.
// -- See TabContent for more details on active page visibility.

import React from "react";

// Interface defines the properties to be used in the component when used in parent component.
interface TabNavItemProps {
  id: string;
  title: string;
  activeTab: string;
  setActiveTab: Function;
  show?: boolean;
}

const TabNavItem: React.FC<TabNavItemProps> = ({ id, title, activeTab, setActiveTab, show = true }) => {
  // Sets the clicked tab as active tab.
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <li key={id} onClick={handleClick} className={`${show ? "" : "d-none"} ${activeTab === id ? "active" : ""}`}>
      {title}
    </li>
  );
};

export default TabNavItem;
