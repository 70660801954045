import React from "react";
import Modal from "./Modal";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";

interface ConfirmAlertProps {
  visible: boolean;
  title: string;
  message: any;
  secondaryText: string | null;
  primaryText: string;
  secondaryAction: any;
  primaryAction: any;
  note?: string;
}
const ConfirmAlert: React.FC<ConfirmAlertProps> = ({
  visible,
  title,
  message,
  secondaryAction,
  secondaryText,
  primaryAction,
  primaryText,
  note,
}) => {
  return (
    <Modal show={visible}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content app-card border-0 p-0">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
          </div>
          <div className="modal-body col-wise">
            <label dangerouslySetInnerHTML={{ __html: message }}></label>
            {note ? <label className="mt-4 fst-italic">{note}</label> : ""}
          </div>
          <div className="modal-footer">
            {secondaryText !== null && (
              <SecondaryButton label={secondaryText || ""} disable={false} clicked={secondaryAction} />
            )}
            <PrimaryButton label={primaryText} disable={false} clicked={primaryAction} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmAlert;
