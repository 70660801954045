import React, { useEffect, useState } from "react";
import "./_addvenue.css";
import { IAddVenueProps } from "./IAddVenueProps";
import LabelledInput from "../LabelledInput";
import PrimaryButton from "../PrimaryButton";
import { VenueDrawPlot } from "../VenueDrawPlot/VenueDrawPlot";
import SecondaryButton from "../SecondaryButton";
import { ApiHandler } from "../../services/apiHandler";
import Spinner from "../Spinner";
import WarningAlert from "../WarningAlert";
import SuccessAlert from "../SuccessAlert";
import ErrorAlert from "../ErrorAlert";
import ConfirmAlert from "../confirmAlert";
import { useNavigate } from "react-router-dom";

export const AddVenue: React.FC<IAddVenueProps> = (props) => {
  let navigate = useNavigate();
  const [confirmAlert, setConfirmAlert] = useState({ alert: false, message: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: "",
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: "",
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: "",
    alert: false,
  });
  const [venue, setVenue] = useState({
    name: "",
    description: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    latitude: 0,
    longitude: 0,
  });
  const [isDrawActive, setIsDrawActive] = useState(false);
  const [isCreateActive, setIsCreateActive] = useState(false);
  const [assetsInVenue, setAssetsInVenue] = useState<any[]>([]);

  useEffect(() => {
    setVenue((prevValues: any) => {
      return {
        ...prevValues,
        latitude: parseFloat(props.position.lat.toFixed(6)),
        longitude: parseFloat(props.position.lng.toFixed(6)),
      };
    });
  }, [props.position]);

  useEffect(() => {
    if (isCreateActive) {
      checkAssetsInVenue();
    }
  }, [props.assetsInBounds]);

  const handleInputChange = (e: any) => {
    setVenue((prevValues: any) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  const drawBoundary = () => {
    setIsDrawActive(true);
    props.setBoundaryDrawMode("draw");
  };

  const confirmBoundary = () => {
    setIsDrawActive(false);
    props.setBoundaryDrawMode(null);
  };

  const cancelBoundary = () => {
    setIsDrawActive(false);
    props.cancelBoundaryDraw();
  };

  const setBoundaryPolygonToCreateVenue = () => {
    // set boundary plot from google.maps.LatLng to {lat: number, lng: number}
    let boundary = props.boundaryPolygon?.map((point) => {
      return {
        lat: parseFloat(point.lat().toFixed(6)),
        lng: parseFloat(point.lng().toFixed(6)),
      };
    });

    // push first vertex as last vertex to make it a closed polygon
    boundary?.push(boundary[0]);
    return boundary;
  };

  const checkAssetsInVenue = () => {
    if (props.assetsInBounds.length > 0) {
      let assetsInVenueList: any[] = [];
      for (let asset of props.assetsInBounds) {
        const contains: any = window.google.maps.geometry.poly.containsLocation(
          new window.google.maps.LatLng(asset.latitude, asset.longitude),
          new window.google.maps.Polygon({ paths: props.boundaryPolygon })
        );
        if (contains) {
          assetsInVenueList.push(asset);
        }
      }
      setAssetsInVenue(assetsInVenueList);
      if (assetsInVenueList.length > 0) {
        let confirmMessage: string =
          "<b>Assets with following Tracking IDs fall inside the bounds of venue.</b><br/><br/><ul>";
        assetsInVenueList.forEach((asset: any) => {
          confirmMessage += "<li>" + asset.tracking_id + "</li>";
        });
        confirmMessage +=
          "</ul><i>On confirming the creation, the assets will <b>automatically be associated to the venue</b>.</i><br/><br/><span>Do you want to proceed?</span>";
        setConfirmAlert({ alert: true, message: confirmMessage });
      } else {
        setConfirmAlert({ alert: true, message: "Are you sure you want to finalize the venue creation?" });
      }
    } else {
      setConfirmAlert({ alert: true, message: "Are you sure you want to finalize the venue creation?" });
    }
    setIsCreateActive(false);
  };

  /* 
  Create Venue API call after confirmation
*/
  const createVenueAPI = () => {
    setLoading(true);

    let boundary = setBoundaryPolygonToCreateVenue();

    // create the request json
    const requestBody = {
      ...venue,
      boundary_plot: boundary,
    };
    ApiHandler({ apiName: "createVenue", body: requestBody, params: {} })
      .then((response: any) => {
        setLoading(false);
        setSuccess({
          message: "Venue created successfully",
          alert: true,
        });
        if (assetsInVenue.length > 0) {
          addAssetsToVenue(response.data.venue_id);
        } else {
          props.cancelBoundaryDraw();
          props.setCurrentVenueId(response.data.venue_id);
          setTimeout(() => {
            props.navigateTo("venueDetails");
          }, 1000);
        }
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
          setIsCreateActive(false);
        }
      });
  };

  const startCreateVenue = () => {
    if (props.boundaryPolygon?.length === 0) {
      setWarning({
        message: "Boundary of the venue is not defined yet. Click on the 'Draw Boundary Plot' button to start.",
        alert: true,
      });
      return;
    }
    setIsCreateActive(true);
    setConfirmAlert({ alert: true, message: "Are you sure you want to finalize the venue creation?" });
    props.fitVenueOnMap();
  };

  const addAssetsToVenue = (venueId: any) => {
    setLoading(true);

    const requestBody: any = { asset_ids: [], venue_id: venueId };
    assetsInVenue.forEach((asset: any) => {
      requestBody.asset_ids.push(asset.id);
    });
    ApiHandler({ apiName: "addAssetsToVenue", body: requestBody, params: {} })
      .then((response: any) => {
        setLoading(false);
        setSuccess({
          message: "Assets added to the venue successfully",
          alert: true,
        });

        props.cancelBoundaryDraw();
        props.setCurrentVenueId(venueId);
        setTimeout(() => {
          props.navigateTo("venueDetails");
        }, 1000);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
          setIsCreateActive(false);
        }
      });
  };

  return (
    <div className="h-100 row-wise overflow-auto">
      <div>
        <div className="app-card position-relative app-card-size">
          <div className="row-wise vertical-center">
            <div
              className="icon-background-active"
              onClick={() => {
                cancelBoundary();
                props.navigateTo("home");
              }}
            >
              <span className="material-icons-round icon-active">arrow_back</span>
            </div>

            <span className="app-card-action-heading ms-3">ADDING VENUE</span>
          </div>
          <form className="overflow-auto col-wise h-100 pe-3 ps-1 pb-1 mb-1 pt-2">
            <LabelledInput
              title="Name*"
              name="name"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={venue.name}
            />
            <div className="form-floating w-100 mb-2">
              <textarea
                rows={5}
                id="description"
                className="form-control textbox-box input-text h-auto"
                placeholder={"Description"}
                name="description"
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
                value={venue.description}
              ></textarea>
              <label className="input-label" htmlFor="description">
                Description
              </label>
            </div>

            <div className="form-floating w-100 mb-2">
              <textarea
                rows={5}
                id="address"
                className="form-control textbox-box input-text h-auto"
                placeholder={"Address"}
                name="address"
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
                value={venue.address}
              ></textarea>
              <label className="input-label" htmlFor="address">
                Address*
              </label>
            </div>

            <div className="row gx-2">
              <div className="col-6">
                <LabelledInput
                  title="Zip Code*"
                  name="zipcode"
                  onChange={(e: any) => {
                    handleInputChange(e);
                  }}
                  value={venue.zipcode}
                />
              </div>
              <div className="col-6">
                <LabelledInput
                  title="City*"
                  name="city"
                  onChange={(e: any) => {
                    handleInputChange(e);
                  }}
                  value={venue.city}
                />
              </div>
            </div>

            <div className="row gx-2">
              <div className="col-6">
                <LabelledInput
                  title="State*"
                  name="state"
                  onChange={(e: any) => {
                    handleInputChange(e);
                  }}
                  value={venue.state}
                />
              </div>
              <div className="col-6">
                <LabelledInput
                  title="Country*"
                  name="country"
                  onChange={(e: any) => {
                    handleInputChange(e);
                  }}
                  value={venue.country}
                />
              </div>
            </div>

            <div className="row gx-2">
              <div className="col-6">
                <LabelledInput title="Latitude*" name="latitude" disabled value={venue.latitude} />
              </div>
              <div className="col-6">
                <LabelledInput title="Longitude*" name="longitude" disabled value={venue.longitude} />
              </div>
            </div>
          </form>
          {isDrawActive ? (
            <div className="row gx-2 me-4 ms-1">
              <div className="col-6">
                <SecondaryButton
                  className="my-2"
                  label={"Confirm Boundary"}
                  clicked={confirmBoundary}
                  disable={false}
                />
              </div>
              <div className="col-6">
                <SecondaryButton
                  className="my-2"
                  label={"Cancel"}
                  clicked={cancelBoundary}
                  disable={false}
                  type="delete"
                />
              </div>
            </div>
          ) : (
            <SecondaryButton
              className="mb-2 me-4 ms-1 mt-2"
              label={"Draw Boundary Plot"}
              clicked={drawBoundary}
              disable={false}
            />
          )}

          <PrimaryButton
            className="mb-2 me-4 ms-1 mt-2"
            label="Create Venue"
            clicked={() => {
              startCreateVenue();
            }}
            disable={isDrawActive}
          />
        </div>
      </div>

      {/* Plot Boundary */}

      {isDrawActive && <VenueDrawPlot setColor={props.setBoundaryColor} color={props.boundaryColor} />}

      <Spinner show={loading} />

      <ConfirmAlert
        message={confirmAlert.message}
        primaryText={"Yes"}
        secondaryText={"No"}
        secondaryAction={() => {
          setConfirmAlert({ alert: false, message: "" });
          setAssetsInVenue([]);
        }}
        visible={confirmAlert.alert}
        title={"Finish Venue Creation"}
        primaryAction={() => {
          setConfirmAlert({ alert: false, message: "" });
          createVenueAPI();
        }}
      />

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: "", alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: "", alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: "", alert: false });
        }}
        message={warning.message}
      />
    </div>
  );
};
