import LeafBlue from "../assets/leaf_blue.png";
import LeafGreen from "../assets/leaf_green.png";
import LeafOrange from "../assets/leaf_orange.png";
import LeafPurple from "../assets/leaf_purple.png";
import LeafYellow from "../assets/leaf_yellow.png";
import SMBBlue from "../assets/smb_blue.png";
import SMBGreen from "../assets/smb_green.png";
import SMBOrange from "../assets/smb_orange.png";
import SMBPurple from "../assets/smb_purple.png";
import SMBYellow from "../assets/smb_yellow.png";

export const SMB_ICONS: any = {
  SAS: SMBGreen,
  OpenWifi: SMBGreen,
  Cloud: SMBOrange,
  "Field Tech": SMBBlue,
  "RMA in progress": SMBYellow,
  "RMA Requested": SMBPurple,
};

export const LEAF_ICONS: any = {
  SAS: LeafGreen,
  Cloud: LeafOrange,
  "Field Tech": LeafBlue,
  "RMA in progress": LeafYellow,
  "RMA Requested": LeafPurple,
};
