import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiHandler } from "../services/apiHandler";
import { assetStatusDot } from "../constants/GlobalConstants";
import ErrorAlert from "./ErrorAlert";

interface SearchInputProps {
  searchFilters: any;
}

const CBSDAssetSearch: React.FC<SearchInputProps> = ({ searchFilters }) => {
  let navigate = useNavigate();
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchBy, setSearchBy] = useState<"trackingid" | "siteid">("trackingid");
  const [searchString, setSearchString] = useState("");
  const [searchList, setSearchList] = useState([{}]);

  useEffect(() => {
    resetSearchValues();
  }, []);

  useEffect(() => {
    {
      /** update hasSearchString property in  searchFilters
        to disable the other filters*/
    }
    let hasSearchString = searchString.length > 0;
    searchFilters({
      trackingId: "",
      siteId: "",
      status: "",
      hasSearchString: hasSearchString,
      abortSearch: false,
    });

    if (hasSearchString) {
      searchAsset();
    }
  }, [searchString]);

  {
    /** This method is clear the search element */
  }
  const resetSearchValues = () => {
    setSearchString("");
    setSearchList([]);
  };

  {
    /** This method is used to abort the search filter by clicking close */
  }
  const clearSearch = () => {
    resetSearchValues();
    searchFilters({
      trackingId: "",
      siteId: "",
      status: "",
      hasSearchString: false,
      abortSearch: true,
    });
  };

  {
    /** This method is used to search the asset based on tracking_id
       or site_id */
  }
  const searchAsset = () => {
    if (searchBy === "trackingid") {
      searchByTrackingId();
    } else if (searchBy === "siteid") {
      searchBySiteId();
    }
  };

  {
    /** Method used to call search asset by tracking id */
  }
  const searchByTrackingId = () => {
    ApiHandler({ apiName: "searchAssetByTrackingId", body: {}, params: { searchTerm: searchString } })
      .then((response: any) => {
        setSearchList(response.data.assets);
      })
      .catch((error: any) => {
        if (error.doLogout === true) {
          setErrorMessage("Session Timed Out");
          setErrorAlert(true);
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setErrorMessage(error.data.message);
          setErrorAlert(true);
        }
      });
  };

  {
    /** Method used to call search asset by tracking id */
  }
  const searchBySiteId = () => {
    ApiHandler({ apiName: "searchAssetBySiteId", body: {}, params: { searchTerm: searchString } })
      .then((response: any) => {
        setSearchList(response.data.assets);
      })
      .catch((error: any) => {
        if (error.doLogout === true) {
          setErrorMessage("Session Timed Out");
          setErrorAlert(true);
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setErrorMessage(error.data.message);
          setErrorAlert(true);
        }
      });
  };

  /** Apply filter when search item clicked*/
  const applyFilters = (asset: any) => {
    searchFilters({
      trackingId: asset.tracking_id,
      siteId: asset.site_id,
      status: asset.status,
      hasSearchString: true,
      abortSearch: false,
    });
    setSearchList([]);
  };

  return (
    <div>
      <div className="col-wise">
        {/* Search Options: Tracking_id or Site_id */}
        <div className="row-wise">
          <span
            onClick={() => {
              setSearchBy("trackingid");
            }}
            className={"me-2 ms-2 " + (searchBy === "trackingid" ? "net-search-label-selected" : "net-search-label")}
          >
            Tracking ID
          </span>

          <span
            onClick={() => {
              setSearchBy("siteid");
            }}
            className={"ms-2 " + (searchBy === "siteid" ? "net-search-label-selected" : "net-search-label")}
          >
            Site ID
          </span>
        </div>

        {/* Search Input */}
        <div className="form-floating input-group net-action-box d-flex align-items-center w-100 searchbar">
          <span className="network-icon material-icons-round ms-2">search</span>
          <input
            id="searchString"
            name="searchString"
            type={"text"}
            className={"net-search-input"}
            placeholder={"Search by " + (searchBy === "siteid" ? "Site ID" : "Tracking ID")}
            value={searchString}
            onChange={(e: any) => {
              setSearchString(e.target.value);
            }}
          />
          {searchString !== "" ? (
            <span className="network-icon material-icons-round me-2 m-auto" onClick={clearSearch}>
              close
            </span>
          ) : (
            <div className="network-icon me-4"></div>
          )}
        </div>
      </div>

      {/* Search List View */}
      {searchString.length > 0 && searchList.length > 0 ? (
        <div className="network-search">
          {searchList.map((asset: any) => {
            return (
              <div
                key={asset.tracking_id}
                className="d-flex search-list"
                onClick={() => {
                  applyFilters(asset);
                }}
              >
                <div className="col-2 center-dots p-l-0 p-r-0">
                  <img
                    src={assetStatusDot[asset.status] ? assetStatusDot[asset.status] : ""}
                    className="asset-image"
                  ></img>
                </div>
                <div className="col-10 p-l-0">
                  <div>
                    <span className="netactid">Tracking ID: </span>
                    <span className="netactdetail">{asset.tracking_id}</span>
                  </div>
                  <div>
                    <span className="netactid">Site ID: </span>
                    <span className="netactdetail">{asset.site_id}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}

      <ErrorAlert
        show={errorAlert}
        onDismiss={() => {
          setErrorAlert(false);
        }}
        message={errorMessage}
      />
    </div>
  );
};

export default CBSDAssetSearch;
